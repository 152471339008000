<template>
  <div class="main-layout">
    <div class="px-5 content-set">
      <v-row no-gutters>
        <v-col class="d-flex align-center justify-center">
          <img :src="require('../assets/images/ilustrations/IluSuccessPaid.png')" height="170" />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="d-flex align-center justify-center">
          <strong class="subheading">{{ $t("success_payment") }} ✨</strong>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="d-flex align-center justify-center">
          <p class="content text-center">
            {{ $t("congrats_payment_verify") }}
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="align-center justify-center">
          <p class="text-center title-invoice">
            {{ $t("page.referral.order_num") }} :
          </p>
          <strong > 
            <p class="subheading text-center iconsSet" @click="openDialogInvoice(order_number_desc)">{{ order_number_desc }} </p>
          </strong>
          <p class="text-center title-invoice">
            {{ $t("page.referral.order_desc") }} :
          </p>
          <strong > 
            <p class="subheading-desc text-center desc-width">
              {{ product_desc }}
            </p>
          </strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-center justify-center">
          <localized-link to="/patient/profile?tab=ORDER">
            <c-button rounded shadow bold>
              {{ $t("view_order") }}
            </c-button>
          </localized-link>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-center justify-center">
          <localized-link to="/">
            <icons icon="house" color="#00a4b3" />
          </localized-link>
        </v-col>
      </v-row>

      <DialogInvoice
        :item="dataDialogInvoice"
        :tableFilter="tableInvoice"
        :tableFilterDeal="tableInvoiceDeal"
        :tampilkan="dialog_invoice"
        @toggleDialog="(is_show) => toggleDialogInvoice(is_show)"
        v-if="dialog_invoice"
      />
    </div>
  </div>  
</template>

<script>
import Icons from "@/components/elements/Icons.vue";
import API from "../service/api_service.js";
import Auth from "../service/auth_service.js";
import CButton from "@/components/v2/button/index.vue";
import DialogInvoice from "@/components/v2/dialog/dialogInvoice.vue";


export default {
  name: "Cart",
  components: {
    CButton,
    Icons,
    DialogInvoice
  },
  data: () => ({
    totalOrder: 0,
    administration: 0,
    voucher: 0,
    totalPayment: 0,
    dataDialogInvoice: {
      order_number: null,
      subscription: false,
      list: [],
      data: {
        metadata: {},
      },
    },
    dialog_invoice: false,
    tableInvoice: [],
    tableInvoiceDeal: [],
    order_number_desc: '',
    product_desc:'',
  }),
  created(){
    API.get(
      `${process.env.VUE_APP_API_TRUST}registration/status-transaction?invoice_number=${this.$route.query.order_id}`,
      {
        Authorization: `Bearer ${Auth.getToken()}`,
      }
    ).then(res => {
      if (res.status_code == 200) {
        this.order_number_desc = res.order_number;
        this.openDialogInvoice(res.order_number);
      }
    });
  },      
  methods: {
    toggleDialogInvoice(toggle) {
      this.dialog_invoice = toggle;
    },
    async openDialogInvoice(item) {
      this.dataDialogInvoice.order_number = item;
      this.dataDialogInvoice.subscription = false;
      this.getOrderDetailInvoice(item);
    },
    async getOrderDetailInvoice(item_order_number){
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}invoices/get-detail?order_number=` +
            item_order_number +
            ``,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.dataDialogInvoice.data = res.results;
        this.dataDialogInvoice.list = res.results.data;
        //this.product_desc = res.results.data[0].package_name;
        // const resMyJSON = this.dataDialogInvoice.list.map(element => {
        //   return element.product_name
        // });
        // const myJSON = JSON.stringify(resMyJSON);
        // this.product_desc = myJSON.replace(/[^ a-z A-Z 0-9 , ()/]/g,'');
        this.product_desc = res.results.package_name;

        let grand_total =
          parseInt(this.dataDialogInvoice.data.metadata.amount_total) -
          parseInt(this.dataDialogInvoice.data.metadata.total_discount);
        this.dataDialogInvoice.data.metadata.grand_total = grand_total;

        let groupCategory = this.dataDialogInvoice.data.data.reduce(
          (r, a) => {
            r[a.sub_category] = [...(r[a.sub_category] || []), a];
            return r;
          },
          {}
        );

        this.groupItemCategory = Object.entries(groupCategory).map((arr) => ({
          nameCategory: arr[0],
          dataCategory: arr[1],
        }));

        let filterItem = [];
        this.groupItemCategory.forEach((element) => {
          filterItem = [
            ...filterItem,
            { text: element.nameCategory, type: "category" },
          ];
          let groupSubcategory = element.dataCategory.reduce((r, a) => {
            r[
              a.product_name
              // this.$i18n.locale == "id"
              //   ? a.product_name
              //   : a[`product_name_${this.$i18n.locale}`]
            ] = [
              ...(r[
                a.product_name
                // this.$i18n.locale == "id"
                //   ? a.product_name
                //   : a[`product_name_${this.$i18n.locale}`]
              ] || []),
              a,
            ];
            return r;
          }, {});
          this.groupItemSubategory = Object.entries(groupSubcategory).map(
            (arr) => ({
              nameSubcategory: arr[0],
              dataSubcategory: arr[1],
            })
          );

          this.groupItemSubategory.forEach((element) => {
            filterItem = [
              ...filterItem,
              { text: element.nameSubcategory, type: "subcategory" },
            ];
            element.dataSubcategory.forEach((element) => {
              filterItem = [...filterItem, { ...element, type: "item" }];
            });
          });
        });
        this.tableInvoice = filterItem;
        this.dialog_invoice = true;
      } catch (error) {
        console.log(error);
      }
    },
    async getOrderDetailInvoiceOld(item_order_number){
      try {
          const res = await API.get(
            `${process.env.VUE_APP_API_TRUST}invoices/get-detail?order_number=` +
            item_order_number +
              ``,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          );
          this.dataDialogInvoice.data = res.results;
          this.dataDialogInvoice.list = res.results.data;
          this.product_desc = res.results.data[0].package_name;

          //cari harga total karena diskon belum kehitung
          let grand_total =
            parseInt(this.dataDialogInvoice.data.metadata.amount_total) -
            parseInt(this.dataDialogInvoice.data.metadata.total_discount);
          this.dataDialogInvoice.data.metadata.grand_total = grand_total;

          let groupCategory = this.dataDialogInvoice.data.data.reduce(
            (r, a) => {
              r[a.sub_category] = [...(r[a.sub_category] || []), a];
              return r;
            },
            {}
          );

          this.groupItemCategory = Object.entries(groupCategory).map((arr) => ({
            nameCategory: arr[0],
            dataCategory: arr[1],
          }));

          let filterItem = [];
          this.groupItemCategory.forEach((element) => {
            filterItem = [
              ...filterItem,
              { text: element.nameCategory, type: "category" },
            ];
            let groupSubcategory = element.dataCategory.reduce((r, a) => {
              r[
                a.product_name
                // this.$i18n.locale == "id"
                //   ? a.product_name
                //   : a[`product_name_${this.$i18n.locale}`]
              ] = [
                ...(r[
                  a.product_name
                  // this.$i18n.locale == "id"
                  //   ? a.product_name
                  //   : a[`product_name_${this.$i18n.locale}`]
                ] || []),
                a,
              ];
              return r;
            }, {});
            this.groupItemSubategory = Object.entries(groupSubcategory).map(
              (arr) => ({
                nameSubcategory: arr[0],
                dataSubcategory: arr[1],
              })
            );

            this.groupItemSubategory.forEach((element) => {
              filterItem = [
                ...filterItem,
                { text: element.nameSubcategory, type: "subcategory" },
              ];
              element.dataSubcategory.forEach((element) => {
                filterItem = [...filterItem, { ...element, type: "item" }];
              });
            });
          });

          this.tableInvoice = filterItem;
          this.dialog_invoice = true;
      } catch (error) {
        console.log(error);
      }
    },
    formater(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style lang="scss">
.d-mobile{
  display: none;
}
.d-desktop{
  display: block;
}
@media only screen and (max-width: 767px) {
  .d-mobile{
    display: block;
  }
  .d-desktop{
    display: none;
  }
}
</style>
<style lang="scss" scoped>
@import "../assets/css/custom.scss";
.desc-width{
  width: 70%;
  margin-left: 15%;
}

.card--payment {
  padding: 32px 15px;
  border-radius: 16px;
}

.header {
  padding: 30px 0 10px 0;
}
.subheading {
  color: rgba(121, 196, 198, 1);
  font-size: 18px;
}
.subheading-desc {
  color: rgba(121, 196, 198, 1);
  font-size: 14px;
  margin-bottom: 0;
}
.rp {
  color: $gray_2;
  font-size: 18px;
  margin-top: 40px;
}
.to-home {
  color: $gray_2;
  font-size: 18px;
}
.content {
  font-size: 16px;
  margin-top: 15px;
  font-weight: 500;
  color: rgba(123, 140, 174, 1);
}
.content-amount {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  color: rgba(123, 140, 174, 1);
}
.content-set {
  padding: 10px 0 0 0;
}
.amount {
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  padding: 15px 40px;
  border-radius: 15px;
}
a {
  text-decoration: none;
}
.iconsSet {
  cursor: pointer;
  margin: 0 30%;
}
.title-invoice{
  font-size: 16px;
  font-weight: 500;
  color: rgba(123, 140, 174, 1);
  margin-bottom: 0;
}

@media only screen and (max-width: 714px) {
  .iconsSet {
    cursor: pointer;
    margin: 0 20%;
  } 
}
</style>
