<template>
  <div class="main-layout">
    <div class="px-5 content-set">
      <div class="card--payment">
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <img :src="require('../assets/circle-check.png')" height="170" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <strong class="subheading"
              >{{ $t("page.register.register_success") }} ✨</strong
            >
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <p class="content text-center">
              {{ $t("page.register.page_auto_redirect") }} {{ countdown }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <router-link to="/">
              <strong class="to-home d-flex align-center justify-center">
                <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
                  />
                </svg>
                {{ $t("page.login.login_now") }}</strong
              >
            </router-link>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    countdown: 3,
  }),
  mounted() {
    setInterval(() => {
      this.countdown--;
      if (this.countdown == 0) {
        this.$router.push("/login");
      }
    }, 1000);
  },
};
</script>

<style></style>
