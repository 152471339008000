<template>
    <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.69792 19H3.3584C1.49384 19 0.00219727 17.7936 0.00219727 16.2857V2.71428C0.00219727 1.20635 1.49384 0 3.3584 0H9.69792C11.5625 0 13.0541 1.20635 13.0541 2.71428V16.2857C13.4271 17.7936 11.9354 19 9.69792 19ZM3.73132 1.50794C2.9855 1.50794 2.23967 2.11111 2.23967 2.4127V15.9841C2.23967 16.5873 2.9855 17.1905 3.73132 17.1905H10.0708C10.8167 17.1905 11.5625 16.5873 11.5625 15.9841V2.4127C11.5625 1.80952 10.8167 1.50794 10.0708 1.50794H3.73132Z" :fill="color"/>
<path d="M8.5791 4.5238H1.12085V6.33333H8.5791V4.5238Z" :fill="color"/>
<path d="M6.71455 8.44446H1.12085V10.254H6.71455V8.44446Z" :fill="color"/>
<path d="M8.5791 12.3651H1.12085V14.1746H8.5791V12.3651Z" :fill="color"/>
</svg>

</template>

<script>
export default {
  props: ["color"],
};
</script>