<template>
  <div class="modal--menu" @click.stop="$store.commit('closeMenu')">
    <div class="modal--menu__body">
      <user-detail />
    </div>
  </div>
</template>

<script>
import Auth from "../service/auth_service";
import UserDetail from './headers/UserDetail.vue';
export default {
  name: "ModalMenu",
  data: () => ({
    switchLang: false,
  }),
  components:{UserDetail},
  methods: {
    changeLang(param) {
      this.$i18n.locale = param;
      this.toggleSwithLang();
    },
    toggleSwithLang() {
      
      this.switchLang = !this.switchLang;
    },
    logout() {
      Auth.signOut();
      this.$router.push({ path: "login" });
      this.$store.commit("closeMenu");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/custom.scss";
.modal--menu {
  position: fixed;
  z-index: 9999;
  background: rgba(16, 16, 16, 0.85);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  .modal--menu__body {
    position: absolute;
    left: 20px;
    top: 200px;
    border-radius: 14px;
    background: white;
    &::before {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      top: -6px;
      transform: rotate(45deg);
      left: 16px;
      background: white;
    }
  }
}
</style>
