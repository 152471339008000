<template>
  <v-select
    :label="$t('field.phone_code')"
    v-model="selected"
    :items="items"
    item-value="code"
    outlined
    dense
    @change="$emit('changePhoneCode', selected)"
  >
    <template v-slot:item="{ item }">
      <v-row>
        <v-col class="flag" cols="2">
          <vue-country-flag :country="item.flag" size="small" />
        </v-col>
        <v-col class="name" cols="7">
          {{ item.name }} <span class="iso">{{ item.iso }}</span>
        </v-col>

        <v-col class="code" cols="3">
          {{ item.code }}
        </v-col>
      </v-row>
      <p></p>
    </template>
    <template v-slot:selection="{ item }" dense>
      <p>
        {{ item.code }}
      </p>
    </template>
  </v-select>
</template>

<script>
import VueCountryFlag from "vue-country-flag";
export default {
  components: {
    VueCountryFlag,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      selected: this.value,
    };
  },

  watch: {
    value: function (new_val, old_val) {
      if (old_val) {
        this.selected = new_val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.name {
  font-size: 12px;
  margin-left: -45px;
  text-align: left;
  width: 250px;
  margin-top: 15px;
}
.iso {
  font-size: 12px;
  text-transform: uppercase;
}
.code {
  font-size: 12px;
  float: right;
  font-weight: bold;
  text-align: right;
  margin-top: 15px;
}

.flag {
  margin: 10px;
}

.v-menu__content {
  position: absolute;
  display: inline-block;
  max-width: 70% !important;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.v-select__selections {
  p {
    margin-bottom: 0px !important;
  }
}
</style>
