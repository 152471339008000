import Vue from "vue";
import LangRouter from "vue-lang-router";
import DatetimePicker from "vuetify-datetime-picker";
// import Home from "../views/Home.vue";
// import Login from "../views/Login.vue";
// import Cart from "../views/Cart.vue";
// import OrderSummary from "../views/OrderSummary.vue";
// import OrderDetail from "../views/OrderDetail.vue";
// import UsageDetail from "../views/UsageDetail.vue";
// import MyOrder from "../views/MyOrder.vue";
// import StatusOrder from "../views/StatusOrder.vue";
// import LabCheck from "../views/LabCheck.vue";
// import LabCheckDetail from "../views/LabCheckDetail.vue";
// import MyPackageUseDetail from "../views/MyPackageUseDetail.vue";
import PaymentSuccess from "../views/PaymentSuccess.vue";
import PaymentSuccessPackage from "../views/PaymentSuccessPackage.vue";
import PaymentSuccessDeal from "../views/PaymentSuccessDeal.vue";
import PaymentSuccessLab from "../views/PaymentSuccessLab.vue";
import PaymentSuccessPackageUse from "../views/PaymentSuccessPackageUse.vue";
// import BookAppointmentList from "../views/BookAppointmentList.vue";
// import BuyPackageList from "../views/BuyPackageList.vue";
// import BuyPackageListDetail from "../views/BuyPackageListDetail.vue";
// import MyPackage from "../views/MyPackage.vue";
// import MyPackageUseSummary from "../views/MyPackageUseSummary.vue";
// import OrderPackageSummary from "../views/OrderPackageSummary.vue";
// import OrderMcuSummary from "../views/OrderMcuSummary.vue";
// import OrderLabSummary from "../views/OrderLabSummary.vue";
// import BookAppointmentListDetail from "../views/BookAppointmentListDetail.vue";
// import BranchInfo from "../views/BranchInfo.vue";
// import BookAppointmentLocation from "../views/BookAppointmentLocation.vue";
// import BookAppointmentLocationMaps from "../views/BookAppointmentLocationMaps.vue";
// import UploadIdCard from "../views/UploadIdCard.vue";
import Register from "../views/Register/Index.vue";
import ContinueRegister from "../views/Register/ContinueRegister.vue";
// import RegisterNoLogout from "../views/RegisterNoLogout.vue";
import RegisterSuccess from "../views/RegisterSuccess.vue";
// import EmailVerification from "../views/EmailVerification.vue";
import Error404 from "../views/Error404.vue";
import Error403 from "../views/Error403.vue";
import LoginV2 from "../views/LoginV2.vue";
import Meta from "vue-meta";
// import Family from "../views/family/Index.vue";
// import FamilyEdit from "../views/family/Edit.vue";
// import FamilyDetail from "../views/family/Show.vue";
// import LabResult from "../views/labresult/Index.vue";
// import LabResultHistory from "../views/labresult/LabResultHistory.vue";
// import ChangePassword from "../views/ChangePassword.vue";
import UseSuccess from "../views/UseSuccess.vue";
// import ProfileUser from "../views/ProfileUser.vue";
// import ProfileUserEdit from "../views/ProfileUserEdit.vue";
// import ProfileEmergency from "../views/ProfileEmergency.vue";
// import PatientReferralOrder from "../views/doctor/PatientReferralOrder.vue";
// import PatientReferralConfirmation from "../views/doctor/PatientReferralConfirmation.vue";
// import PatientReferralComplete from "../views/doctor/PatientReferralComplete.vue";
// import PatientReferralCancel from "../views/doctor/PatientReferralCancel.vue";
import LogOut from "../views/LogOut.vue";
// import DiagnosticImaging from "../views/doctor/DiagnosticImage.vue";
// import LabReport from "../views/doctor/LabReport.vue";
// import PatientReferralNew from "../views/doctor/PatientReferralNew.vue";
// import ReferalReport from "../views/doctor/ReferalReport.vue";
// import RevenueReport from "../views/doctor/RevenueReport.vue";
// import HrUserManagement from "../views/office/HrUserManagement.vue";
// import EmployeeList from "../views/office/EmployeeList.vue";
// import EmployeeScheduleMcu from "../views/office/EmployeeScheduleMcu.vue";
// import EmployeeMcuReport from "../views/office/EmployeeMcuReport.vue";
// import BillingInvoice from "../views/office/BillingInvoice.vue";
// import HomeCare from "../views/family/HomeCare.vue";
// import HomeCareBranch from "../views/family/HomeCareBranch.vue";
import Otp from "../views/otp.vue";

// service
import Auth from "../service/auth_service";
import localStorageService from "../service/localStorageService";

// lang
import translations from "../lang/translations";
// import localizedURLs from "../lang/localized-urls";

Vue.use(LangRouter, {
  defaultLanguage: "en",
  translations,
  // localizedURLs,
});

Vue.use(Meta);
Vue.use(DatetimePicker);
const routes = [
  // all user
  {
    path: "/sign-out",
    name: "Logout",
    component: LogOut,
  },

  {
    path: "/otp/:phone",
    name: "Otp",
    component: Otp,
    meta: { notAuth: true },
  },
  {
    path: "/register-success",
    name: "Register Success",
    component: RegisterSuccess,
    meta: { notAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginV2,
    meta: { notAuth: true },
  },
  {
    path: "/",
    name: "",
    component: () => import("@/views/CheckSession.vue"),
  },
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   component: Home,
  //   meta: { requiresAuth: true, title: "Dashboard" },
  // },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { notAuth: true },
  },
  {
    path: "/barcode",
    name: "",
    component: () => import("@/views/v2/Patient/Barcode/index.vue"),
  },
  // {
  //   path: "/dashboard/change-password",
  //   name: "change_password",
  //   component: ChangePassword,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/dashboard/profile-user",
  //   name: "profile",
  //   component: ProfileUser,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/dashboard/profile-user-edit",
  //   name: "profile_user_edit",
  //   component: ProfileUserEdit,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/dashboard/profile-emergency",
  //   name: "profile_emergency",
  //   component: ProfileEmergency,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   meta: { requiresAuth: true },
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  // only user 0 lengkapi detail
  {
    path: "/continue-register",
    name: "Continue Register",
    component: ContinueRegister,
    meta: { requiresAuth: true, onlyUser: [0] },
  },
  // only user 1 pasien

  // {
  //   path: "/register-new",
  //   name: "Register New",
  //   component: RegisterNoLogout,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/cart",
  //   name: "cart",
  //   component: Cart,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/order-summary",
  //   name: "OrderSummary",
  //   component: OrderSummary,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/order-detail",
  //   name: "order_detail",
  //   component: OrderDetail,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/usage-detail",
  //   name: "usage_detail",
  //   component: UsageDetail,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/my-order",
  //   name: "my_order",
  //   component: MyOrder,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/status-order",
  //   name: "StatusOrder",
  //   component: StatusOrder,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/lab-check",
  //   name: "lab_test",
  //   component: LabCheck,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/lab-check/:slug",
  //   name: "lab_test",
  //   component: LabCheckDetail,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/my-package/:slug",
  //   name: "MyPackageUseDetail",
  //   component: MyPackageUseDetail,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  {
    path: "/payment-success",
    name: "PaymentSuccess",
    component: PaymentSuccess,
    meta: { requiresAuth: true, onlyUser: [1] },
  },
  {
    path: "/payment-pending",
    name: "PaymentPending",
    component: () => import("@/views/PaymentPending.vue"),
    meta: { requiresAuth: true, onlyUser: [1] },
  },
  {
    path: "/payment-pending-subscribe",
    name: "PaymentPendingDeal",
    component: () => import("@/views/PaymentPendingDeal.vue"),
    meta: { requiresAuth: true, onlyUser: [1] },
  },
  {
    path: "/payment-pending-package",
    name: "PaymentPendingPackage",
    component: () => import("@/views/PaymentPendingPackage.vue"),
    meta: { requiresAuth: true, onlyUser: [1] },
  },
  {
    path: "/use-success",
    name: "UseSuccess",
    component: UseSuccess,
    meta: { requiresAuth: true, onlyUser: [1] },
  },
  {
    path: "/payment-success-package",
    name: "PaymentSuccessPackage",
    component: PaymentSuccessPackage,
    meta: { requiresAuth: true, onlyUser: [1] },
  },
  {
    path: "/payment-success-subscribe",
    name: "PaymentSuccessDeal",
    component: PaymentSuccessDeal,
    meta: { requiresAuth: true, onlyUser: [1] },
  },
  {
    path: "/payment-success-lab",
    name: "PaymentSuccessLab",
    component: PaymentSuccessLab,
    meta: { requiresAuth: true, onlyUser: [1] },
  },
  {
    path: "/booking-success",
    name: "PaymentSuccessPackageUse",
    component: PaymentSuccessPackageUse,
    meta: { requiresAuth: true, onlyUser: [1] },
  },
  // {
  //   path: "/dashboard/book-appointment-list/:slug",
  //   name: "book_appointment",
  //   component: BookAppointmentList,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/branch-info/:slug",
  //   name: "info_branch",
  //   component: BranchInfo,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/buy-package-list",
  //   name: "BuyPackageList",
  //   component: BuyPackageList,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/buy-package-list/:slug",
  //   name: "BuyPackageListDetail",
  //   component: BuyPackageListDetail,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/my-package",
  //   name: "MyPackage",
  //   component: MyPackage,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/my-package-use-summary",
  //   name: "MyPackageUseSummary",
  //   component: MyPackageUseSummary,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/order-package-summary",
  //   name: "OrderPackageSummary",
  //   component: OrderPackageSummary,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/order-mcu-summary",
  //   name: "OrderPackageSummary",
  //   component: OrderMcuSummary,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/order-lab-summary",
  //   name: "OrderLabSummary",
  //   component: OrderLabSummary,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/book-appointment-list-detail",
  //   name: "BookAppointmentListDetail",
  //   component: BookAppointmentListDetail,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/book-appointment",
  //   name: "Book Appointment",
  //   component: BookAppointment,
  // },
  // {
  //   path: "/dashboard/book-appointment/list",
  //   name: "Book Appointment List",
  //   component: BookAppointmentLocation,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/book-appointment",
  //   name: "Book Appointment",
  //   component: BookAppointmentLocationMaps,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/upload-id-card",
  //   name: "Upload ID Card",
  //   component: UploadIdCard,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },

  // {
  //   path: "/dashboard/email-verification",
  //   name: "EmailVerification",
  //   component: EmailVerification,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/family",
  //   name: "family",
  //   component: Family,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/family/edit/:id",
  //   name: "Family Edit",
  //   component: FamilyEdit,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/family/:id",
  //   name: "Family Detail",
  //   component: FamilyDetail,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/lab-result",
  //   name: "lab_result",
  //   component: LabResult,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/lab-result-history",
  //   name: "lab_result_history",
  //   component: LabResultHistory,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/home-care/:slug",
  //   name: "HomeCare",
  //   component: HomeCare,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },
  // {
  //   path: "/dashboard/home-care",
  //   name: "HomeCareBranch",
  //   component: HomeCareBranch,
  //   meta: { requiresAuth: true, onlyUser: [1] },
  // },

  // only for doctor
  // {
  //   path: "/dashboard/log-patient/lab-report",
  //   name: "log_patient",
  //   component: LabReport,
  //   meta: { requiresAuth: true, onlyUser: [2] },
  // },
  // {
  //   path: "/dashboard/log-patient/diagnostic-imaging",
  //   name: "log_patient",
  //   component: DiagnosticImaging,
  //   meta: { requiresAuth: true, onlyUser: [2] },
  // },
  // {
  //   path: "/dashboard/summary-report/revenue",
  //   name: "revenue_report",
  //   component: RevenueReport,
  //   meta: { requiresAuth: true, onlyUser: [2] },
  // },
  // {
  //   path: "/dashboard/summary-report/referal",
  //   name: "doctor_report",
  //   component: ReferalReport,
  //   meta: { requiresAuth: true, onlyUser: [2] },
  // },
  // {
  //   path: "/dashboard/patient-referral/order",
  //   name: "patient_referral_order",
  //   component: PatientReferralOrder,
  //   meta: { requiresAuth: true, onlyUser: [2] },
  // },
  // {
  //   path: "/dashboard/patient-referral/confirmation",
  //   name: "patient_referral_confirmation",
  //   component: PatientReferralConfirmation,
  //   meta: { requiresAuth: true, onlyUser: [2] },
  // },
  // {
  //   path: "/dashboard/patient-referral/complete",
  //   name: "patient_referral_complete",
  //   component: PatientReferralComplete,
  //   meta: { requiresAuth: true, onlyUser: [2] },
  // },
  // {
  //   path: "/dashboard/patient-referral/cancel",
  //   name: "patient_referral",
  //   component: PatientReferralCancel,
  //   meta: { requiresAuth: true, onlyUser: [2] },
  // },
  // {
  //   path: "/dashboard/patient-referral/new",
  //   name: "patient_referral",
  //   component: PatientReferralNew,
  //   meta: { requiresAuth: true, onlyUser: [2] },
  // },

  // only for office
  // {
  //   path: "/dashboard/hr-user-management",
  //   name: "hr_user_management",
  //   component: HrUserManagement,
  //   meta: { requiresAuth: true, onlyUser: [3] },
  // },
  // {
  //   path: "/dashboard/employee/list",
  //   name: "employee_list",
  //   component: EmployeeList,
  //   meta: { requiresAuth: true, onlyUser: [3] },
  // },
  // {
  //   path: "/dashboard/employee/observation",
  //   name: "employee_schedule_mcu",
  //   component: EmployeeScheduleMcu,
  //   meta: { requiresAuth: true, onlyUser: [3] },
  // },
  // {
  //   path: "/dashboard/log-employee/employee-report",
  //   name: "employee_mcu_report",
  //   component: EmployeeMcuReport,
  //   meta: { requiresAuth: true, onlyUser: [3] },
  // },
  // {
  //   path: "/dashboard/billing/invoice",
  //   name: "billing_invoice",
  //   component: BillingInvoice,
  //   meta: { requiresAuth: true, onlyUser: [3] },
  // },

  // office v2
  {
    path: "/company",
    component: () => import("@/layouts/Office.vue"),
    children: [
      {
        path: "",
        name: "Home Office",
        meta: { requiresAuth: true, onlyUser: [3] },
        component: () => import("@/views/v2/Company/Home.vue"),
      },
      {
        path: "employee/master",
        name: "Employee List",
        meta: { requiresAuth: true, onlyUser: [3] },
        component: () => import("@/views/v2/Company/Employee/Master.vue"),
      },

      {
        path: "employee/master/add",
        name: "Employee Master Add",
        meta: { requiresAuth: true, onlyUser: [3] },
        component: () => import("@/views/v2/Company/Employee/MasterAdd.vue"),
      },
      {
        path: "employee/master/edit/:id",
        name: "Employee Master Edit",
        meta: { requiresAuth: true, onlyUser: [3] },
        component: () => import("@/views/v2/Company/Employee/MasterEdit.vue"),
      },
      {
        path: "employee/master/detail/:id",
        name: "Employee Master Detail",
        meta: { requiresAuth: true, onlyUser: [3] },
        component: () => import("@/views/v2/Company/Employee/MasterDetail.vue"),
      },
      {
        path: "office-care",
        name: "Office Care",
        meta: { requiresAuth: true, onlyUser: [3] },
        component: () =>
          import("@/views/v2/Company/office_care/OfficeCare.vue"),
      },
      {
        path: "office-care/add",
        name: "Office Care Add",
        meta: { requiresAuth: true, onlyUser: [3] },
        component: () =>
          import("@/views/v2/Company/office_care/OfficeCareAdd.vue"),
      },
      {
        path: "examination-approval/approve-list/:id",
        name: "Approve Examination",
        meta: { requiresAuth: true, onlyUser: [3] },
        component: () =>
          import("@/views/v2/Company/examination/ExaminationApproveHrd.vue"),
      },
      
      {
        path: "examination-approval",
        name: "Approval list",
        meta: { requiresAuth: true, onlyUser: [3] },
        component: () =>
          import("@/views/v2/Company/examination/ApprovalList.vue"),
      },
      {
        path: "examination-history",
        name: "Examination History",
        meta: { requiresAuth: true, onlyUser: [3] },
        component: () => import("@/views/v2/Company/examination/HistoryList.vue"),
      },
      {
        path: "deposit-transaction",
        name: "Deposit Transaction",
        meta: { requiresAuth: true, onlyUser: [3] },
        component: () =>
          import("@/views/v2/Company/deposit_transaction/index.vue"),
      },
    ],
  },

  // doctor v2
  {
    path: "/doctor",
    component: () => import("@/layouts/Doctor.vue"),
    children: [
      {
        path: "",
        name: "Home Doctor",
        meta: { requiresAuth: true, onlyUser: [2] },
        component: () => import("@/views/v2/Doctor/Home.vue"),
      },
      {
        path: "appointment/patient-list",
        name: "Patient Appointment",
        meta: { requiresAuth: true, onlyUser: [2] },
        component: () => import("@/views/v2/Doctor/Appointment/PatientList.vue"),
      },
      {
        path: "summary-report/referral",
        name: "Summary Referal",
        meta: { requiresAuth: true, onlyUser: [2] },
        component: () => import("@/views/v2/Doctor/SummaryReport/Referral.vue"),
      },
      {
        path: "summary-report/fee",
        name: "Summary Fee",
        meta: { requiresAuth: true, onlyUser: [2] },
        component: () => import("@/views/v2/Doctor/SummaryReport/Fee.vue"),
      },
      {
        path: "referral/confirmation",
        name: "Referral Confirmation",
        meta: { requiresAuth: true, onlyUser: [2] },
        component: () => import("@/views/v2/Doctor/Referral/Confirmation.vue"),
      },
      {
        path: "referral/complete",
        name: "Referral Complete",
        meta: { requiresAuth: true, onlyUser: [2] },
        component: () => import("@/views/v2/Doctor/Referral/Complete.vue"),
      },
      // {
      //   path: "setting/profile",
      //   name: "Setting Profile Doctor",
      //   meta: { requiresAuth: true, onlyUser: [2] },
      //   component: () => import("@/views/v2/Doctor/Setting/Profile.vue"),
      // },
      // {
      //   path: "setting/password",
      //   name: "Setting Password Doctor",
      //   meta: { requiresAuth: true, onlyUser: [2] },
      //   component: () => import("@/views/v2/Doctor/Setting/Password.vue"),
      // },
    ],
  },

  // Medical Center v2
  {
    path: "/medical-center",
    component: () => import("@/layouts/MedicalCenter.vue"),
    children: [
      {
        path: "",
        name: "Home Medical",
        meta: { requiresAuth: true, onlyUser: [6] },
        component: () => import("@/views/v2/MedicalCenter/Home.vue"),
      },
      {
        path: "mr-patient",
        name: "Medical Record",
        meta: { requiresAuth: true, onlyUser: [6] },
        component: () => import("@/views/v2/MedicalCenter/Record/Patient.vue"),
      },
      // {
      //   path: "summary-report/referral",
      //   name: "Summary Referal MC",
      //   meta: { requiresAuth: true, onlyUser: [6] },
      //   component: () =>
      //     import("@/views/v2/MedicalCenter/SummaryReport/Referral.vue"),
      // },
      // {
      //   path: "summary-report/fee",
      //   name: "Summary Fee MC",
      //   meta: { requiresAuth: true, onlyUser: [6] },
      //   component: () =>
      //     import("@/views/v2/MedicalCenter/SummaryReport/Fee.vue"),
      // },
      // {
      //   path: "referral/confirmation",
      //   name: "Referral Confirmation MC",
      //   meta: { requiresAuth: true, onlyUser: [6] },
      //   component: () =>
      //     import("@/views/v2/MedicalCenter/Referral/Confirmation.vue"),
      // },
      // {
      //   path: "referral/complete",
      //   name: "Referral Complete MC",
      //   meta: { requiresAuth: true, onlyUser: [6] },
      //   component: () =>
      //     import("@/views/v2/MedicalCenter/Referral/Complete.vue"),
      // },
      // {
      //   path: "setting/profile",
      //   name: "Setting Profile Doctor",
      //   meta: { requiresAuth: true, onlyUser: [2] },
      //   component: () => import("@/views/v2/Doctor/Setting/Profile.vue"),
      // },
      // {
      //   path: "setting/password",
      //   name: "Setting Password Doctor",
      //   meta: { requiresAuth: true, onlyUser: [2] },
      //   component: () => import("@/views/v2/Doctor/Setting/Password.vue"),
      // },
    ],
  },

  // patient v2
  {
    path: "/patient",
    component: () => import("@/layouts/Patient.vue"),
    children: [
      {
        path: "",
        name: "Home Patient",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/Home.vue"),
      },
      {
        path: "test",
        name: "Test",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/test/index.vue"),
      },
      {
        path: "test/:id",
        name: "Detail Test",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/test/detail.vue"),
      },
      {
        path: "voucher",
        name: "Voucher",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/voucher/index.vue"),
      },
      {
        path: "promo",
        name: "Promo",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/Promo/Index.vue"),
      },
      {
        path: "promo/detail",
        name: "Detail Promo",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/Promo/Detail.vue"),
      },
      {
        path: "nearme",
        name: "Near Me List",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () =>
          import("@/views/v2/Patient/book_appointment/index.vue"),
      },
      {
        path: "nearme/map-view",
        name: "Near Me Map",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () =>
          import("@/views/v2/Patient/book_appointment/MapView.vue"),
      },
      {
        path: "home-care",
        name: "Home Care",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/home_care/index.vue"),
      },
      {
        path: "profile",
        name: "Profile",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/profile/index.vue"),
      },
      {
        path: "medical-record/:id",
        name: "Detail Medical Record",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () =>
          import("@/views/v2/Patient/Tabs/DetailMedicalInfo.vue"),
      },
      {
        path: "buy-package",
        name: "Buy Package",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/buy_package/index.vue"),
      },
      {
        path: "buy-package/:id",
        name: "Detail Package",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/buy_package/detail.vue"),
      },
      {
        path: "buy-subscription",
        name: "Buy Subscription",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () =>
          import("@/views/v2/Patient/buy_subscription/index.vue"),
      },
      {
        path: "buy-subscription/:id",
        name: "Detail Subscription",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () =>
          import("@/views/v2/Patient/buy_subscription/detail.vue"),
      },
      {
        path: "lab-result",
        name: "Lab Result",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/lab_result/index.vue"),
      },
      {
        path: "lab-result/history",
        name: "Lab Result History",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/lab_result/history.vue"),
      },
      // {
      //   path: "setting/profile",
      //   name: "Setting Profile Patient",
      //   meta: { requiresAuth: true, onlyUser: [1] },
      //   component: () => import("@/views/v2/Patient/Setting/Photo.vue"),
      // },
      // {
      //   path: "setting/password",
      //   name: "Setting Password",
      //   meta: { requiresAuth: true, onlyUser: [1] },
      //   component: () => import("@/views/v2/Patient/Setting/Password.vue"),
      // },
      // {
      //   path: "setting/emergency",
      //   name: "Setting Emergency",
      //   meta: { requiresAuth: true, onlyUser: [1] },
      //   component: () => import("@/views/v2/Patient/Setting/Emergency.vue"),
      // },
      // branch
      {
        path: "branch/:id",
        name: "Detail Branch",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/branch/detail.vue"),
      },
      // family
      {
        path: "family/:id",
        name: "Detail Family",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/family/index.vue"),
      },
      // cart
      {
        path: "cart",
        name: "Cart",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/cart/index.vue"),
      },
      {
        path: "profile?tab=orderr",
        name: "My Booking",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/mybooking/index.vue"),
      },
      // order package
      {
        path: "order-package/:id",
        name: "Order Package",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/order/package/index.vue"),
      },
      // order deal
      {
        path: "order-deal/:id",
        name: "Order Deal",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/order/deal/index.vue"),
      },
      // order subscribe
      {
        path: "order-subscription/:id",
        name: "Order Subscription",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () =>
          import("@/views/v2/Patient/order/subscription/index.vue"),
      },
      {
        path: "order-test/:branch/:id",
        name: "Order Test",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/order/test/index.vue"),
      },
      // Deal Page
      {
        path: "deal",
        name: "Deal",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/deal/index.vue"),
      },
      {
        path: "use-subscription-packages/:id",
        name: "Use Deal Packages",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/order/use/packagesIndex.vue"),
      },
      {
        path: "use-subscription-test/:id",
        name: "Use Deal Test",
        meta: { requiresAuth: true, onlyUser: [1] },
        component: () => import("@/views/v2/Patient/order/use/testIndex.vue"),
      },  
    ],
  },
  {
    path: "translate",
    name: "Translate",
    component: () => import("@/views/translate.vue"),
  },
  // 404 bottom position
  { path: "/page-not-allowed", component: Error403 },
  { path: "*", component: Error404 },
];
// if(process.env.NODE_ENV != 'production'){
//   routes.push(
//     {
//       path: "/register",
//       name: "Register",
//       component: Register,
//       meta: { notAuth: true },
//     },
//   )
// }
const router = new LangRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!Auth.checkSession()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      if (
        typeof to.meta.onlyUser !== "undefined" &&
        to.meta.onlyUser.length > 0
      ) {
        if (
          !to.meta.onlyUser.includes(
            parseInt(localStorageService.getItem("type"))
          )
        ) {
          next({
            path: "/page-not-allowed",
          });
        }
      }
      next();
    }
  } else if (to.matched.some(record => record.meta.notAuth)) {
    if (Auth.checkSession()) {
      next({
        path: `/`,
      });
    } else {
      next();
    }
  } else {
    if (to.meta.redirect) {
      next({
        path: to.meta.redirect,
      });
    } else {
      next(); // make sure to always call next()!
    }
  }
});

export default router;
