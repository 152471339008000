// import API from "../../service/api_service";
import en from "./en/index.js";
import id from "./id/index.js";
const lang = { id, en };
const translations = {
  en: {
    name: "English",
    load: () => getTranslation("en"),
  },
  id: {
    name: "Indonesia",
    load: () => getTranslation("id"),
  },
};

async function getTranslation(id) {
  // from api
  // try {
  //   const resp = await API.get(
  //     `{{url}}`,
  //     {
  //       "Content-Type": "application/json",
  //     }
  //   );
  //   return resp[lang];
  // } catch (error) {
  //   console.log(error);
  // }
  return lang[id];
}

export default translations;
