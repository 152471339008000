<template>
  <div class="main-layout">
    <div class="px-5 content-set">
      <div class="card--payment d-desktop">
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <img
              :src="require('../assets/images/ilustrations/IluSuccessPaid.png')"
              height="170"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <strong class="subheading">{{ $t("success_payment") }} ✨</strong>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <p class="content text-center">
              {{ $t("congrats_payment_verify") }}
            </p>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="home_care == 'true'">
          <v-col class="align-center justify-center">
            <p class="text-center title-invoice">
              {{ $t("page.referral.order_num") }} :
            </p>
            <strong>
              <p
                class="subheading text-center iconsSet"
                @click="openDialogInvoiceHomecare(order_number_desc)"
              >
                {{ order_number_desc }}
              </p>
            </strong>
            <p class="text-center title-invoice">
              {{ $t("page.referral.order_desc") }} :
            </p>
            <div v-for="(item, index) in test" :key="index">
              <p class="subheading-desc text-center" style="font-weight: 700">
                {{ item.patient_name }}
              </p>
              <div
                v-for="(part, index) in item.participant_test"
                :key="index"
                style="display: flex; justify-content: center"
              >
                <div
                  class="item"
                  v-for="(catItem, index) in part.product_data"
                  :key="index"
                >
                  <p
                    v-if="catItem === part.product_data.slice(-1)[0]"
                    class="subheading-desc text-center"
                  >
                    {{ catItem.product_name }}
                  </p>
                  <p v-else class="subheading-desc text-center">
                    {{ catItem.product_name }},
                  </p>
                </div>
              </div>
            </div>
            <!-- <strong > 
              <p class="subheading-desc text-center">
                {{ product_desc }}
              </p>
            </strong> -->
          </v-col>
        </v-row>
        <v-row no-gutters v-else>
          <v-col class="align-center justify-center">
            <p class="text-center title-invoice">
              {{ $t("page.referral.order_num") }} :
            </p>
            <strong>
              <p
                class="subheading text-center iconsSet"
                @click="openDialogInvoice(order_number_desc)"
              >
                {{ order_number_desc }}
              </p>
            </strong>
            <p class="text-center title-invoice">
              {{ $t("page.referral.order_desc") }} :
            </p>
            <strong>
              <p class="subheading-desc text-center">
                {{ product_desc }}
              </p>
            </strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <localized-link to="/patient/profile?tab=ORDER">
              <c-button rounded shadow bold>
                {{ $t("view_order") }}
              </c-button>
            </localized-link>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <localized-link to="/">
              <icons icon="house" color="#00a4b3" />
            </localized-link>
          </v-col>
        </v-row>

        <DialogInvoice
          :item="dataDialogInvoice"
          :tampilkan="dialog_invoice"
          :tableFilter="tableInvoice"
          :prodName="prodName"
          @toggleDialog="is_show => toggleDialogInvoice(is_show)"
          v-if="dialog_invoice"
        />
      </div>

      <DialogInvoiceHomecare
        :item="dataDialogInvoiceHomecare"
        :tampilkan="dialog_invoice_homecare"
        @toggleDialog="is_show => toggleDialogInvoiceHomecare(is_show)"
        v-if="dialog_invoice_homecare"
      />

      <!-- batas desktop mobile -->
      <div class="card--payment d-mobile">
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <img
              :src="require('../assets/images/ilustrations/IluSuccessPaid.png')"
              height="170"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <strong class="subheading">{{ $t("success_payment") }} ✨</strong>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <p class="content text-center">
              {{ $t("congrats_payment_verify") }}
            </p>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col class="d-flex align-center justify-center">
            <template v-if="home_care == 'true'">
              <c-button
                rounded
                shadow
                bold
                @click="openDialogInvoiceHomecare(order_number_desc)"
                style="margin-right: 20px"
              >
                {{ $t("view_invoice") }}
              </c-button>
            </template>
            <template v-else>
              <c-button
                rounded
                shadow
                bold
                @click="openDialogInvoiceMobile(order_number_desc)"
                style="margin-right: 20px"
              >
                {{ $t("view_invoice") }}
              </c-button>
            </template>

            <localized-link to="/patient/profile?tab=ORDER">
              <c-button rounded shadow bold>
                {{ $t("view_order") }}
              </c-button>
            </localized-link>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <localized-link to="/">
              <icons icon="house" color="#00a4b3" />
            </localized-link>
          </v-col>
        </v-row>

        <DialogInvoiceMobile
          :item="dataDialogInvoiceMobile"
          :tampilkan="dialog_invoice_mobile"
          :tableFilter="tableInvoiceMobile"
          @toggleDialog="is_show => toggleDialogInvoiceMobile(is_show)"
          v-if="dialog_invoice_mobile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Icons from "@/components/elements/Icons.vue";
import CButton from "@/components/v2/button/index.vue";
import API from "../service/api_service";
import Auth from "../service/auth_service";
import DialogInvoiceMobile from "@/components/v3/invoice/index.vue";
import DialogInvoice from "@/components/v2/dialog/dialogInvoice.vue";
import DialogInvoiceHomecare from "@/components/v2/dialog/dialogInvoiceHomecare.vue";
export default {
  components: {
    Icons,
    CButton,
    DialogInvoice,
    DialogInvoiceMobile,
    DialogInvoiceHomecare,
  },
  data: () => ({
    tableInvoice: [],
    groupItemCategory: [],
    dataDialogInvoice: {
      order_number: null,
      subscription: false,
      list: [],
      data: {
        metadata: {},
      },
    },
    dialog_invoice: false,
    order_number_desc: "",
    product_desc: "",
    tableInvoiceMobile: [],
    dialog_invoice_mobile: false,
    dataDialogInvoiceMobile: {
      order_number: null,
      subscription: false,
      list: [],
      data: {
        metadata: {},
      },
    },
    home_care: "false",
    test: [],
    dataDialogInvoiceHomecare: {
      order_number: null,
    },
    dialog_invoice_homecare: false,
    prodName: "",
  }),
  created() {
    this.home_care = this.$route.query.home_care;
    this.resetCart();
    if (this.$route.query.order_id != undefined) {
      if (this.home_care == "true") {
        API.get(
          `${process.env.VUE_APP_API_TRUST}registration/status-transaction?invoice_number=${this.$route.query.order_id}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then(res => {
          this.order_number_desc = res.order_number;
          this.openDialogInvoiceHomecare(res.order_number);

          API.get(
            `${process.env.VUE_APP_API_TRUST}invoices/get-detail-homecare?order_number=` +
              res.order_number +
              ``,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          ).then(res => {
            this.test = res.results.participant;
          });
        });
      } else {
        // start check is voucher
        if (this.$route.query.order_number != undefined) {
          this.order_number_desc = this.$route.query.order_number;
          setTimeout(
            function () {
              if (window.innerWidth < 767) {
                this.openDialogInvoiceMobile(this.$route.query.order_number);
              } else {
                this.openDialogInvoice(this.$route.query.order_number);
              }
            }.bind(this),
            1000
          );
        }
        // end check is voucher
        else {
          API.get(
            `${process.env.VUE_APP_API_TRUST}registration/status-transaction?invoice_number=${this.$route.query.order_id}`,
            {
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          ).then(res => {
            if (res.status_code == 200) {
              this.order_number_desc = res.order_number;
              setTimeout(
                function () {
                  if (window.innerWidth < 767) {
                    this.openDialogInvoiceMobile(res.order_number);
                  } else {
                    this.openDialogInvoice(res.order_number);
                  }
                }.bind(this),
                1000
              );
            }
          });
        }
      }
    }
  },
  methods: {
    async resetCart() {
      // reset cart

      try {
        await API.post(`${process.env.VUE_APP_API_TRUST}cart/empty`, {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        });
      } catch (error) {
        console.log(error);
      }
    },
    toggleDialogInvoice(toggle) {
      this.dialog_invoice = toggle;
    },
    async openDialogInvoice(item) {
      this.dataDialogInvoice.order_number = item;
      this.dataDialogInvoice.subscription = false;
      this.getOrderDetailInvoiceMCU(item);
      this.dialog_invoice = true;
    },
    async getOrderDetailInvoiceMCU(item_order_number) {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}invoices/get-detail?order_number=` +
            item_order_number +
            ``,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.dataDialogInvoice.data = res.results;
        this.dataDialogInvoice.list = res.results.data;

        if (res.results.package_name == undefined) {
          const resMyJSON = this.dataDialogInvoice.list.map(element => {
            return element.product_name;
          });
          const myJSON = JSON.stringify(resMyJSON);
          this.product_desc = myJSON.replace(/[^ a-z A-Z 0-9 , ()/]/g, "");
        } else {
          this.product_desc = res.results.package_name;
          this.prodName = res.results.package_name;
        }

        //cari harga total karena diskon belum kehitung
        let grand_total =
          parseInt(this.dataDialogInvoice.data.metadata.amount_total) -
          parseInt(this.dataDialogInvoice.data.metadata.total_discount);
        this.dataDialogInvoice.data.metadata.grand_total = grand_total;

        let groupCategory = this.dataDialogInvoice.data.data.reduce((r, a) => {
          r[a.sub_category] = [...(r[a.sub_category] || []), a];
          return r;
        }, {});

        this.groupItemCategory = Object.entries(groupCategory).map(arr => ({
          nameCategory: arr[0],
          dataCategory: arr[1],
        }));

        let filterItem = [];
        this.groupItemCategory.forEach(element => {
          filterItem = [
            ...filterItem,
            { text: element.nameCategory, type: "category" },
          ];
          let groupSubcategory = element.dataCategory.reduce((r, a) => {
            r[a.product_name] = [...(r[a.product_name] || []), a];
            return r;
          }, {});

          this.groupItemSubategory = Object.entries(groupSubcategory).map(
            arr => ({
              nameSubcategory: arr[0],
              dataSubcategory: arr[1],
            })
          );

          this.groupItemSubategory.forEach(element => {
            filterItem = [
              ...filterItem,
              { text: element.nameSubcategory, type: "subcategory" },
            ];
            element.dataSubcategory.forEach(element => {
              filterItem = [...filterItem, { ...element, type: "item" }];
            });
          });
        });

        this.tableInvoice = filterItem;
      } catch (error) {
        console.log(error);
      }
    },
    toggleDialogInvoiceMobile(toggle) {
      this.dialog_invoice_mobile = toggle;
    },
    async openDialogInvoiceMobile(item) {
      this.dataDialogInvoiceMobile.order_number = item;
      this.getOrderDetailInvoiceMCUMobile(item);
      this.dialog_invoice_mobile = true;
    },
    async getOrderDetailInvoiceMCUMobile(item_order_number) {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}invoices/get-detail?order_number=` +
            item_order_number +
            ``,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.dataDialogInvoiceMobile.data = res.results;
        this.dataDialogInvoiceMobile.list = res.results.data;
        const resMyJSON = this.dataDialogInvoiceMobile.list.map(element => {
          return element.product_name;
        });
        const myJSON = JSON.stringify(resMyJSON);
        this.product_desc = myJSON.replace(/[^ a-z A-Z 0-9 , ()/]/g, "");

        //cari harga total karena diskon belum kehitung
        let grand_total =
          parseInt(this.dataDialogInvoiceMobile.data.metadata.amount_total) -
          parseInt(this.dataDialogInvoiceMobile.data.metadata.total_discount);
        this.dataDialogInvoiceMobile.data.metadata.grand_total = grand_total;

        let groupCategory = this.dataDialogInvoiceMobile.data.data.reduce(
          (r, a) => {
            r[a.sub_category] = [...(r[a.sub_category] || []), a];
            return r;
          },
          {}
        );

        this.groupItemCategory = Object.entries(groupCategory).map(arr => ({
          nameCategory: arr[0],
          dataCategory: arr[1],
        }));

        let filterItem = [];
        this.groupItemCategory.forEach(element => {
          filterItem = [
            ...filterItem,
            { text: element.nameCategory, type: "category" },
          ];
          let groupSubcategory = element.dataCategory.reduce((r, a) => {
            r[a.product_name] = [...(r[a.product_name] || []), a];
            return r;
          }, {});

          this.groupItemSubategory = Object.entries(groupSubcategory).map(
            arr => ({
              nameSubcategory: arr[0],
              dataSubcategory: arr[1],
            })
          );

          this.groupItemSubategory.forEach(element => {
            filterItem = [
              ...filterItem,
              { text: element.nameSubcategory, type: "subcategory" },
            ];
            element.dataSubcategory.forEach(element => {
              filterItem = [...filterItem, { ...element, type: "item" }];
            });
          });
        });

        this.tableInvoiceMobile = filterItem;
      } catch (error) {
        console.log(error);
      }
    },
    toggleDialogInvoiceHomecare(toggle) {
      this.dialog_invoice_homecare = toggle;
    },
    async openDialogInvoiceHomecare(item) {
      this.dataDialogInvoiceHomecare.order_number = item;
      this.getOrderDialogInvoiceHomecare(item);
    },
    async getOrderDialogInvoiceHomecare(order_number) {
      const resp = await API.get(
        `${process.env.VUE_APP_API_TRUST}invoices/get-detail-homecare?order_number=` +
          order_number +
          ``,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      );
      if (resp.statusCode == 200) {
        this.dataDialogInvoiceHomecare = {};
        const { results } = resp;
        let dataInvoice = {
          order_number: order_number,
          document_info: {
            invoice_number: results.invoice_number,
            invoice_date: results.invoice_date,
            plant_name: results.plant_name,
            medical_record_number: results.medical_record_number,
            practitioner_name: results.practitioner_name,
            payment_method: results.payment_method_name,
            gender: results[`gender_name_${this.$i18n.locale}`],
            currency: results.currency,
            total_payment: results.total_payment,
          },
          customer_info: {
            name: results.name,
            address: results.address,
            email: results.email_contact,
            dob: results.person_dob,
            age: results.age,
            district_city: `${results.subdistrict_name}, ${results.district_name}, ${results.city_name}`,
            postal_province: `${results.province_name} ${results.postal_code}, ${results.country_name}`,
            mobile_phone:
              results.celluar_contact == null
                ? "-"
                : `${results.celluar_contact_code}${results.celluar_contact}`,
            home_contact:
              results.home_contact == null
                ? "-"
                : `${results.home_contact_code}${results.home_contact}`,
          },
          amount_total: 0,
          total_discount: 0,
          grand_total: 0,
          price_discount: 0,
          tables: [],
        };

        results.participant.forEach(item => {
          dataInvoice.tables = [
            ...dataInvoice.tables,
            { type: "name", name: item.patient_name },
          ];
          item.participant_test.forEach(participant => {
            participant.product_data.forEach((category, indexCategory) => {
              if (indexCategory == 0) {
                dataInvoice.tables = [
                  ...dataInvoice.tables,
                  {
                    type: "category",
                    category_id: participant.category_name,
                    category_en: participant.category_name_en,
                  },
                ];
              }
              dataInvoice.tables = [
                ...dataInvoice.tables,
                {
                  type: "sub_category",
                  sub_category_id: category.product_name,
                  sub_category_en: category.product_name_en,
                },
              ];
              dataInvoice.tables = [
                ...dataInvoice.tables,
                {
                  type: "item",
                  product: category,
                },
              ];
              dataInvoice.amount_total =
                parseFloat(category.product_price) +
                parseFloat(dataInvoice.amount_total);
              dataInvoice.total_discount =
                parseFloat(category.product_discount) +
                parseFloat(dataInvoice.total_discount);
              dataInvoice.price_discount =
                parseFloat(
                  (category.product_discount / 100) * category.product_price
                ) + parseFloat(dataInvoice.price_discount);
            });
          });
        });
        dataInvoice.grand_total =
          parseFloat(dataInvoice.amount_total) -
          parseFloat(dataInvoice.price_discount);
        this.dataDialogInvoiceHomecare = dataInvoice;
        this.dialog_invoice_homecare = true;
      }
    },
  },
};
</script>
<style lang="scss">
.d-mobile {
  display: none;
}
.d-desktop {
  display: block;
}
@media only screen and (max-width: 767px) {
  .d-mobile {
    display: block;
  }
  .d-desktop {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
@import "../assets/css/custom.scss";

.card--payment {
  padding: 32px 15px;
  border-radius: 16px;
}

.header {
  padding: 30px 0 10px 0;
}
.subheading {
  color: rgba(121, 196, 198, 1);
  font-size: 18px;
}
.subheading-desc {
  color: rgba(121, 196, 198, 1);
  font-size: 14px;
  margin-bottom: 0;
}
.rp {
  color: $gray_2;
  font-size: 18px;
  margin-top: 40px;
}
.to-home {
  color: $gray_2;
  font-size: 18px;
}
.content {
  font-size: 16px;
  margin-top: 15px;
  font-weight: 500;
  color: rgba(123, 140, 174, 1);
}
.content-amount {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  color: rgba(123, 140, 174, 1);
}
.content-set {
  padding: 10px 0 0 0;
}
.amount {
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  padding: 15px 40px;
  border-radius: 15px;
}
a {
  text-decoration: none;
}
.iconsSet {
  cursor: pointer;
  margin: 0 30%;
}
.title-invoice {
  font-size: 16px;
  font-weight: 500;
  color: rgba(123, 140, 174, 1);
  margin-bottom: 0;
}

@media only screen and (max-width: 714px) {
  .iconsSet {
    cursor: pointer;
    margin: 0 20%;
  }
}
</style>
