<template>
  <div class="card">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.card {
  padding: 32px 15px;
  border-radius: 16px;
  background: #fff;
}
</style>
