// get localStorage expiry time
const getItem = key => {
    const data = localStorage.getItem(key);
    if (data) {
        const parsedData = JSON.parse(data);
        if (new Date(parsedData.expiry) && new Date(parsedData.expiry) < Date.now()) {
        removeItem(key);
        return null;
        }
        return parsedData.value;
    }
    return null;
};
const setItem = (key, value, ...expiry) => {
  const data = {
    value,
    expiry:expiry[0],
  };
  localStorage.setItem(key, JSON.stringify(data));
};
const removeItem = key => {
  localStorage.removeItem(key);
};

const localStorageService = {
  getItem,
  setItem,
  removeItem,
};

export default localStorageService;
