<template>
  <svg
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3994 15L0.660661 15C0.3003 15 0 14.6053 0 14.1316L0 10.3421L0.24024 10.2632C1.08108 9.94737 1.68168 8.76316 1.68168 7.5C1.68168 6.23684 1.08108 5.13158 0.24024 4.73684H0L0 0.868421C0 0.394737 0.3003 0 0.660661 0L19.3994 0C19.7598 0 20.0601 0.394737 20.0601 0.868421V4.6579L19.7598 4.73684C18.8589 4.97368 18.1982 6.1579 18.1982 7.5C18.1982 8.84211 18.8589 10.0263 19.7598 10.2632L20 10.3421L20 14.1316C20 14.6053 19.7598 15 19.3994 15ZM0.600601 10.8947L0.600601 14.1316C0.600601 14.2105 0.600601 14.2105 0.660661 14.2105L19.3994 14.2105L19.4595 14.1316L19.4595 10.9737C18.3784 10.5 17.6577 9.07895 17.6577 7.5C17.6577 5.92105 18.4384 4.5 19.4595 4.02632V0.868421C19.4595 0.789474 19.4595 0.789474 19.3994 0.789474L0.660661 0.789474C0.600601 0.789474 0.600601 0.868421 0.600601 0.868421L0.600601 4.10526C1.56156 4.65789 2.28228 6 2.28228 7.5C2.28228 9 1.56156 10.3421 0.600601 10.8947Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: ["color"],
};
</script>
