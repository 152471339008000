<template>
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
    class="ic-cart"
  >
    <path
      d="M17.4206 10.0047L18.98 3.09848C19.0926 2.59984 18.716 2.125 18.208 2.125H5.25165L4.9493 0.637168C4.87396 0.266322 4.54978 0 4.1737 0H0.791667C0.354436 0 0 0.356768 0 0.796875V1.32812C0 1.76823 0.354436 2.125 0.791667 2.125H3.09684L5.41404 13.5281C4.85968 13.849 4.48611 14.4507 4.48611 15.1406C4.48611 16.1675 5.31314 17 6.33333 17C7.35353 17 8.18056 16.1675 8.18056 15.1406C8.18056 14.6202 7.9679 14.15 7.6256 13.8125H14.541C14.1988 14.15 13.9861 14.6202 13.9861 15.1406C13.9861 16.1675 14.8131 17 15.8333 17C16.8535 17 17.6806 16.1675 17.6806 15.1406C17.6806 14.4044 17.2554 13.7683 16.6389 13.467L16.8209 12.661C16.9335 12.1623 16.5569 11.6875 16.0489 11.6875H7.19483L6.97894 10.625H16.6487C17.0183 10.625 17.3387 10.3675 17.4206 10.0047Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
