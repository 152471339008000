var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-layout"},[_c('v-container',{staticClass:"mt-14 containt"},[_c('div',{staticClass:"d-flex align-center justify-center pb-5"},[_c('router-link',{attrs:{"to":"/dashboard"}},[_c('img',{attrs:{"src":require('../../assets/logoDigiLab.png'),"height":"50"}})])],1),(_vm.loading)?_c('div',{staticClass:"d-flex justify-center"},[_c('icons',{attrs:{"icon":"loading"}})],1):_vm._e(),(!_vm.openOtp)?[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","offset-md":"3"}},[_c('Card',{staticClass:"mt-6"},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"field-padding",attrs:{"cols":"4","md":"3"}},[_c('v-autocomplete',{staticClass:"mt-0 uppercase-input",attrs:{"outlined":"","dense":"","items":_vm.titleName,"item-text":"name","item-value":"id","rules":[
                      v => !!v || `${_vm.$t('field.title')} ${_vm.$t('is_required')}`,
                    ],"label":_vm.$t('field.title'),"required":""},model:{value:(_vm.payload.title),callback:function ($$v) {_vm.$set(_vm.payload, "title", $$v)},expression:"payload.title"}})],1),_c('v-col',{staticClass:"field-padding",attrs:{"cols":"8","md":"4"}},[_c('v-text-field',{staticClass:"mt-0 uppercase-input",attrs:{"outlined":"","dense":"","rules":[
                      v =>
                        !!v ||
                        `${_vm.$t('field.first_name')} ${_vm.$t('is_required')}`,
                    ],"label":_vm.$t('field.first_name'),"required":""},model:{value:(_vm.payload.firstname),callback:function ($$v) {_vm.$set(_vm.payload, "firstname", $$v)},expression:"payload.firstname"}})],1),_c('v-col',{staticClass:"field-padding",attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{staticClass:"mt-0 uppercase-input",attrs:{"outlined":"","dense":"","rules":[
                      v =>
                        !!v ||
                        `${_vm.$t('field.last_name')} ${_vm.$t('is_required')}`,
                    ],"label":_vm.$t('field.last_name'),"required":""},model:{value:(_vm.payload.lastname),callback:function ($$v) {_vm.$set(_vm.payload, "lastname", $$v)},expression:"payload.lastname"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"column-phone-code field-padding",attrs:{"cols":"4","md":"3"}},[_c('PhoneNumber',{staticClass:"input-phone",attrs:{"items":_vm.phoneCodeItems,"value":_vm.defaultPhoneCode,"rules":[
                      v =>
                        !!v ||
                        `${_vm.$t('field.phone_code')} ${_vm.$t('is_required')}`,
                    ]},on:{"changePhoneCode":selected => _vm.update(selected)}})],1),_c('v-col',{staticClass:"column-phone-number field-padding",attrs:{"cols":"8","md":"9"}},[_c('v-text-field',{attrs:{"outlined":"","required":"","dense":"","rules":[
                      v =>
                        !!v ||
                        `${_vm.$t('field.phone_number')} ${_vm.$t('is_required')}`,
                    ],"label":_vm.$t('field.phone_number')},model:{value:(_vm.formatedPhoneNumber),callback:function ($$v) {_vm.formatedPhoneNumber=$$v},expression:"formatedPhoneNumber"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"column-username field-padding",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mt-0",attrs:{"outlined":"","dense":"","rules":[
                      v =>
                        !!v || `${_vm.$t('field.username')} ${_vm.$t('is_required')}`,
                    ],"label":_vm.$t('field.username'),"required":""},model:{value:(_vm.payload.username),callback:function ($$v) {_vm.$set(_vm.payload, "username", $$v)},expression:"payload.username"}})],1),_c('v-col',{staticClass:"column-email field-padding",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mt-0 uppercase-input",attrs:{"outlined":"","dense":"","rules":[_vm.emailRules],"label":"Email","required":""},model:{value:(_vm.payload.email),callback:function ($$v) {_vm.$set(_vm.payload, "email", $$v)},expression:"payload.email"}})],1),_c('v-col',{staticClass:"column-password field-padding",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mt-0",attrs:{"outlined":"","dense":"","rules":[
                      v =>
                        !!v || `${_vm.$t('field.password')} ${_vm.$t('is_required')}`,
                    ],"label":_vm.$t('field.password'),"append-icon":_vm.show_password ? 'visibility' : 'visibility_off',"type":_vm.show_password ? 'text' : 'password',"required":""},on:{"click:append":function($event){_vm.show_password = !_vm.show_password}},model:{value:(_vm.payload.password),callback:function ($$v) {_vm.$set(_vm.payload, "password", $$v)},expression:"payload.password"}})],1),_c('v-col',{staticClass:"column-confirm-password field-padding",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mt-0",attrs:{"outlined":"","dense":"","rules":[_vm.passwordConfirmRules],"label":_vm.$t('field.confirm_password'),"append-icon":_vm.show_confirm_password ? 'visibility' : 'visibility_off',"type":_vm.show_confirm_password ? 'text' : 'password',"required":""},on:{"click:append":function($event){_vm.show_confirm_password = !_vm.show_confirm_password}},model:{value:(_vm.payload.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.payload, "passwordConfirm", $$v)},expression:"payload.passwordConfirm"}})],1)],1),_c('div',{staticClass:"d-flex align-center justify-space-between pt-3"},[_c('p',{staticClass:"already-text"},[_vm._v(" "+_vm._s(_vm.$t("page.register.already_has_account"))+" "),_c('br'),_c('router-link',{attrs:{"to":"/login"}},[_vm._v(_vm._s(_vm.$t("page.login.sign_in")))])],1),_c('v-btn',{staticClass:"mr-0 button-register mr-md-4 mr-lg-4",attrs:{"disabled":!_vm.valid,"color":"white","rounded":""},on:{"click":_vm.validate}},[_vm._v(" "+_vm._s(_vm.$t("next"))+" ")])],1)],1)],1)],1)],1)]:_vm._e(),(!_vm.loading && _vm.openOtp)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","offset-md":"3"}},[_c('Card',[_c('Otp',{attrs:{"title":_vm.otpName,"email":_vm.payload.email,"timeEnd":_vm.distance},on:{"handleOnComplete":item => _vm.handleOnComplete(item)}})],1)],1)],1)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }