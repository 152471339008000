<template>
 <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.864 19.9503H1.44563C0.911617 19.9503 0.644607 19.6843 0.3776 19.4183C0.110594 19.1523 -0.156408 18.6203 0.110598 18.0883L2.24665 8.77814C2.24665 7.98013 3.04766 7.44812 3.84868 7.44812H14.2619C15.0629 7.44812 15.5969 7.98013 15.864 8.77814L18 18.0883C18 18.6203 18 19.1523 17.733 19.4183C16.665 19.6843 16.398 19.9503 15.864 19.9503ZM3.31466 9.04415L1.17862 18.3543H15.597L13.4609 9.04415H3.31466Z" :fill="color"/>
<path d="M8.65467 17.0243C6.78563 17.0243 5.18359 15.4282 5.18359 13.5662C5.18359 11.7042 6.78563 10.1082 8.65467 10.1082C10.5237 10.1082 12.1258 11.7042 12.1258 13.5662C11.8588 15.6942 10.5237 17.0243 8.65467 17.0243ZM8.65467 11.9702C7.58665 11.9702 6.78564 12.7682 6.78564 13.8322C6.78564 14.8962 7.58665 15.6942 8.65467 15.6942C9.72269 15.6942 10.5237 14.8962 10.5237 13.8322C10.5237 12.7682 9.72269 11.9702 8.65467 11.9702Z" :fill="color"/>
<path d="M11.0577 9.04414H6.25165V3.72406H11.0577V9.04414ZM7.85368 7.44812H9.45572V5.32008H7.85368V7.44812Z" :fill="color"/>
<path d="M12.1259 5.32008H5.18373C3.5817 5.32008 2.51367 4.25607 2.51367 2.66004C2.51367 1.06402 3.5817 0 5.18373 0H12.1259C13.7279 0 14.796 1.06402 14.796 2.66004C14.796 4.25607 13.4609 5.32008 12.1259 5.32008ZM5.18373 1.59602C4.64972 1.59602 4.11571 2.12803 4.11571 2.66004C4.11571 3.19205 4.64972 3.72406 5.18373 3.72406H12.1259C12.6599 3.72406 13.1939 3.19205 13.1939 2.66004C13.1939 2.12803 12.6599 1.59602 12.1259 1.59602H5.18373V1.59602Z" :fill="color"/>
</svg>
</template>

<script>
export default {
  props: ["color"],
};
</script>