import { Promise } from "core-js";
const get = (url, headers) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers,
    })
      .then(res => {
        resolve(res.json());
      })
      .catch(err => {
        reject(err);
      });
  });
};
const post = (url, headers, body) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers,
      body: JSON.stringify(body),
    })
      .then(res => {
        resolve(res.json());
      })
      .catch(err => {
        reject(err);
      });
  });
};
const del = (url, headers, body) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers,
      body: JSON.stringify(body),
    })
      .then(res => {
        resolve(res.json());
      })
      .catch(err => {
        reject(err);
      });
  });
};
const put = (url, headers, body) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers,
      body: JSON.stringify(body),
    })
      .then(res => {
        resolve(res.json());
      })
      .catch(err => {
        reject(err);
      });
  });
};
const API = { get, post, put, del };
export default API;
