<template>
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9672 9.28242L11.9146 0.364811C11.4282 -0.121604 10.7796 -0.121604 10.2932 0.364811L0.240616 9.28242C-0.245799 9.6067 0.0784792 10.4174 0.564894 10.4174H3.15911V20.1457C3.15911 20.9564 3.80766 21.6049 4.61835 21.6049H7.86112C8.34753 21.6049 8.67181 21.2807 8.67181 20.7942V15.9301C8.67181 15.4437 8.99609 15.1194 9.4825 15.1194H12.5631C13.0495 15.1194 13.3738 15.4437 13.3738 15.9301V20.7942C13.3738 21.2807 13.6981 21.6049 14.1845 21.6049H17.4273C18.238 21.6049 19.0487 20.9564 19.0487 19.9836V10.2552H21.6429C22.1293 10.4174 22.4536 9.76884 21.9672 9.28242Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
