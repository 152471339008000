<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 44 44"
  >
    <path
      :fill="color"
      fill-rule="evenodd"
      d="M14.433 33.442a3 3 0 1 0 1.96-.416a8.972 8.972 0 0 1-.103-.405a19.627 19.627 0 0 1-.32-1.87a17.026 17.026 0 0 1-.14-1.914a6.55 6.55 0 0 1 .015-.527c.384-.11.77-.21 1.155-.297c.441-.1.703.42.914.842l.086.169h11.749c.229-.434.748-1.126 1.251-1.011c.536.122 1.075.267 1.609.433l-.003.001c-.002-.002-.002-.002 0 .002c.004.014.026.08.048.22c.025.162.042.372.05.625c.014.504-.015 1.117-.074 1.735c-.06.617-.149 1.214-.249 1.685c-.022.105-.044.2-.066.286H31a1 1 0 0 0-.894.553l-1 2A.999.999 0 0 0 29 36v2a1 1 0 0 0 1 1h2v-2h-1v-.764L31.618 35h2.764L35 36.236V37h-1v2h2a1 1 0 0 0 1-1v-2a.999.999 0 0 0-.106-.447l-1-2A1 1 0 0 0 35 33h-.636c.107-.533.196-1.155.256-1.779c.066-.674.1-1.373.083-1.983l-.001-.028C38.69 30.895 42 33.666 42 36.57V42H6v-5.43c0-3.032 3.61-5.92 7.831-7.577c.011.622.07 1.325.155 2.006c.092.735.217 1.466.355 2.068c.03.129.06.254.092.375ZM16 37.015c.538 0 1-.44 1-1.015c0-.574-.462-1.015-1-1.015s-1 .44-1 1.015c0 .574.462 1.015 1 1.015ZM24 24a8 8 0 1 0 0-16a8 8 0 0 0 0 16Zm0 2c5.523 0 10-4.477 10-10S29.523 6 24 6s-10 4.477-10 10s4.477 10 10 10Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
