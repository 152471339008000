<template>
  <div class="error-page">
    <v-container>
      <page-not-found class="mx-auto mt-5 mt-md-15" />
    </v-container>
  </div>
</template>

<script>
import PageNotFound from "@/components/v3/elements/page_not_found.vue";

export default {
  components: {
    PageNotFound,
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/custom.scss";
.error-page {
  height: 100vh;
  background: $main_1;
  text-align: center;

}
</style>
