<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="96.000000pt" height="17pt" viewBox="0 0 96.000000 96.000000"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
:fill="color" stroke="none">
<path d="M342 861 c-29 -11 -75 -35 -101 -54 l-48 -35 -37 36 -36 37 0 -103 0
-102 102 0 102 0 -33 34 -34 35 49 31 c65 41 115 53 200 48 147 -10 264 -118
281 -259 l6 -49 45 0 45 0 -6 61 c-11 121 -102 246 -222 304 -52 26 -73 30
-160 33 -80 2 -111 -2 -153 -17z"/>
<path d="M460 712 c0 -20 -7 -30 -28 -40 -36 -15 -57 -53 -57 -102 0 -54 20
-84 76 -115 32 -17 51 -35 55 -51 8 -33 -1 -54 -26 -54 -15 0 -22 8 -26 30 -6
28 -9 30 -51 30 l-45 0 6 -32 c9 -45 33 -79 67 -91 22 -9 29 -18 29 -39 0 -23
4 -28 25 -28 21 0 25 5 25 28 0 21 8 30 33 42 45 21 66 81 48 139 -9 33 -23
48 -69 79 -48 32 -57 42 -57 67 0 43 33 48 41 6 6 -29 9 -31 51 -31 l45 0 -7
38 c-7 38 -49 92 -72 92 -8 0 -13 12 -13 30 0 25 -4 30 -25 30 -21 0 -25 -5
-25 -28z"/>
<path d="M83 419 c11 -121 103 -247 222 -304 47 -22 79 -29 144 -33 111 -6
177 10 256 62 l63 43 36 -36 36 -36 0 103 0 102 -102 0 -102 0 33 -34 32 -33
-47 -32 c-63 -42 -114 -54 -200 -49 -147 10 -264 118 -281 259 l-6 49 -45 0
-45 0 6 -61z"/>
</g>
</svg>
</template>

<script>
export default {
  props: ["color"],
};
</script>