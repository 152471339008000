<template>
  <div class="main-layout">
    <div class="px-5 content-set">
      <div class="card--payment">
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <img :src="require('../assets/circle-check.png')" height="170" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <strong class="subheading">{{ $t("success_logout") }} ✨</strong>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <p class="content text-center">
              {{ $t("page.logout.auto_redirect_login") }} {{ countdown }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <!-- <localized-link to="/login"> -->
            <strong
              class="to-home d-flex align-center justify-center cursor-pointer"
              @click="$router.push(`/${$i18n.locale}/login?redirect=/`)"
            >
              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
                />
              </svg>
              {{ $t("page.login.login_now") }}</strong
            >
            <!-- </localized-link> -->
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "../service/auth_service";
export default {
  data: () => ({
    countdown: 3,
  }),
  mounted() {
    Auth.signOut();
    setInterval(() => {
      this.countdown--;
      if (this.countdown == 0) {
        this.$router.push(`/${this.$i18n.locale}/login`);
      }
    }, 1000);
  },
};
</script>

<style>
.to-home {
  cursor: pointer;
}
</style>
