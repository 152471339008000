<template>
  <svg
    width="30"
    height="21"
    viewBox="0 0 30 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.7058 9.9779H17.5348V0H5.90457V20.2459H0V21H30V20.2459H25.6461V9.9779H25.7058ZM16.7594 19.9558H6.62028V0.812155H16.7594V19.9558ZM24.8708 19.9558H17.6541V10.732H24.8708V19.9558Z"
      :stroke="color"
      stroke-width="0.7"
    />
    <path
      d="M11.153 2.84253H8.64807V4.93093H11.153V2.84253ZM10.7952 4.58286H9.06557V3.24861H10.7952V4.58286Z"
      :stroke="color"
      stroke-width="0.7"
    />
    <path
      d="M14.7317 2.84253H12.2267V4.93093H14.7317V2.84253ZM14.3738 4.58286H12.6442V3.24861H14.3738V4.58286Z"
      :stroke="color"
      stroke-width="0.7"
    />
    <path
      d="M14.7317 5.56909H12.2267V7.65749H14.7317V5.56909ZM14.3738 7.30942H12.6442V5.97517H14.3738V7.30942Z"
      :stroke="color"
      stroke-width="0.7"
    />
    <path
      d="M14.7317 10.384H12.2267V12.4724H14.7317V10.384ZM14.3738 12.0664H12.6442V10.7321H14.3738V12.0664Z"
      :stroke="color"
      stroke-width="0.7"
    />
    <path
      d="M14.7317 15.1409H12.2267V17.2293H14.7317V15.1409ZM14.3738 16.8232H12.6442V15.4889H14.3738V16.8232Z"
      :stroke="color"
      stroke-width="0.7"
    />
    <path
      d="M11.153 15.1409H8.64807V17.2293H11.153V15.1409ZM10.7952 16.8232H9.06557V15.4889H10.7952V16.8232Z"
      :stroke="color"
      stroke-width="0.7"
    />
    <path
      d="M21.1134 11.5442H18.6084V13.6326H21.1134V11.5442ZM20.7555 13.2265H19.0259V11.8923H20.7555V13.2265Z"
      :stroke="color"
      stroke-width="0.7"
    />
    <path
      d="M24.2744 14.9088H21.7694V16.9972H24.2744V14.9088ZM23.8569 16.5911H22.1273V15.2569H23.8569V16.5911Z"
      :stroke="color"
      stroke-width="0.7"
    />
  </svg>
</template>

<script>
export default {
  props: ["color"],
};
</script>
