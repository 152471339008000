<template>
  <div class="error-page">
    <v-container>
      <v-row>
        <v-col cols="12" md="4" offset-md="4">
          <h1>{{ $t("error_403_title") }}</h1>
          <p>{{ $t("error_403") }}</p>
           <localized-link to="/">
              <strong class="to-home d-flex align-center justify-center">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
                  />
                </svg>
                {{ $t("back_to_home") }}</strong
              >
            </localized-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
  @import '../assets/css/custom.scss';
  .error-page{
    height: 100vh;
    background:white;
    text-align: center;
    h1{
      font-size: 3rem;
      margin-top: 20%;
    }
    a{
      text-decoration: none;
      color: $blue_2;
    }
  }
</style>
