<template>
  <svg
    width="15"
    height="17"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.69407 2.4519L7.65789 2.55H7.55333H2.44444C1.18024 2.55 0.15 3.56543 0.15 4.8V21.6C0.15 22.8346 1.18024 23.85 2.44444 23.85H19.5556C20.8198 23.85 21.85 22.8346 21.85 21.6V4.8C21.85 3.56543 20.8198 2.55 19.5556 2.55H14.4467H14.3421L14.3059 2.4519C13.8135 1.11665 12.5238 0.15 11 0.15C9.47625 0.15 8.18647 1.11665 7.69407 2.4519ZM8.55556 19.35H8.40556V19.2V16.8V16.65H8.55556H17.1111H17.2611V16.8V19.2V19.35H17.1111H8.55556ZM4.88889 14.55H4.73889V14.4V12V11.85H4.88889H17.1111H17.2611V12V14.4V14.55H17.1111H4.88889ZM4.88889 9.75H4.73889V9.6V7.2V7.05H4.88889H17.1111H17.2611V7.2V9.6V9.75H17.1111H4.88889ZM9.62778 3.6C9.62778 2.85457 10.2475 2.25 11 2.25C11.7525 2.25 12.3722 2.85457 12.3722 3.6C12.3722 4.34543 11.7525 4.95 11 4.95C10.2475 4.95 9.62778 4.34543 9.62778 3.6Z"
      :stroke="color"
      stroke-width="1.25"
    />
  </svg>
</template>

<script>
export default {
  props: ["color"],
};
</script>
