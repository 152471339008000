<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99992 8.33334C4.08325 8.33334 3.33325 9.08334 3.33325 10C3.33325 10.9167 4.08325 11.6667 4.99992 11.6667C5.91659 11.6667 6.66659 10.9167 6.66659 10C6.66659 9.08334 5.91659 8.33334 4.99992 8.33334ZM14.9999 8.33334C14.0833 8.33334 13.3333 9.08334 13.3333 10C13.3333 10.9167 14.0833 11.6667 14.9999 11.6667C15.9166 11.6667 16.6666 10.9167 16.6666 10C16.6666 9.08334 15.9166 8.33334 14.9999 8.33334ZM9.99992 8.33334C9.08325 8.33334 8.33325 9.08334 8.33325 10C8.33325 10.9167 9.08325 11.6667 9.99992 11.6667C10.9166 11.6667 11.6666 10.9167 11.6666 10C11.6666 9.08334 10.9166 8.33334 9.99992 8.33334Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
