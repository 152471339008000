<template>

  <ul v-click-outside="closeMenu">
    <li v-if="$store.state.typeUser == 1">
      <IconQr/>
      <v-dialog v-model="dialog" width="500" class="bg-blue">
          <template v-slot:activator="{ on, attrs }">
            <!-- <v-btn
              class="btn-package"
              dark
              v-bind="attrs"
              v-on="on"
            > -->
              <p
              v-bind="attrs"
              v-on="on"
              >
                {{$t('menu.myqr')}}
              </p>
            <!-- </v-btn> -->
          </template>

          <v-card>
            <v-container>
              <v-row>
                <v-col cols="9"></v-col>
                <v-col
                  cols="3"
                  class="d-flex justify-end"
                >
                  <v-btn @click="dialog = false" class="btn-cancel">
                    <IconCancel />
                  </v-btn>
                </v-col>
                <!-- <v-col></v-col> -->
              </v-row>

            </v-container>
            <v-card-text>
              <v-col
                cols="12"
                class="d-flex align-center justify-center"
              >
                <qrcode-vue
                  :value="profile.person.patient.medical_record_number"
                  :size="sizes"
                  level="H"
                ></qrcode-vue>
              </v-col>

            </v-card-text>
          </v-card>
        </v-dialog>
      
    </li>

    <li v-if="$store.state.typeUser == 1">
      <icon-user/>
      <p>
        <router-link to="/dashboard/profile-user">
          {{$t('menu.profile')}}
        </router-link>
      </p>
    </li>
    <li v-if="$store.state.typeUser != 1">
      <icon-user/>
      <p>
        <router-link to="/dashboard">
          {{$t('menu.profile')}}
        </router-link>
      </p>
    </li>
    <li v-if="$store.state.typeUser == 1">
      <v-icon class="icon-family">mdi-account-multiple-outline</v-icon>
      <p>
        <router-link to="/dashboard/family">
          {{$t('menu.family')}}
        </router-link>
      </p>
    </li>
    <li v-if="$store.state.typeUser == 1">
      <IconCart/>
      <p>
        <router-link to="/dashboard/cart">
          {{$t('menu.cart')}}
        </router-link>
      </p>
    </li>
    <li v-if="$store.state.typeUser == 1">
      <icon-package/>
      <p>
        <router-link to="/dashboard/my-package">
          {{$t('menu.my_package')}}
        </router-link>
      </p>
    </li>
    <li>
      <IconGlobe/>
      <p @click.stop="toggleSwithLang()">
        {{$t('lang')}}
        <span v-if="$i18n.locale == 'id'"><img class="flag" src="https://www.biznetnetworks.com/site/themes/biznet-tejarat/src/img/id-32.png" /></span>
        <span v-else><img class="flag" src="https://www.biznetnetworks.com/site/themes/biznet-tejarat/src/img/en-32.png" /></span>
          <ul class="switch--locale" :class="{'d-none':!switchLang}">
            <li :class="{active:$i18n.locale=='en'}" @click.stop="changeLang('en')"><img src="https://www.biznetnetworks.com/site/themes/biznet-tejarat/src/img/en-32.png" /></li>
            <li :class="{active:$i18n.locale=='id'}" @click.stop="changeLang('id')"><img src="https://www.biznetnetworks.com/site/themes/biznet-tejarat/src/img/id-32.png" /></li>
          </ul>
      </p>
    </li>
    <li>
      <icon-question/>
      <p>
        <a :href="`${url_virtu}frequently-asked-questions/`" target="_blank">
          {{$t('menu.faq')}}
        </a>
      </p>
    </li>
    <li>
      <icon-question-mark/>
      <p>
        <a :href="`${url_virtu}syarat-ketentuan/`" target="_blank">
          {{$t('menu.tnc')}}
        </a>
      </p>
    </li>
    <li>
      <icon-log-out/>
      <p @click.stop="logout">
        {{$t('logout')}}
      </p>
    </li>
  </ul>
</template>

<script>
import IconQr from "../elements/IconQr.vue";
import IconLogOut from "../elements/IconLogOut.vue";
import Auth from '../../service/auth_service';
import IconUser from '../elements/IconUser.vue';
import IconQuestion from '../elements/IconQuestion.vue';
import IconGlobe from '../elements/IconGlobe';
import IconCart from '../elements/IconCart.vue'
import IconQuestionMark from '../elements/IconQuestionMark.vue';
import IconPackage from '../elements/IconPackage.vue';
import API from "../../service/api_service";
import QrcodeVue from "qrcode.vue";
import IconCancel from "../elements/IconCancel.vue"
import localStorageService from '../../service/localStorageService';

export default {
  components: {
    QrcodeVue, 
    IconCancel,
    IconQr,
    IconLogOut,
    IconUser,
    IconQuestion,
    IconQuestionMark,
    IconGlobe,
    IconCart,
    IconPackage
  },
  data:()=>({
        dialog: false,
        switchLang:false,
        url_virtu:process.env.VUE_APP_URL_VIRTU,
        sizes: 300,
        profile: { person: {patient:{}} },
  }),
  mounted() {
   API.get(`${process.env.VUE_APP_API_TRUST}profile`, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then(res => {
      this.profile = res;
    });
  },
  methods: {
    changeLang(param){
      this.$i18n.locale = param
      localStorageService.setItem('locale', param)
      this.toggleSwithLang()
    },
    toggleSwithLang(){
      
      this.switchLang = !this.switchLang
    },
    logout(){
      Auth.signOut();
      this.$router.push('/login');
      this.$store.commit('closeMenu')
    },
    closeMenu() {
      this.$store.commit("toggleMenuActive", "");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/custom.scss";
.flag{
  box-shadow: 0px 0px 15px #9a9a9a;
  min-height: 17px !important;
  }
.icon-family{
  color: #1a428ade;
  width: 16px;
  font-size: 22px;
  justify-content: flex-start;
  align-items: flex-start;
}
ul {
  cursor: default;
  padding: 0 !important;
  list-style: none;
  li {
    padding: 18px 0 0 18px;
    display: flex;
    position: relative;
    p {
      cursor: pointer;
      margin-left: 20px;
      line-height: 16px;
      border-bottom: 1px solid $gray_3;
      margin-bottom: 0;
      padding-bottom: 18px;
      width: 100%;
      color: $blue_1;
      padding-right: 18px;
      display: flex;
      justify-content: space-between;
      .badge {
        display: inline-block;
        width: 24px;
        height: 24px;
        text-align: center;
        color: white;
        border-radius: 50%;
        background: $red_2;
      }
    }
    a {
      text-decoration: none;
      color: $blue_1;
    }
    &:nth-last-child(1) {
      p {
        border: none;
        color: $red_1;
      }
    }
  }
}
.switch--locale{
  position: absolute;
  right: 12px;
  top: 26px;
  list-style: none;
  padding: 0;
  background: white;
  overflow: hidden;
  border-radius: 10px;
  z-index: 2;
  box-shadow: 0 0 8px $gray_2;
  li{
    cursor: pointer;
    padding: 6px !important;
    border-bottom: 1px solid $gray_2;
    text-transform: uppercase;
    &.active{
      color: $red_1;
    }
  }
}
</style>
