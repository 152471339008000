<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    :stroke="color"
    stroke-width="2"
    style="width: 20px;height: 20px;"
  >
    <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
