<template>
  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 0.999998V11H11V0.499999C11 0.224 10.776 0 10.5 0H1.5V0.999998H10Z" fill="#4F4F4F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 2.00002H8.5C8.776 2.00002 9 2.22402 9 2.50002V11.5C9 11.776 8.776 12 8.5 12H0.5C0.224 12 0 11.776 0 11.5V2.50002C0 2.22402 0.224 2.00002 0.5 2.00002ZM2 9.5H5V8.50001H2V9.5ZM2 7.50001H7V6.50001H2V7.50001ZM2 5.50001H7V4.50002H2V5.50001Z" fill="#4F4F4F"/>
</svg>

</template>
<script>
export default {
  props: ["color"],
};
</script>
