<template>
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 10C11.1828 10 13.3571 7.76172 13.3571 5C13.3571 2.23828 11.1828 0 8.5 0C5.81719 0 3.64286 2.23828 3.64286 5C3.64286 7.76172 5.81719 10 8.5 10ZM11.9 11.25H11.2663C10.4239 11.6484 9.48661 11.875 8.5 11.875C7.51339 11.875 6.57991 11.6484 5.73371 11.25H5.1C2.28437 11.25 0 13.6016 0 16.5V18.125C0 19.1602 0.815848 20 1.82143 20H15.1786C16.1842 20 17 19.1602 17 18.125V16.5C17 13.6016 14.7156 11.25 11.9 11.25Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
