<template>
    <svg 
        width="20" 
        height="20" 
        viewBox="0 0 80 80" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <path d="M76 36H60V12H36V36H20L48 64L76 36ZM20 72V80H76V72H20Z" 
        :fill="color"/>
    </svg>

</template>


<script>
export default {
  props: ["color"],
};
</script>
