<template>
  <button
    class="btn"
    :class="{
      'btn--rounded': rounded,
      'btn--bold': bold,
      'btn--shadow': shadow,
      'btn--fluid': fluid,
      'btn--sm--fluid': fluidSm,
      'btn--sm--non--fluid': nonFluidSm,
      'btn--md--fluid': fluidMd,
      'btn--md--non--fluid': nonFluidMd,
      'btn--disabled': disabled,
      'btn--outline': outline,
      'btn--rounded-small': roundedSmall,
      'btn--small': small,
    }"
    @click="$emit('click')"
    :disabled="disabled"
    :type="type"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    rounded: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    fluidSm: {
      type: Boolean,
      default: false,
    },
    nonFluidSm: {
      type: Boolean,
      default: false,
    },
    fluidMd: {
      type: Boolean,
      default: false,
    },
    nonFluidMd: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    outline: {
      type: Boolean,
      default: false,
    },
    roundedSmall: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/color.scss";
button {
  display: inline-block;
  padding: 10px 12px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-decoration: none;
  min-width: 110px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: $main_2;
    color: white;
  }
  &.btn {
    background-color: $main_1;
    color: white;
    &:hover {
      background-color: $main_2;
      color: white;
    }
    &--disabled {
      background-color: $gray_3;
      color: white;
      cursor: not-allowed;
      &:hover {
        background-color: $gray_3;
        color: white;
      }
    }

    &--sm {
      &--fluid {
        @media only screen and (min-width: 600px) {
          width: 100%;
        }
      }
      &--non--fluid {
        @media only screen and (min-width: 600px) {
          width: auto !important;
        }
      }
    }
    &--md {
      &--fluid {
        @media only screen and (min-width: 960px) {
          width: 100%;
        }
      }
      &--non--fluid {
        @media only screen and (min-width: 960px) {
          width: auto !important;
        }
      }
    }
    &--small {
      font-size: 14px;
      line-height: 14px;
      padding: 8px 10px;
    }
    &--bold {
      font-style: normal;
      font-weight: 700;
    }
    &--rounded {
      border-radius: 6px !important;
    }
    &--shadow {
      box-shadow: 0px 4px 4px rgba(14, 53, 191, 0.25);
    }
    &--fluid {
      width: 100%;
    }
    &--outline {
      background-color: transparent;
      border: 1px solid $main_1;
      color: $main_1;
      &:hover {
        background-color: transparent;
        border: 1px solid $main_2;
        color: $main_2;
      }
    }
    &--rounded-small {
      border-radius: 4px !important;
    }
  }
}
</style>
