<template>
    <svg 
        width="20"
        height="17"
        viewBox="0 0 60 60"
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        
        <path 
            d="M32 32C40.84 32 48 24.84 48 16C48 7.16 40.84 0 32 0C23.16 0 16 7.16 16 16C16 24.84 23.16 32 32 32ZM32 40C21.32 40 0 45.36 0 56V64H64V56C64 45.36 42.68 40 32 40Z" 
            :stroke="color"
            :fill="color"
            stroke-width="1.4"
        />
    </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
 
    
