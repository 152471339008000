<template>
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 7L0.803849 0.249999L11.1962 0.25L6 7Z" :fill="color" />
    <defs>
      <linearGradient
        id="paint0_linear_501_387"
        x1="6"
        y1="7"
        x2="6"
        y2="-2"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.348958" :stop-color="color" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: ["color"],
};
</script>
