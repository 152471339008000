<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5714 5.625H0.428571C0.191893 5.625 0 5.43611 0 5.20312V3.9375C0 3.00551 0.7675 2.25 1.71429 2.25H3.42857V0.421875C3.42857 0.188895 3.62046 0 3.85714 0H5.28571C5.52239 0 5.71429 0.188895 5.71429 0.421875V2.25H10.2857V0.421875C10.2857 0.188895 10.4776 0 10.7143 0H12.1429C12.3795 0 12.5714 0.188895 12.5714 0.421875V2.25H14.2857C15.2325 2.25 16 3.00551 16 3.9375V5.20312C16 5.43611 15.8081 5.625 15.5714 5.625ZM0.428571 6.75H15.5714C15.8081 6.75 16 6.93889 16 7.17188V16.3125C16 17.2445 15.2325 18 14.2857 18H1.71429C0.7675 18 0 17.2445 0 16.3125V7.17188C0 6.93889 0.191893 6.75 0.428571 6.75ZM12.332 10.1231L11.326 9.12477C11.1593 8.95936 10.8879 8.95827 10.7199 9.12238L6.93286 12.8203L5.29071 11.1907C5.12404 11.0253 4.85264 11.0242 4.68461 11.1883L3.67043 12.1787C3.50239 12.3427 3.50129 12.6098 3.668 12.7753L6.61804 15.7027C6.78471 15.8681 7.05607 15.8692 7.22411 15.7051L12.3296 10.7197C12.4976 10.5557 12.4987 10.2885 12.332 10.1231V10.1231Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
