<template>
  <div class="main-layout">
    <v-container class="mt-14 containt">
      <div class="d-flex align-center justify-center pb-5">
        <router-link to="/dashboard">
          <img :src="require('../../assets/logoDigiLab.png')" height="50" />
        </router-link>
      </div>
      <div class="d-flex justify-center" v-if="loading">
        <icons icon="loading" />
      </div>
      <template v-if="!openOtp">
        <v-form v-model="valid" lazy-validation ref="form">
          <v-row>
            <v-col cols="12" md="6" offset-md="3">
              <Card class="mt-6">
                <v-row class="mt-2">
                  <v-col cols="4" md="3" class="field-padding">
                    <v-autocomplete
                      outlined
                      dense
                      class="mt-0 uppercase-input"
                      v-model="payload.title"
                      :items="titleName"
                      item-text="name"
                      item-value="id"
                      :rules="[
                        v => !!v || `${$t('field.title')} ${$t('is_required')}`,
                      ]"
                      :label="$t('field.title')"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <!-- </v-row>
                <v-row> -->
                  <v-col cols="8" md="4" class="field-padding">
                    <v-text-field
                      class="mt-0 uppercase-input"
                      v-model="payload.firstname"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!v ||
                          `${$t('field.first_name')} ${$t('is_required')}`,
                      ]"
                      :label="$t('field.first_name')"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="5" class="field-padding">
                    <v-text-field
                      class="mt-0 uppercase-input"
                      v-model="payload.lastname"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!v ||
                          `${$t('field.last_name')} ${$t('is_required')}`,
                      ]"
                      :label="$t('field.last_name')"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="4"
                    md="3"
                    class="column-phone-code field-padding"
                  >
                    <PhoneNumber
                      :items="phoneCodeItems"
                      :value="defaultPhoneCode"
                      class="input-phone"
                      :rules="[
                        v =>
                          !!v ||
                          `${$t('field.phone_code')} ${$t('is_required')}`,
                      ]"
                      @changePhoneCode="selected => update(selected)"
                    />
                  </v-col>
                  <v-col
                    cols="8"
                    md="9"
                    class="column-phone-number field-padding"
                  >
                    <v-text-field
                      outlined
                      required
                      dense
                      v-model="formatedPhoneNumber"
                      :rules="[
                        v =>
                          !!v ||
                          `${$t('field.phone_number')} ${$t('is_required')}`,
                      ]"
                      :label="$t('field.phone_number')"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="column-username field-padding">
                    <v-text-field
                      class="mt-0"
                      outlined
                      dense
                      v-model="payload.username"
                      :rules="[
                        v =>
                          !!v || `${$t('field.username')} ${$t('is_required')}`,
                      ]"
                      :label="$t('field.username')"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="column-email field-padding">
                    <v-text-field
                      class="mt-0 uppercase-input"
                      outlined
                      dense
                      v-model="payload.email"
                      :rules="[emailRules]"
                      label="Email"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="column-password field-padding">
                    <v-text-field
                      class="mt-0"
                      outlined
                      dense
                      v-model="payload.password"
                      :rules="[
                        v =>
                          !!v || `${$t('field.password')} ${$t('is_required')}`,
                      ]"
                      :label="$t('field.password')"
                      :append-icon="
                        show_password ? 'visibility' : 'visibility_off'
                      "
                      :type="show_password ? 'text' : 'password'"
                      @click:append="show_password = !show_password"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="column-confirm-password field-padding"
                  >
                    <v-text-field
                      class="mt-0"
                      outlined
                      dense
                      v-model="payload.passwordConfirm"
                      :rules="[passwordConfirmRules]"
                      :label="$t('field.confirm_password')"
                      :append-icon="
                        show_confirm_password ? 'visibility' : 'visibility_off'
                      "
                      :type="show_confirm_password ? 'text' : 'password'"
                      @click:append="
                        show_confirm_password = !show_confirm_password
                      "
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div class="d-flex align-center justify-space-between pt-3">
                  <p class="already-text">
                    {{ $t("page.register.already_has_account") }}
                    <br />
                    <router-link to="/login">{{
                      $t("page.login.sign_in")
                    }}</router-link>
                  </p>
                  <v-btn
                    :disabled="!valid"
                    class="mr-0 button-register mr-md-4 mr-lg-4"
                    @click="validate"
                    color="white"
                    rounded
                  >
                    {{ $t("next") }}
                    <!-- <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style="margin-left: 8px"
                    >
                      <path
                        d="M5.56238 11.1299C4.907 11.1299 4.37571 11.6612 4.37571 12.3166C4.37571 12.972 4.907 13.5033 5.56238 13.5033V11.1299ZM21.714 13.1557C22.1774 12.6923 22.1774 11.9409 21.714 11.4775L14.1621 3.9256C13.6987 3.46218 12.9473 3.46218 12.4839 3.9256C12.0205 4.38903 12.0205 5.14038 12.4839 5.6038L19.1967 12.3166L12.4839 19.0294C12.0205 19.4928 12.0205 20.2442 12.4839 20.7076C12.9473 21.171 13.6987 21.171 14.1621 20.7076L21.714 13.1557ZM5.56238 13.5033H20.8749V11.1299H5.56238V13.5033Z"
                        fill="white"
                      />
                    </svg> -->
                  </v-btn>
                </div>
              </Card>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-if="!loading && openOtp">
        <v-row>
          <v-col cols="12" md="6" offset-md="3">
            <Card>
              <Otp
                @handleOnComplete="item => handleOnComplete(item)"
                :title="otpName"
                :email="payload.email"
                :timeEnd="distance"
              />
            </Card>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
import Card from "@/components/general/Card.vue";
import Otp from "@/components/general/Otp.vue";
import PhoneNumber from "@/components/v2/input/phonenumber.vue";

import Icons from "@/components/elements/Icons.vue";
import validator from "validator";
import API from "../../service/api_service";
import Auth from "../../service/auth_service";
export default {
  components: { Card, Icons, Otp, PhoneNumber },
  data: () => ({
    valid: true,
    show_password: false,
    show_confirm_password: false,
    loading: false,
    openOtp: false,
    otpName: "",
    payload: {
      title: "",
      token: "",
      username: "",
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      phone: "",
    },
    titleName: [],
    defaultPhoneCode: "+62",
    phoneCodeItems: [
      {
        flag: "af",
        name: "Afghanistan",
        iso: "(af)",
        code: "+93",
      },
      {
        flag: "al",
        name: "Albania",
        iso: "(al)",
        code: "+355",
      },
      {
        flag: "dz",
        name: "Algeria",
        iso: "(dz)",
        code: "+213",
      },
      {
        flag: "as",
        name: "American Samoa",
        iso: "(as)",
        code: "+684",
      },
      {
        flag: "ad",
        name: "Andorra",
        iso: "(ad)",
        code: "+376",
      },
      {
        flag: "ao",
        name: "Angola",
        iso: "(ao)",
        code: "+244",
      },
      {
        flag: "ai",
        name: "Anguilla",
        iso: "(ai)",
        code: "+1-264",
      },
      {
        flag: "aq",
        name: "Antarctica",
        iso: "(aq)",
        code: "+672",
      },
      {
        flag: "ag",
        name: "Antigua and Barbuda",
        iso: "(ag)",
        code: "+1-268",
      },
      {
        flag: "ar",
        name: "Argentina",
        iso: "(ar)",
        code: "+54",
      },
      {
        flag: "am",
        name: "Armenia",
        iso: "(am)",
        code: "+374",
      },
      {
        flag: "aw",
        name: "Aruba",
        iso: "(as)",
        code: "+297",
      },
      {
        flag: "au",
        name: "Australia",
        iso: "(au)",
        code: "+61",
      },
      {
        flag: "at",
        name: "Austria",
        iso: "(at)",
        code: "+43",
      },
      {
        flag: "az",
        name: "Azerbaijan",
        iso: "(az)",
        code: "+994",
      },
      {
        flag: "bs",
        name: "Bahamas",
        iso: "(bs)",
        code: "+1-242",
      },
      {
        flag: "bh",
        name: "Bahrain",
        iso: "(bh)",
        code: "+973",
      },
      {
        flag: "bd",
        name: "Bangladesh",
        iso: "(bd)",
        code: "+880",
      },
      {
        flag: "by",
        name: "Belarus",
        iso: "(by)",
        code: "+1-246",
      },
      {
        flag: "be",
        name: "Belgium",
        iso: "(be)",
        code: "+93",
      },
      {
        flag: "bz",
        name: "Belize",
        iso: "(bz)",
        code: "+93",
      },
      {
        flag: "bj",
        name: "Benin",
        iso: "(bj)",
        code: "+355",
      },
      {
        flag: "bm",
        name: "Bermuda",
        iso: "(bm)",
        code: "+213",
      },
      {
        flag: "bt",
        name: "Bhutan",
        iso: "(bt)",
        code: "+684",
      },
      {
        flag: "bo",
        name: "Bolivia ",
        iso: "(bo)",
        code: "+376",
      },
      {
        flag: "ba",
        name: "Bosnia and Herzegovina",
        iso: "(ba)",
        code: "+244",
      },
      {
        flag: "bw",
        name: "Botswana",
        iso: "(bw)",
        code: "+1-264",
      },
      {
        flag: "br",
        name: "Brazil",
        iso: "(br)",
        code: "+672",
      },
      {
        flag: "vg",
        name: "British Virgin Islands",
        iso: "(vg)",
        code: "+1-268",
      },
      {
        flag: "bn",
        name: "Brunei Darussalam",
        iso: "(bn)",
        code: "+54",
      },

      {
        flag: "bg",
        name: "Bulgaria",
        iso: "(bg)",
        code: "+374",
      },
      {
        flag: "bg",
        name: "Burkina Faso",
        iso: "(bg)",
        code: "+297",
      },
      {
        flag: "bi",
        name: "Burundi",
        iso: "(bi)",
        code: "+257",
      },
      {
        flag: "kh",
        name: "Cambodia",
        iso: "(kh)",
        code: "+855",
      },
      {
        flag: "cm",
        name: "Cameroon",
        iso: "(cm)",
        code: "+237",
      },

      {
        flag: "ca",
        name: "Canada",
        iso: "(ca)",
        code: "+1",
      },
      {
        flag: "cv",
        name: "Cape Verde",
        iso: "(cv)",
        code: "+238",
      },
      {
        flag: "ky",
        name: "Cayman Islands",
        iso: "(ky)",
        code: "+1-345*",
      },
      {
        flag: "cf",
        name: "Central African Republic",
        iso: "(cf)",
        code: "+236",
      },

      ////////////////////////////////////
      {
        flag: "td",
        name: "Chad",
        iso: "(td)",
        code: "+235",
      },
      {
        flag: "cl",
        name: "Chile",
        iso: "(cl)",
        code: "+56",
      },
      {
        flag: "cn",
        name: "China",
        iso: "(cn)",
        code: "+86",
      },
      {
        flag: "hk",
        name: "Hong Kong",
        iso: "(hk)",
        code: "+852",
      },
      {
        flag: "ad",
        name: "Macao",
        iso: "(ad)",
        code: "+853",
      },
      {
        flag: "cx",
        name: "Christmas Island",
        iso: "(cx)",
        code: "+61",
      },
      {
        flag: "co",
        name: "Colombia",
        iso: "(co)",
        code: "+57",
      },
      {
        flag: "km",
        name: "Comoros",
        iso: "(km)",
        code: "+269",
      },
      {
        flag: "cg",
        name: "Congo",
        iso: "(cg)",
        code: "+242",
      },
      {
        flag: "cd",
        name: "Congo",
        iso: "(cd)",
        code: "+243",
      },
      {
        flag: "ck",
        name: "Cook Islands",
        iso: "(ck)",
        code: "+682",
      },
      {
        flag: "cr",
        name: "Costa Rica",
        iso: "(cr)",
        code: "+506",
      },
      {
        flag: "ci",
        name: "Côte d'Ivoire",
        iso: "(ci)",
        code: "+225",
      },
      {
        flag: "hr",
        name: "Croatia",
        iso: "(hr)",
        code: "+385",
      },
      {
        flag: "cu",
        name: "Cuba",
        iso: "(cu)",
        code: "+53",
      },
      {
        flag: "cy",
        name: "Cyprus",
        iso: "(cy)",
        code: "+357",
      },
      {
        flag: "cz",
        name: "Czech Republic",
        iso: "(cz)",
        code: "+420",
      },
      {
        flag: "dk",
        name: "Denmark",
        iso: "(dk)",
        code: "+45",
      },
      {
        flag: "dm",
        name: "Dominica",
        iso: "(dm)",
        code: "+1-767*",
      },
      {
        flag: "do",
        name: "Dominican Republic",
        iso: "(do)",
        code: "+1-809*",
      },
      {
        flag: "ec",
        name: "Ecuador",
        iso: "(ec)",
        code: "+593",
      },
      {
        flag: "eg",
        name: "Egypt",
        iso: "(eg)",
        code: "+20",
      },
      {
        flag: "sv",
        name: "El Salvador",
        iso: "(sv)",
        code: "+503",
      },
      {
        flag: "gq",
        name: "Equatorial Guinea",
        iso: "(gq)",
        code: "+240",
      },
      {
        flag: "er",
        name: "Eritrea ",
        iso: "(er)",
        code: "+291",
      },
      {
        flag: "ee",
        name: "Estonia",
        iso: "(ee)",
        code: "+372",
      },
      {
        flag: "et",
        name: "Ethiopia",
        iso: "(et)",
        code: "+251",
      },
      {
        flag: "fk",
        name: "Falkland Islands",
        iso: "(fk)",
        code: "+500",
      },
      {
        flag: "fo",
        name: "Faroe Islands",
        iso: "(fo)",
        code: "+298",
      },
      {
        flag: "fj",
        name: "Fiji Islands",
        iso: "(fj)",
        code: "+679",
      },

      {
        flag: "fi",
        name: "Finland",
        iso: "(fi)",
        code: "+358",
      },
      {
        flag: "fr",
        name: "France",
        iso: "(fr)",
        code: "+33",
      },
      {
        flag: "gf",
        name: "French Guiana",
        iso: "(gf)",
        code: "+594",
      },
      {
        flag: "pf",
        name: "French Polynesia",
        iso: "(pf)",
        code: "+689",
      },
      {
        flag: "ga",
        name: "Gabonese Republic",
        iso: "(ga)",
        code: "+241",
      },

      {
        flag: "gm",
        name: "Gambia",
        iso: "(gm)",
        code: "+220",
      },
      {
        flag: "ge",
        name: "Georgia",
        iso: "(ge)",
        code: "+995",
      },
      {
        flag: "de",
        name: "Germany",
        iso: "(de)",
        code: "+49",
      },
      {
        flag: "gh",
        name: "Ghana",
        iso: "(gh)",
        code: "+233",
      },
      //////////////////////
      {
        flag: "gi",
        name: "Gibraltar",
        iso: "(gi)",
        code: "+350",
      },
      {
        flag: "gr",
        name: "Greece",
        iso: "(gr)",
        code: "+30",
      },
      {
        flag: "gl",
        name: "Greenland",
        iso: "(gl)",
        code: "+299",
      },
      {
        flag: "gd",
        name: "Grenada",
        iso: "(gd)",
        code: "+1-473",
      },
      {
        flag: "gp",
        name: "Guadeloupe",
        iso: "(gp)",
        code: "+590",
      },
      {
        flag: "gu",
        name: "Guam",
        iso: "(gu)",
        code: "+1-671*",
      },
      {
        flag: "gt",
        name: "Guatemala",
        iso: "(gt)",
        code: "+502",
      },
      {
        flag: "gn",
        name: "Guinea",
        iso: "(gn)",
        code: "+224",
      },
      {
        flag: "gw",
        name: "Guinea-Bissau",
        iso: "(gw)",
        code: "+245",
      },
      {
        flag: "gy",
        name: "Guyana",
        iso: "(gy)",
        code: "+592",
      },
      {
        flag: "ht",
        name: "Haiti",
        iso: "(ht)",
        code: "+509",
      },
      {
        flag: "hn",
        name: "Honduras",
        iso: "(hn)",
        code: "+504",
      },
      {
        flag: "hu",
        name: "Hungary",
        iso: "(hu)",
        code: "+36",
      },
      {
        flag: "is",
        name: "Iceland",
        iso: "(is)",
        code: "+354",
      },
      {
        flag: "in",
        name: "India",
        iso: "(in)",
        code: "+91",
      },
      {
        flag: "id",
        name: "Indonesia",
        iso: "(id)",
        code: "+62",
      },
      {
        flag: "ir",
        name: "Iran",
        iso: "(ir)",
        code: "+98",
      },
      {
        flag: "iq",
        name: "Iraq",
        iso: "(iq)",
        code: "+964",
      },
      {
        flag: "ie",
        name: "Ireland",
        iso: "(ie)",
        code: "+353",
      },
      {
        flag: "il",
        name: "Israel",
        iso: "(il)",
        code: "+972",
      },
      {
        flag: "it",
        name: "Italy",
        iso: "(it)",
        code: "+39",
      },
      {
        flag: "jm",
        name: "Jamaica",
        iso: "(jm)",
        code: "+1-876*",
      },
      {
        flag: "jp",
        name: "Japan",
        iso: "(jp)",
        code: "+81",
      },
      {
        flag: "jo",
        name: "Jordan",
        iso: "(jo)",
        code: "+962",
      },
      {
        flag: "kz",
        name: "Kazakhstan ",
        iso: "(kz)",
        code: "+7",
      },
      {
        flag: "ke",
        name: "Kenya",
        iso: "(ke)",
        code: "+254",
      },
      {
        flag: "ki",
        name: "Kiribati",
        iso: "(ki)",
        code: "+686",
      },
      {
        flag: "kp",
        name: "North Korea",
        iso: "(kp)",
        code: "+850",
      },
      {
        flag: "kr",
        name: "South Korea",
        iso: "(kr)",
        code: "+82",
      },
      {
        flag: "kw",
        name: "Kuwait",
        iso: "(kw)",
        code: "+965",
      },
      {
        flag: "kg",
        name: "Kyrgyzstan",
        iso: "(kg)",
        code: "+996",
      },

      {
        flag: "la",
        name: "Laos",
        iso: "(la)",
        code: "+856",
      },
      {
        flag: "lv",
        name: "Latvia",
        iso: "(lv)",
        code: "+371",
      },
      {
        flag: "lb",
        name: "Lebanon",
        iso: "(lb)",
        code: "+961",
      },
      {
        flag: "ls",
        name: "Lesotho",
        iso: "(ls)",
        code: "+266",
      },
      {
        flag: "lr",
        name: "Liberia",
        iso: "(lr)",
        code: "+231",
      },

      {
        flag: "ly",
        name: "Libya",
        iso: "(ly)",
        code: "+218",
      },
      {
        flag: "li",
        name: "Liechtenstein",
        iso: "(li)",
        code: "+423",
      },
      {
        flag: "lt",
        name: "Lithuania",
        iso: "(lt)",
        code: "+370",
      },
      {
        flag: "lu",
        name: "Luxembourg",
        iso: "(lu)",
        code: "+352",
      },
      ////////////
      {
        flag: "mk",
        name: "North Macedonia",
        iso: "(mk)",
        code: "+389",
      },
      {
        flag: "mg",
        name: "Madagascar",
        iso: "(mg)",
        code: "+261",
      },
      {
        flag: "mw",
        name: "Malawi",
        iso: "(mw)",
        code: "+265",
      },
      {
        flag: "my",
        name: "Malaysia",
        iso: "(my)",
        code: "+60",
      },
      {
        flag: "mv",
        name: "Maldives",
        iso: "(mv)",
        code: "+960",
      },
      {
        flag: "ml",
        name: "Mali Republic",
        iso: "(ml)",
        code: "+223",
      },
      {
        flag: "mt",
        name: "Malta",
        iso: "(mt)",
        code: "+356",
      },
      {
        flag: "mh",
        name: "Marshall Islands",
        iso: "(mh)",
        code: "+692",
      },
      {
        flag: "mq",
        name: "Martinique",
        iso: "(mq)",
        code: "+596",
      },
      {
        flag: "mr",
        name: "Mauritania",
        iso: "(mr)",
        code: "+222",
      },
      {
        flag: "mu",
        name: "Mauritius",
        iso: "(mu)",
        code: "+230",
      },
      {
        flag: "yt",
        name: "Mayotte",
        iso: "(yt)",
        code: "+269",
      },
      {
        flag: "mx",
        name: "Mexico",
        iso: "(mx)",
        code: "+52",
      },
      {
        flag: "fm",
        name: "Micronesia",
        iso: "(fm)",
        code: "+691",
      },
      {
        flag: "md",
        name: "Moldova",
        iso: "(md)",
        code: "+373",
      },
      {
        flag: "mc",
        name: "Monaco",
        iso: "(mc)",
        code: "+377",
      },
      {
        flag: "mn",
        name: "Mongolia",
        iso: "(mn)",
        code: "+976",
      },
      {
        flag: "me",
        name: "Montserrat",
        iso: "(me)",
        code: "+1-664*",
      },
      {
        flag: "ma",
        name: "Morocco",
        iso: "(ma)",
        code: "+212",
      },
      {
        flag: "mz",
        name: "Mozambique",
        iso: "(mz)",
        code: "+258",
      },
      {
        flag: "mm",
        name: "Myanmar",
        iso: "(mm)",
        code: "+95",
      },
      {
        flag: "na",
        name: "Namibia",
        iso: "(na)",
        code: "+264",
      },
      {
        flag: "nr",
        name: "Nauru",
        iso: "(nr)",
        code: "+674",
      },
      {
        flag: "np",
        name: "Nepal",
        iso: "(np)",
        code: "+977",
      },
      {
        flag: "nl",
        name: "Netherlands",
        iso: "(nl)",
        code: "+31",
      },
      {
        flag: "nc",
        name: "New Caledonia",
        iso: "(nc)",
        code: "+687",
      },
      {
        flag: "nz",
        name: "New Zealand",
        iso: "(nz)",
        code: "+64",
      },
      {
        flag: "ni",
        name: "Nicaragua",
        iso: "(ni)",
        code: "+505",
      },
      {
        flag: "ne",
        name: "Niger",
        iso: "(ne)",
        code: "+227",
      },
      {
        flag: "ng",
        name: "Nigeria",
        iso: "(ng)",
        code: "+234",
      },
      {
        flag: "nu",
        name: "Niue",
        iso: "(nu)",
        code: "+683",
      },

      {
        flag: "nf",
        name: "Norfolk Island",
        iso: "(nf)",
        code: "+672",
      },
      {
        flag: "mp",
        name: "Northern Mariana Islands",
        iso: "(mp)",
        code: "+1-670*",
      },
      {
        flag: "no",
        name: "Norway",
        iso: "(no)",
        code: "+47",
      },
      {
        flag: "om",
        name: "Oman",
        iso: "(om)",
        code: "+968",
      },
      {
        flag: "pk",
        name: "Pakistan",
        iso: "(pk)",
        code: "+92",
      },

      {
        flag: "pw",
        name: "Palau",
        iso: "(pw)",
        code: "+680",
      },
      {
        flag: "pa",
        name: "Panama",
        iso: "(pa)",
        code: "+507",
      },
      {
        flag: "pg",
        name: "Papua New Guinea",
        iso: "(pg)",
        code: "+675",
      },
      {
        flag: "py",
        name: "Paraguay",
        iso: "(py)",
        code: "+595",
      },
      ////////////
      {
        flag: "pe",
        name: "Peru",
        iso: "(pe)",
        code: "+51",
      },
      {
        flag: "ph",
        name: "Philippines",
        iso: "(ph)",
        code: "+63",
      },
      {
        flag: "pl",
        name: "Poland",
        iso: "(pl)",
        code: "+48",
      },
      {
        flag: "pt",
        name: "Portugal",
        iso: "(pt)",
        code: "+351",
      },
      {
        flag: "pr",
        name: "Puerto Rico",
        iso: "(pr)",
        code: "+1-787",
      },
      {
        flag: "qa",
        name: "Qatar",
        iso: "(qa)",
        code: "+974",
      },
      {
        flag: "re",
        name: "Réunion",
        iso: "(re)",
        code: "+262",
      },
      {
        flag: "ro",
        name: "Romania",
        iso: "(ro)",
        code: "+40",
      },
      {
        flag: "ru",
        name: "Russian Federation",
        iso: "(ru)",
        code: "+7",
      },
      {
        flag: "rw",
        name: "Rwanda",
        iso: "(rw)",
        code: "+250",
      },
      {
        flag: "sh",
        name: "St. Helena",
        iso: "(sh)",
        code: "+290",
      },
      {
        flag: "kn",
        name: "St. Kitts/Nevis",
        iso: "(kn)",
        code: "+1-869*",
      },
      {
        flag: "lc",
        name: "St Lucia",
        iso: "(lc)",
        code: "+1-758*",
      },
      {
        flag: "pm",
        name: "St. Pierre & Miquelon",
        iso: "(pm)",
        code: "+508",
      },
      {
        flag: "vc",
        name: "St. Vincent & Grenadines",
        iso: "(vc)",
        code: "+1-784*",
      },
      {
        flag: "sm",
        name: "San Marino",
        iso: "(sm)",
        code: "+378",
      },
      {
        flag: "st",
        name: "Sao Tomo and Principe",
        iso: "(st)",
        code: "+239",
      },
      {
        flag: "sa",
        name: "Saudi Arabia",
        iso: "(sa)",
        code: "+966",
      },
      {
        flag: "sn",
        name: "Senegal",
        iso: "(sn)",
        code: "+221",
      },
      {
        flag: "rs",
        name: "Serbia & Montenegro",
        iso: "(rs)",
        code: "+381",
      },
      {
        flag: "sc",
        name: "Seychelles Republic",
        iso: "(sc)",
        code: "+248",
      },
      {
        flag: "sl",
        name: "Sierra Leone",
        iso: "(sl)",
        code: "+232",
      },
      {
        flag: "sg",
        name: "Singapore",
        iso: "(sg)",
        code: "+65",
      },
      {
        flag: "sk",
        name: "Slovak Republic",
        iso: "(sk)",
        code: "+421",
      },
      {
        flag: "si",
        name: "Slovenia",
        iso: "(si)",
        code: "+386",
      },
      {
        flag: "sb",
        name: "Solomon Islands",
        iso: "(sb)",
        code: "+677",
      },
      {
        flag: "so",
        name: "Somalia",
        iso: "(so)",
        code: "+252",
      },
      {
        flag: "za",
        name: "South Africa",
        iso: "(za)",
        code: "+27",
      },
      {
        flag: "es",
        name: "Spain",
        iso: "(es)",
        code: "+34",
      },
      {
        flag: "lk",
        name: "Sri Lanka",
        iso: "(lk)",
        code: "+94",
      },
      {
        flag: "sd",
        name: "Sudan",
        iso: "(sd)",
        code: "+249",
      },
      {
        flag: "sr",
        name: "Suriname",
        iso: "(sr)",
        code: "+597",
      },
      {
        flag: "se",
        name: "Sweden",
        iso: "(se)",
        code: "+46",
      },
      {
        flag: "ch",
        name: "Switzerland",
        iso: "(ch)",
        code: "+41",
      },
      {
        flag: "sy",
        name: "Syrian Arab Republic",
        iso: "(sy)",
        code: "+963",
      },
      {
        flag: "tw",
        name: "Taiwan",
        iso: "(tw)",
        code: "+886",
      },

      {
        flag: "tj",
        name: "Tajikistan",
        iso: "(tj)",
        code: "+992",
      },
      {
        flag: "tz",
        name: "Tanzania",
        iso: "(tz)",
        code: "+255",
      },
      {
        flag: "th",
        name: "Thailand",
        iso: "(th)",
        code: "+66",
      },
      {
        flag: "tg",
        name: "Togolese Republic",
        iso: "(tg)",
        code: "+228",
      },
      ////////////
      {
        flag: "tk",
        name: "Tokelau",
        iso: "(tk)",
        code: "+690",
      },
      {
        flag: "to",
        name: "Tonga",
        iso: "(to)",
        code: "+676",
      },
      {
        flag: "tt",
        name: "Trinidad and Tobago",
        iso: "(tt)",
        code: "+1-868",
      },
      {
        flag: "tn",
        name: "Tunisia",
        iso: "(tn)",
        code: "+216",
      },
      {
        flag: "tr",
        name: "Turkey",
        iso: "(tr)",
        code: "+90",
      },
      {
        flag: "tm",
        name: "Turkmenistan",
        iso: "(tm)",
        code: "+993",
      },
      {
        flag: "tc",
        name: "Turks and Caicos Islands",
        iso: "(tc)",
        code: "+1~649",
      },
      {
        flag: "tv",
        name: "Tuvalu",
        iso: "(tv)",
        code: "+688",
      },
      {
        flag: "ug",
        name: "Uganda",
        iso: "(ug)",
        code: "+256",
      },
      {
        flag: "ua",
        name: "Ukraine",
        iso: "(ua)",
        code: "+380",
      },
      {
        flag: "ae",
        name: "United Arab Emirates",
        iso: "(ae)",
        code: "+971",
      },
      {
        flag: "gb",
        name: "United Kingdom",
        iso: "(gb)",
        code: "+44",
      },
      {
        flag: "us",
        name: "United States of America",
        iso: "(us)",
        code: "+1",
      },
      {
        flag: "uy",
        name: "Uruguay",
        iso: "(uy)",
        code: "+598",
      },
      {
        flag: "uz",
        name: "Uzbekistan",
        iso: "(uz)",
        code: "+998",
      },
      {
        flag: "vu",
        name: "Vanuatu",
        iso: "(vu)",
        code: "+678",
      },
      {
        flag: "ve",
        name: "Venezuela",
        iso: "(ve)",
        code: "+58",
      },
      {
        flag: "vn",
        name: "Vietnam",
        iso: "(vn)",
        code: "+84",
      },
      {
        flag: "vi",
        name: "Virgin Islands",
        iso: "(vi)",
        code: "+1-340*",
      },
      {
        flag: "ye",
        name: "Yemen",
        iso: "(ye)",
        code: "+998",
      },
      {
        flag: "zm",
        name: "Zambia",
        iso: "(zm)",
        code: "+260",
      },
      {
        flag: "zw",
        name: "Zimbabwe",
        iso: "(zw)",
        code: "+00",
      },
    ],
  }),

  computed: {
    formatedPhoneNumber: {
      get() {
        return this.payload.phone;
      },
      set(param) {
        if (param.length > 4 && param.substring(0, 1) == "0") {
          this.payload.phone = param.substring(1);
        } else {
          this.payload.phone = param;
        }
      },
    },
    
  },
  async mounted() {
    // title
    let paramEmail = this.$route.query.e || false;
    if(paramEmail){
      paramEmail = paramEmail.trim();
    }
    let paramEmailIsValid = this.isEmailValid(paramEmail);

    if(paramEmail && paramEmailIsValid){
      
      let emailUrl = `${process.env.VUE_APP_API_BIZNET_URL}virtuelab/getCustomerBiznetByEmail?e=${paramEmail}`;
      
      await API.get(emailUrl, {}).then(
        res => {
          if(res.data.length>0){
            this.payload.firstname = res.data[0].firstname;
            this.payload.lastname = res.data[0].customerlastname;
            this.formatedPhoneNumber = res.data[0].customermobilephome;
            this.payload.email = res.data[0].customeremail1;

            let gender = res.data[0].customergender || false;
            
            if(gender){
              if(gender.toLowerCase() === 'female'){
                this.payload.title = 8;
              }else if(gender.toLowerCase() === 'male'){
                this.payload.title = 7;
              }
            }
          }
          
        }
      );      
    }else{
      console.log('email not valid : ', paramEmail);
    }
    
    await API.get(`${process.env.VUE_APP_API_TRUST}patient-title/get`, {}).then(
      res => {
        if (res.statusCode == 200) {
          this.titleName = res.results.sort((a, b) => this.sortByName(a, b));
        }
      }
    );




  },
  methods: {
    isEmailValid(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    sortByName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    validate() {
      if (this.$refs.form.validate() == true) {
        this.loading = true;
        const { username, email, password, firstname, lastname, phone, title } =
          this.payload;
        const dataReady = {
          request_otp: true,
          token: "",
          username,
          email,
          password,
          title_id: title,
          first_name: firstname,
          last_name: lastname,
          phone_code: this.defaultPhoneCode,
          phone_number: phone.replace(/\s/g, "").replaceAll("-", ""),
          client_unique_id: "tm617a34c4a3b86",
        };
        API.post(
          `${process.env.VUE_APP_API_TRUST}register?lang=${this.$i18n.locale}`,
          {
            "Content-Type": "application/json",
          },
          dataReady
        )
          .then(res => {
            this.loading = false;

            if (res.statusCode === 200) {
              this.openOtp = true;
              this.otpName = firstname;
              this.distance = res.results.time_to_wait;
            } else if (res.statusCode == 400) {
              Object.keys(res.errors).forEach(element => {
                this.toast(
                  "error",
                  typeof res.errors[element] == "object"
                    ? res.errors[element][0]
                    : res.errors[element]
                );
              });
            } else {
              this.$swal({
                icon: "error",
                text: res.message,
              });
            }
          })
          .catch(() => {
            this.$swal({
              icon: "error",
              text: this.$t("error_try_again_latter"),
            });
          });
      }
    },
    passwordConfirmRules(value) {
      if (value !== this.payload.password) {
        return `${this.$t("field.confirm_password")} ${this.$t("must_valid")}`;
      } else if (value == "") {
        return `${this.$t("field.confirm_password")} ${this.$t("is_required")}`;
      } else {
        return true;
      }
    },
    emailRules(value) {
      if (value == "") {
        return `${this.$t("field.email")} ${this.$t("is_required")}`;
      } else if (!validator.isEmail(value)) {
        return `${this.$t("field.email")} ${this.$t("must_valid")}`;
      } else {
        return true;
      }
    },
    handleOnComplete(value) {
      this.loading = true;
      API.post(
        `${process.env.VUE_APP_API_TRUST}verify-otp?lang=${this.$i18n.locale}`,
        {
          "Content-Type": "application/json",
        },
        {
          otp: value,
          email: this.payload.email,
        }
      )
        .then(res => {
          this.loading = false;

          if (res.statusCode == 200) {
            const { username, email, password, firstname, lastname, phone, title } =
              this.payload;
            const dataReady = {
              request_otp: false,
              token: res.results.token,
              username,
              email,
              password,
              title_id: title,
              first_name: firstname,
              last_name: lastname,
              phone_code: this.defaultPhoneCode,
              phone_number: phone.replace(/\s/g, "").replaceAll("-", ""),
              client_unique_id: "tm617a34c4a3b86",
            };

            API.post(
              `${process.env.VUE_APP_API_TRUST}register?lang=${this.$i18n.locale}`,
              { "Content-Type": "application/json" },
              dataReady
            )
              .then(res => {
                this.loading = false;
                if (res.statusCode === 200) {
                  this.payload = {
                    password: "",
                    phone: "",
                    passwordConfirm: "",
                    email: "",
                    username: "",
                    firstname: "",
                    lastname: "",
                  };
                  Auth.setToken(res.results.access_token);
                  Auth.setTypeUser(0);
                  this.$router.push("/continue-register");
                } else if (res.statusCode == 400) {
                  this.openOtp = false;
                  Object.keys(res.errors).forEach(element => {
                    this.toast(
                      "error",
                      typeof res.errors[element] == "object"
                        ? res.errors[element][0]
                        : res.errors[element]
                    );
                  });
                } else {
                  this.$swal({
                    icon: "error",
                    text: res.message,
                  });
                }
              })
              .catch(() => {
                this.$swal({
                  icon: "error",
                  text: "Error Registration!",
                });
              });
          } else {
            this.$swal({
              icon: "error",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$swal({
            icon: "error",
            text: this.$t("error_try_again_latter"),
          });
        });
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },

    update(e) {
      this.updateAllReport = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-register {
  padding: 8px 18px;
  background: #00a4b3 !important;
  color: white;
  text-transform: capitalize;
  border-radius: 8px;
  width: 150px !important;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: #1a428a !important;
}

@media screen and (max-width: 600px) {
  // .column-phone-code {
  //   margin-top: -30px;
  // }

  // .column-phone-number {
  //   margin-top: -30px;
  // }

  // .column-username {
  //   margin-top: -30px;
  // }

  // .column-email {
  //   margin-top: -30px;
  // }

  // .column-password {
  //   margin-top: -30px;
  // }

  // .column-confirm-password {
  //   margin-top: -30px;
  // }

  .already-text {
    font-size: 12px;
    margin-bottom: 0px;
    a {
      text-decoration: none;
      font-weight: bold;
    }
  }

  .button-register {
    color: white;
    background: #00a4b3 !important;
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
    height: 35px !important;
    text-transform: capitalize;
    width: 100px;
  }
}
.field-padding {
  padding: 0 10px !important;
}
.field-padding-top {
  padding: 12px 10px 0px 10px !important;
}
.card {
  padding: 23px !important;
}
</style>