<!-- start modal popup order detail (dialog_order)-->
<template>
  <v-row justify="center">
    <v-dialog
      v-model="is_show"
      content-class="dialogorderdetail"
      width="1000px"
    >
      <v-card class="pt-5">
        <v-card-title class="px-3 px-md-5">
          <v-row dense>
            <v-col cols="6" md="6" no-gutters class="ma-0 pa-0">
              <span class="text-h5">{{ $t("invoice") }}</span>
              <br />
              <template v-if="dataDialogInvoice.order_number">
                <v-btn
                  text
                  small
                  color="#FFF"
                  @click="openReceipt(dataDialogInvoice.order_number)"
                  class="btn-download-report"
                >
                  <v-icon left dark> mdi-download </v-icon>
                  {{ $t("download") }} PDF
                </v-btn>
              </template>
            </v-col>
            <v-col cols="6" md="6" no-gutters class="ma-0 pa-0">
              <img
                src="/iconVirtu.png"
                alt="Icon Virtu"
                class="float-right dialogvirtulogo"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-3 px-md-5">
          <v-row class="pt-5">
            <v-col cols="12">
              <h3 class="font-black">{{ $t("document_information") }}</h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="super-dense" mobile-breakpoint="0">
                      <template v-slot:default>
                        <tbody class="no-pad tbl">
                          <tr class="grey-color head-vir2">
                            <th class="text-center tb-wide2">
                              {{ $t("field.invoice_no") }}
                            </th>
                            <th class="text-center tb-wide2">
                              {{ $t("field.invoice_date") }}
                            </th>
                            <th class="text-center tb-wide3">
                              {{ $t("field.branch_name") }}
                            </th>
                            <!--
                            <th class="text-center tb-wide desktop" style="background: white; border: 1px solid;" v-if="dataDialogInvoice.data.metadata.booking_code !== null" rowspan="6">
                              <qrcode-vue
                                  :value="dataDialogInvoice.data.metadata.booking_code"
                                  :size="sizes"
                                  level="H"
                                ></qrcode-vue>
                            </th>
                            -->
                          </tr>
                          <tr
                            class="hover-apri"
                            v-if="dataDialogInvoice.list != null"
                          >
                            <td class="text-center">
                              {{
                                dataDialogInvoice.data.metadata.invoice_number
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                formatDate(
                                  dataDialogInvoice.data.metadata.invoice_date,
                                  "DD MMM YYYY"
                                ).toUpperCase()
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                dataDialogInvoice.data.metadata.plant_name ==
                                "IN SELECTION"
                                  ? "-"
                                  : dataDialogInvoice.data.metadata.plant_name
                              }}
                            </td>
                          </tr>
                          <tr class="hover-apri" v-else>
                            <td colspan="4" class="text-center">
                              {{ $t("no_data") }}
                            </td>
                          </tr>

                          <tr class="grey-color head-vir2">
                            <th class="text-center tb-wide2">
                              {{ $t("field.medical_record_no") }}
                            </th>
                            <th class="text-center tb-wide">
                              {{ $t("field.primary_doctor") }}
                            </th>
                            <th class="text-center">
                              {{ $t("field.payment_method") }}
                            </th>
                          </tr>
                          <tr
                            class="hover-apri"
                            v-if="dataDialogInvoice.list != null"
                          >
                            <td class="text-center">
                              {{
                                dataDialogInvoice.data.metadata
                                  .medical_record_number
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                dataDialogInvoice.data.metadata
                                  .practitioner_name != null
                                  ? dataDialogInvoice.data.metadata
                                      .practitioner_name
                                  : "-"
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                dataDialogInvoice.data.metadata.payment_method
                              }}
                            </td>
                          </tr>
                          <tr class="hover-apri" v-else>
                            <td colspan="4" class="text-center">
                              {{ $t("no_data") }}
                            </td>
                          </tr>

                          <tr class="grey-color head-vir2">
                            <th class="text-center">
                              {{ $t("field.gender") }}
                            </th>
                            <th class="text-center">
                              {{ $t("field.currency") }}
                            </th>
                            <th class="text-center">
                              {{ $t("page.cart.total_amount") }}
                            </th>
                          </tr>
                          <tr
                            class="hover-apri"
                            v-if="dataDialogInvoice.list != ''"
                          >
                            <td class="text-center">
                              {{
                                dataDialogInvoice.data.metadata[
                                  $i18n.locale == "id"
                                    ? `gender_name_id`
                                    : `gender`
                                ]
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                dataDialogInvoice.data.metadata.currrency !=
                                null
                                  ? dataDialogInvoice.data.metadata.currrency
                                  : "-"
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                formatPrice(
                                  dataDialogInvoice.data.metadata.grand_total
                                )
                              }}
                            </td>
                          </tr>
                          <tr class="hover-apri" v-else>
                            <td colspan="4" class="text-center">
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>
        <!--
         <v-card-text class="mobile">
          <v-col
            cols="12"
            class="d-flex align-center justify-center"
            style="padding-top: 15px; padding-bottom: 0"
          >
            <qrcode-vue
              :value="dataDialogInvoice.data.metadata.booking_code"
              :size="sizes"
              level="H"
            ></qrcode-vue>
          </v-col>
        </v-card-text>
        -->
        <v-card-text class="px-3 px-md-5">
          <v-row>
            <v-col cols="12">
              <h3 class="font-black">{{ $t("cutomer_information") }}</h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="no-hover-table super-dense">
                      <template v-slot:default>
                        <tbody
                          class="no-pad desktop"
                          v-if="dataDialogInvoice.list != ''"
                        >
                          <tr class="hover-apri">
                            <td class="font-weight-bold">
                              {{ dataDialogInvoice.data.metadata.patient_name }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.mobile_phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogInvoice.data.metadata
                                          .mobile_phone !== null
                                          ? dataDialogInvoice.data.metadata
                                              .mobile_phone
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              {{
                                `${
                                  dataDialogInvoice.data.metadata.address !==
                                  null
                                    ? dataDialogInvoice.data.metadata.address.toUpperCase()
                                    : "-"
                                }`
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogInvoice.data.metadata
                                          .home_phone !== null
                                          ? dataDialogInvoice.data.metadata
                                              .home_phone
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              {{
                                `${
                                  dataDialogInvoice.data.metadata.city == null
                                    ? "-"
                                    : `${dataDialogInvoice.data.metadata.city}, `
                                } ${
                                  dataDialogInvoice.data.metadata
                                    .district_name == null
                                    ? "-"
                                    : dataDialogInvoice.data.metadata
                                        .district_name
                                }`
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.email") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogInvoice.data.metadata
                                          .email !== null
                                          ? dataDialogInvoice.data.metadata.email.toUpperCase()
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              {{
                                `${
                                  dataDialogInvoice.data.metadata
                                    .province_name == null
                                    ? "-"
                                    : `${dataDialogInvoice.data.metadata.province_name} `
                                }${
                                  dataDialogInvoice.data.metadata.postal_code ==
                                  null
                                    ? "-"
                                    : `${dataDialogInvoice.data.metadata.postal_code}, `
                                }${
                                  dataDialogInvoice.data.metadata.country ==
                                  null
                                    ? "-"
                                    : dataDialogInvoice.data.metadata.country
                                }`
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.dob") }}</td>
                                  <td>
                                    :{{
                                      formatDate(
                                        dataDialogInvoice.data.metadata
                                          .date_of_birth,
                                        "DD MMM YYYY"
                                      ).toUpperCase()
                                    }}
                                    |
                                    {{
                                      dataDialogInvoice.data.metadata[
                                        $i18n.locale == "id"
                                          ? `patient_age_id`
                                          : `age`
                                      ].toUpperCase()
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr
                            class="hover-apri"
                            v-if="
                              dataDialogInvoice.data.metadata.mcu_type != null
                            "
                          >
                            <td></td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.mcu_type") }}</td>
                                  <td>
                                    :
                                    {{
                                      dataDialogInvoice.data.metadata.mcu_type
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <!--
                          <tr class="hover-apri">
                            <td class="font-weight-bold">

                              MEDICAL RECORD NUMBER :
                              {{
                                dataDialogInvoice.data.metadata
                                  .medical_record_number
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>TEST DATE</td>
                                  <td>
                                    :
                                      -
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          -->
                        </tbody>
                        <!-- start mobile part -->
                        <tbody
                          class="no-pad mobile"
                          v-if="dataDialogInvoice.list != ''"
                        >
                          <tr class="hover-apri">
                            <td class="font-weight-bold">
                              {{ dataDialogInvoice.data.metadata.patient_name }}
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              {{
                                `${
                                  dataDialogInvoice.data.metadata.address !==
                                  null
                                    ? dataDialogInvoice.data.metadata.address.toUpperCase()
                                    : "-"
                                }`
                              }}
                            </td>
                          </tr>

                          <tr class="hover-apri">
                            <td>
                              {{
                                `${
                                  dataDialogInvoice.data.metadata.city == null
                                    ? "-"
                                    : `${dataDialogInvoice.data.metadata.city}, `
                                } ${
                                  dataDialogInvoice.data.metadata
                                    .district_name == null
                                    ? "-"
                                    : dataDialogInvoice.data.metadata
                                        .district_name
                                }`
                              }}
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              {{
                                `${
                                  dataDialogInvoice.data.metadata
                                    .province_name == null
                                    ? "-"
                                    : `${dataDialogInvoice.data.metadata.province_name} `
                                }${
                                  dataDialogInvoice.data.metadata.postal_code ==
                                  null
                                    ? "-"
                                    : `${dataDialogInvoice.data.metadata.postal_code}, `
                                }${
                                  dataDialogInvoice.data.metadata.country ==
                                  null
                                    ? "-"
                                    : dataDialogInvoice.data.metadata.country
                                }`
                              }}
                            </td>
                          </tr>
                          <!-- <tr class="hover-apri">
                            <td class="font-weight-bold">
                              {{ $t("field.medical_record_no") }} :
                              {{
                                dataDialogInvoice.data.metadata
                                  .medical_record_number
                              }}
                            </td>
                          </tr> -->
                          <tr class="hover-apri">
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.mobile_phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogInvoice.data.metadata
                                          .mobile_phone !== null
                                          ? dataDialogInvoice.data.metadata
                                              .mobile_phone
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogInvoice.data.metadata
                                          .home_phone !== null
                                          ? dataDialogInvoice.data.metadata
                                              .home_phone
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.email") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogInvoice.data.metadata
                                          .email !== null
                                          ? dataDialogInvoice.data.metadata.email.toUpperCase()
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.dob") }}</td>
                                  <td>
                                    :

                                    {{
                                      dataDialogInvoice.data.metadata[
                                        $i18n.locale == "id" ? `dob_id` : `dob`
                                      ].toUpperCase()
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <!--   v-if="
                              dataDialogInvoice.data.metadata.mcu_type != null
                            " -->
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.mcu_type") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogInvoice.data.metadata
                                          .mcu_type !== null
                                          ? dataDialogInvoice.data.metadata
                                              .mcu_type
                                          : "-"
                                      }`
                                    }}
                                    <!-- dataDialogInvoice.data.metadata.mcu_type -->
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr class="hover-apri">
                            <td class="text-center">
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>
        <v-card-text class="px-3 px-md-5">
          <v-row>
            <v-col cols="12">
              <h3 class="font-black">{{ $t("invoice_detail") }}</h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="table-sticky super-dense">
                      <template v-slot:default>
                        <thead
                          class="no-pad"
                          v-if="dataDialogInvoice.subscription == false"
                        >
                          <tr class="grey-color head-vir2">
                            <th class="text-center tb-wide">
                              {{ $t("field.product_code") }}
                            </th>
                            <th class="text-center tb-wide">
                              {{ $t("field.description") }}
                            </th>
                            <th class="text-center">{{ $t("field.uom") }}</th>
                            <th class="text-center">{{ $t("field.qty") }}</th>
                            <th
                              class="text-center"
                              style="min-width: 125px !important"
                            >
                              {{ $t("field.unit_price") }}
                            </th>
                            <th class="text-center">
                              {{ $t("field.disc") }}(%)
                            </th>
                            <th class="text-center">{{ $t("field.disc") }}</th>
                            <th class="text-center">{{ $t("field.payer") }}</th>
                            <th class="text-center">
                              {{ $t("field.patient") }}
                            </th>
                          </tr>
                        </thead>
                        <thead class="no-pad" v-else>
                          <tr class="grey-color head-vir2">
                            <th class="text-center tb-wide">
                              {{ $t("field.product_code") }}
                            </th>
                            <th class="text-center tb-wide">
                              {{ $t("field.description") }}
                            </th>
                            <th class="text-center">{{ $t("field.uom") }}</th>
                            <th class="text-center">{{ $t("field.qty") }}</th>
                          </tr>
                        </thead>
                        <tbody
                          class="no-pad"
                          v-if="dataDialogInvoice.subscription == false"
                        >
                          <tr v-if="prodName != ''">
                            <td colspan="9">{{ prodName }}</td>
                          </tr>
                          <tr
                            v-for="(item, indexFilter) in tableFilter"
                            :key="indexFilter"
                            :class="{
                              'grey-color': item.type == 'header',
                              'hover-apri': item.type == 'test',
                            }"
                          >
                            <td
                              v-if="item.type == 'category'"
                              colspan="9"
                              class="category-blue"
                            >
                              {{ item.text }}
                            </td>
                            <td
                              v-else-if="item.type == 'subcategory'"
                              colspan="9"
                              class="bold pl-sub"
                            >
                              {{ item.text }}
                            </td>
                            <template v-else>
                              <td class="text-left td-custom">
                                {{ item.code }}
                              </td>
                              <td class="text-left">
                                {{ item.name }}
                              </td>
                              <td class="text-center">-</td>
                              <td class="text-right">
                                {{ item.qty }}
                              </td>
                              <td class="text-right">
                                {{ formatPrice(item.amount) }}
                              </td>
                              <td class="text-right">
                                {{ formatDisc(item.discount_percent) }}
                              </td>
                              <td class="text-right">
                                {{ formatPrice(item.discount_amount) }}
                              </td>
                              <td class="text-center">-</td>
                              <td class="text-right">
                                {{ formatPrice(item.sub_total) }}
                              </td>
                            </template>
                          </tr>
                        </tbody>
                        <tbody
                          class="no-pad"
                          v-else
                          v-for="(item, index) in tableFilter"
                          :key="index"
                        >
                          <tr>
                            <td colspan="3">
                              {{ item.package_name }}
                            </td>
                            <td class="text-center">
                              {{ item.qty }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, indx) in tableFilterDeal"
                            :key="indx"
                            :class="{
                              'grey-color': item.type == 'header',
                              'hover-apri': item.type == 'test',
                            }"
                          >
                            <td
                              v-if="item.type == 'category'"
                              colspan="9"
                              class="category-blue"
                            >
                              {{ item.text }}
                            </td>
                            <td
                              v-else-if="item.type == 'subcategory'"
                              colspan="9"
                              class="bold pl-sub"
                            >
                              {{ item.text }}
                            </td>
                            <template v-else>
                              <td class="text-left td-custom pl-sub-item">
                                {{ item.product_code }}
                              </td>
                              <td class="text-left">
                                {{ item[`name_${$i18n.locale}`] }}
                              </td>
                            </template>
                          </tr>
                          <!-- <template v-for="(val, indx) in item.products">
                          <tr
                            :key="indx"
                          >
                            <td
                              colspan="4"
                              class="category-blue"
                            >
                              {{ val[`sub_category_${$i18n.locale}`] }}
                            </td>
                          </tr>
                          <tr
                            :key="indx"
                          >
                            <td
                              colspan="4"
                              class="bold pl-sub"
                            >
                              {{ val[`name_${$i18n.locale}`] }}
                            </td>
                          </tr>
                          <tr
                            :key="indx"
                          >
                            <td class="text-left td-custom">{{ val.product_code }}</td>
                            <td class="text-left">
                              {{ val[`name_${$i18n.locale}`] }}
                            </td>
                          </tr>

                        </template>   -->
                          <!-- <tr
                            v-for="(item, index2) in tableFilter[0].products"
                            :key="index2"
                          >
                            <td
                              colspan="4"
                            >
                              {{ item[`name_${$i18n.locale}`] }}
                            </td>
                          </tr> -->
                          <!-- <div  
                            v-for="(item, index) in tableFilter"
                            :key="index"
                          >
                            <tr>
                              <td
                                colspan="4"
                              >
                                {{ item.package_name }}
                              </td>
                            </tr>
                            <div  
                              v-for="(item, index) in tableFilter[0].products"
                              :key="index"
                            >
                              <tr>
                                <td
                                  colspan="4"
                                  class="category-blue"
                                >
                                  {{ item[`category_${$i18n.locale}`] }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colspan="4"
                                >
                                  {{ item[`name_${$i18n.locale}`] }}
                                </td>
                              </tr>
                            </div>  
                          </div> -->
                        </tbody>
                        <!--
                        <tbody class="no-pad">
                          <tr
                            v-for="(item, index) in dataDialogInvoice.list"
                            :key="index"
                            :class="{
                              'grey-color': item.type == 'header',
                              'hover-apri': item.type == 'test',
                            }"
                          >
                          <td class="text-left td-custom" >
                            {{item.code}}
                          </td>
                          <td class="text-left">
                            {{item.name}}
                          </td>
                          <td class="text-center">
                            -
                          </td>
                          <td class="text-right">
                            {{item.qty}}
                          </td>
                          <td class="text-right">
                            {{ formatPrice(item.amount)}}
                          </td>
                          <td class="text-right">
                            {{item.discount_percent}}%
                          </td>
                          <td class="text-right">
                            {{formatPrice(item.discount_amount)}}
                          </td>
                          <td class="text-center">
                            -
                          </td>
                          <td class="text-right">
                            {{formatPrice(item.sub_total)}}
                          </td>
                          </tr>
                        </tbody>
                        -->
                        <tfoot v-if="dataDialogInvoice.subscription == false">
                          <tr class="font-weight-bold">
                            <td colspan="5"></td>
                            <td colspan="3">
                              {{ $t("page.cart.total_price") }}
                            </td>
                            <td class="text-right">
                              {{
                                formatPrice(
                                  dataDialogInvoice.data.metadata.amount_total
                                )
                              }}
                            </td>
                          </tr>
                          <tr class="font-weight-bold">
                            <td colspan="5"></td>
                            <td colspan="3">
                              {{ $t("page.cart.discount") }} (IDR)
                            </td>
                            <td class="text-right">
                              {{
                                formatPrice(
                                  dataDialogInvoice.data.metadata.total_discount
                                )
                              }}
                            </td>
                          </tr>
                          <tr class="font-weight-bold">
                            <td colspan="5"></td>
                            <td colspan="3">
                              {{ $t("page.cart.grand_total") }}
                            </td>
                            <td class="text-right">
                              {{
                                formatPrice(
                                  dataDialogInvoice.data.metadata.grand_total
                                )
                              }}
                            </td>
                          </tr>
                        </tfoot>
                        <tfoot v-else>
                          <tr class="font-weight-bold">
                            <td colspan="2"></td>
                            <td>
                              {{ $t("page.cart.total_price") }}
                            </td>
                            <td class="text-right">
                              {{
                                formatPrice(
                                  dataDialogInvoice.data.metadata.amount_total
                                )
                              }}
                            </td>
                          </tr>
                          <tr class="font-weight-bold">
                            <td colspan="2"></td>
                            <td>{{ $t("page.cart.discount") }} (IDR)</td>
                            <td class="text-right">
                              {{
                                formatPrice(
                                  dataDialogInvoice.data.metadata.total_discount
                                )
                              }}
                            </td>
                          </tr>
                          <tr class="font-weight-bold">
                            <td colspan="2"></td>
                            <td>
                              {{ $t("page.cart.grand_total") }}
                            </td>
                            <td class="text-right">
                              {{
                                formatPrice(
                                  dataDialogInvoice.data.metadata.grand_total
                                )
                              }}
                            </td>
                          </tr>
                        </tfoot>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary darken-1" text @click="toggleDialog()">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import Card from "@/components/v2/card/index.vue";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
//import QrcodeVue from "qrcode.vue";
// import icons from "@/components/elements/Icons.vue";

export default {
  components: {
    Card,
    //QrcodeVue,
    // icons,
  },
  props: {
    tampilkan: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => [],
    },
    tableFilter: {
      type: Array,
      default: () => [],
    },
    tableFilterDeal: {
      type: Array,
      default: () => [],
    },
    prodName: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      is_show: this.tampilkan,
      dataDialogInvoice: this.item,
    };
  },
  watch: {
    tampilkan: function (new_val, old_val) {
      if (old_val !== "") {
        this.is_show = new_val;
      }
    },
    is_show: function (new_val, old_val) {
      if (old_val !== "") {
        if (!new_val) {
          this.$emit("toggleDialog", this.is_show);
        }
      }
    },
  },
  // mounted(){
  //   console.log(this.prodName,'assaasds')
  // },
  methods: {
    openReceipt(item) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}invoices/get-printout?order_number=${item}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        this.preview = res;

        window.open(this.preview.linkDownload, "_blank");
      });
    },
    formatPrice(price) {
      return `${Intl.NumberFormat().format(price)}.00`;
    },
    formatDisc(discount) {
      return Math.round(discount * 100) / 100 == 0
        ? `0%`
        : (Math.round(discount * 100) / 100).toFixed(2) + "%";
    },
    formatDateTime(par) {
      if (!par) return null;
      return moment(par)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY HH:mm:ss")
        .toUpperCase();
    },
    formatDate(date, format) {
      return moment(date).locale(this.$i18n.locale).format(format);
    },
    toggleDialog() {
      this.is_show = false;
      this.$emit("toggleDialog", this.is_show);
    },
    downloadLab(param) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}diagnosis/get-labreport?order_number=${param}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        this.download = res;
        window.open(this.download.linkDownload, "_blank");
      });
    },
    downloadMcu(param) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}diagnosis/get-mcureport?order_number=${param}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        this.download = res;
        window.open(this.download.linkDownload, "_blank");
      });
    },
    formaterText(param) {
      let result = param.substr(0, 1);
      if (result == "[") {
        let newVal = JSON.parse(param);
        let newValues = newVal[0].reference_text;

        return newValues;
      } else {
        return param;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.pl-sub {
  padding-left: 3% !important;
}
.pl-sub-item {
  padding-left: 6% !important;
}
.grey-color {
  background: #eeeeee;
}

.head-vir {
  background: #1a428a;
  color: #fff;
}

.head-vir2 {
  background: #cecece;
  font-weight: bold;
}

.head-vir2:hover {
  background: #cecece !important;
}

.head-vir2 th {
  color: #000 !important;
}

.bold {
  font-weight: bold;
}

// .hover-apri:hover {
//   background: rgba(113, 188, 225, 0.1) !important;
// }
// .hover-apri:hover td {
//   font-weight: bold;
// }

.v-data-table table tr td {
  font-size: 12px !important;
}

.mobile {
  display: none;
}

@media (max-width: 450px) {
  .dialogvirtu.v-dialog {
    margin: 5px !important;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  .dialogvirtulogo {
    width: 150px;
  }
}
</style>
<style lang="scss">
@media (max-width: 450px) {
  .dialogorderdetail.v-dialog {
    margin: 5px !important;
  }
  .dialogorderdetail .v-card__text {
    padding: 0 10px 20px !important;
  }
  .td-custom {
    width: 100% !important;
  }
}
.font-black {
  color: #000 !important;
}
.category-black {
  color: #fff !important;
  background: #000 !important;
}
.category-blue {
  color: #fff !important;
  background: #233e8d !important;
}
.btn-download-report {
  background: #209fb4;
}

.table-inside {
  width: 100%;
  padding: 0;
}

.table-inside tr td:first-child {
  width: 20%;
}

.mobile tr {
  display: inline-table;
  width: 100%;
}

.mobile .table-inside tr td:first-child {
  width: 20%;
}

.td-custom {
  width: 20% !important;
}

@media (min-width: 600px) {
  .table-sticky .v-data-table__wrapper {
    overflow-x: auto;
    overflow-y: auto;
  }
  .table-sticky thead {
    position: sticky;
    inset-block-start: 0;
    top: 0;
  }
}
@media (max-width: 599px) {
  .table-sticky .v-data-table__wrapper {
    overflow-x: auto;
    overflow-y: auto;
    height: 400px;
  }
  .table-sticky thead tr {
    border-bottom: 0 !important;
    position: sticky;
    top: 0;
    z-index: 2;
  }
}
.popup {
  padding: 8px;
  background: white;
  text-transform: uppercase;
  font-size: 12px !important;
  line-height: 16px;
  a {
    text-decoration: none;
    color: black;
  }
}
.title-grid {
  display: grid;
}
.title-grid-h {
  text-align: left;
}
.title-grid-c {
  text-align: left;
  font-size: 8px;
}
.mt5 {
  margin-top: 5px !important;
}
.mb10 {
  margin-bottom: -10px;
}
.mb5 {
  margin-bottom: 5px !important;
}
</style>
