<template>
  <div class="main-layout">
    <div class="px-5 content-set">
      <div class="card--payment">
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <img :src="require('../assets/images/ilustrations/IluPendingPaid.png')" height="170" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <strong class="subheading">{{ $t("success_payment") }} ✨</strong>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <p class="content text-center">
              {{ $t("congrats_payment_verify") }}
            </p>
          </v-col>
        </v-row>
        <!-- <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <div class="text-center amount">
              <p class="content-amount">Amount Paid:</p>
              <strong class="rp">Rp {{ formater(totalPayment) }}</strong>
            </div>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col class="mt-15 d-flex align-center justify-center">
             <localized-link to="/patient/profile?tab=ORDER">
              <c-button rounded shadow bold>
                {{ $t("view_order") }}
              </c-button>
            </localized-link>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <router-link to="/">
              <strong class="to-home d-flex align-center justify-center">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
                  />
                </svg>
                {{ $t("back_to_home") }}</strong
              >
            </router-link>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../service/api_service.js";
import Auth from "../service/auth_service";
import CButton from "@/components/v2/button/index.vue";

export default {
  name: "Cart",
    components: {
    CButton,
  },
  data: () => ({
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    branchGet: { prices: [] },
    totalOrder: 0,
    totalPayment: 0,
  }),
  mounted() {
    API.get(
      `${process.env.VUE_APP_API_TRUST}plant/get?product_id=${this.$route.query.product}`,
      {
        Authorization: `Bearer ${Auth.getToken()}`,
      }
    ).then(res => {
      if (res.statusCode === 200) {
        const getIndex = res.results.findIndex(
          data => data.id == this.$route.query.branch
        );
        this.branchGet = res.results[getIndex];
        this.totalOrder =
          parseInt(this.branchGet.product.prices[0].amount) + this.totalOrder;
        this.totalPayment = this.totalOrder;
      }
    });
  },
  methods: {
    formater(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/custom.scss";

.card--payment {
  padding: 32px 15px;
  border-radius: 16px;
}

.header {
  padding: 30px 0 10px 0;
}
.subheading {
  color: rgba(121, 196, 198, 1);
  font-size: 18px;
}
.rp {
  color: $gray_2;
  font-size: 18px;
  margin-top: 40px;
}
.to-home {
  color: $gray_2;
  font-size: 18px;
}
.content {
  font-size: 16px;
  margin-top: 30px;
  font-weight: 500;
  color: rgba(123, 140, 174, 1);
}
.content-amount {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  color: rgba(123, 140, 174, 1);
}
.content-set {
  padding: 10px 0 60px 0;
}
.amount {
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  padding: 15px 40px;
  border-radius: 15px;
}
.btn-view-order {
  color: #fff !important;
  background: radial-gradient(
    118.75% 157.07% at 34.74% -18.75%,
    #5278c7 0%,
    $blue_1 100%
  );
  box-shadow: -4.74667px 8px 30px rgba(35, 63, 120, 0.25);
  border-radius: 12px;
  height: 45px;
  margin-bottom: 15px;
}
a {
  text-decoration: none;
}
</style>
