<template>
  <div class="otp">
    <h1>{{ $t("hi") }} {{ title }}</h1>
    <p>{{ $t("page.register.input_otp") }}</p>
    <div class="mb-8 d-flex justify-center">
      <v-otp-input
        ref="otpInput"
        input-classes="otp-input"
        separator=""
        v-model="otp"
        :length="6"
        :disabled="loading"
        :should-auto-focus="true"
        :is-input-num="true"
        @finish="handleOnComplete"
      ></v-otp-input>
      <v-overlay absolute :value="loading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </div>
    <!-- <v-btn rounded class="btn-checkout" @click="handleOnComplete"> Send </v-btn> -->
    <div class="d-flex justify-end otp__countdown" v-if="distance > 0">
      <span>{{ otpTime }} {{ $t("page.register.to_resend_otp") }}</span>
    </div>
    <p class="mt-3 text-right otp__resend mb-0" v-else @click="resendOtp">
      {{ $t("page.register.resend_otp") }}
    </p>
  </div>
</template>

<script>
import API from "../../service/api_service";
// import VOtpInput from "@bachdgvn/vue-otp-input";

export default {
  name: "OTP",
  data: () => ({
    otpTime: "0m 0s",
    timerOtp: () => {},
    distance: 0,
    loading: false,
    otp: "",
  }),

  // components: { VOtpInput },
  watch: {
    timeEnd: function (param) {
      console.log(param);
    },
  },
  mounted() {
    this.distance = this.timeEnd;
    this.countdown();
  },
  beforeDestroy() {
    clearInterval(this.timerOtp);
  },
  props: {
    phone: { type: String, default: "" },
    email: { type: String, default: "" },
    title: { type: String, default: "" },
    timeEnd: { type: Number, default: 0 },
  },
  methods: {
    resendOtp() {
      API.post(
        `${process.env.VUE_APP_API_TRUST}request-otp?lang=${this.$i18n.locale}`,
        {
          "Content-Type": "application/json",
        },
        {
          email: this.email,
          // phone_number:
          //   this.defaultPhoneCode +
          //   this.payload.phone.replace(/\s/g, "").replaceAll("-", ""),
          // username: this.payload.username,
        }
      )
        .then(res => {
          if (res.statusCode == 200) {
            this.$swal({
              text: this.$t("success_resend_otp"),
              confirmButtonColor: "#1A428A",
              confirmButtonText: this.$t("oke"),
            });
            this.distance = res.results.time_to_wait;
          } else {
            this.$swal({
              icon: "error",
              text: res.message,
            });
          }
        })
        .catch(err => {
          if (err.response.statusCode == 400) {
            this.$swal({
              icon: "error",
              text: this.$t("error_try_again_latter"),
            });
          }
          this.loading = false;
        });
    },
    handleOnComplete(rsp) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$emit("handleOnComplete", rsp);
      }, 3500);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    countdown() {
      var time = this;
      this.timerOtp = setInterval(function () {
        time.distance = time.distance - 1;
        time.otpTime = `${Math.floor(time.distance / 60)}m  ${
          time.distance % 60
        }s`;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/custom.scss";
.otp {
  text-align: center;
  &__resend {
    cursor: pointer;
    color: $blue_2;
  }
  &__countdown {
    color: $gray_2;
  }

  h1 {
    margin: 16px 0;
    font-size: 24px;
    font-weight: 600;
  }
  input {
    padding: 10px;
    border: 1px solid gray;
    outline: none;
    border-radius: 10px;
    margin-right: 10px;
    text-align: center;
    font-size: 20px;
    &:nth-last-child(1) {
      margin: 0;
    }
  }
}
</style>