var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn",class:{
    'btn--rounded': _vm.rounded,
    'btn--bold': _vm.bold,
    'btn--shadow': _vm.shadow,
    'btn--fluid': _vm.fluid,
    'btn--sm--fluid': _vm.fluidSm,
    'btn--sm--non--fluid': _vm.nonFluidSm,
    'btn--md--fluid': _vm.fluidMd,
    'btn--md--non--fluid': _vm.nonFluidMd,
    'btn--disabled': _vm.disabled,
    'btn--outline': _vm.outline,
    'btn--rounded-small': _vm.roundedSmall,
    'btn--small': _vm.small,
  },attrs:{"disabled":_vm.disabled,"type":_vm.type},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }