<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99996 1.66675C6.77496 1.66675 4.16663 4.27508 4.16663 7.50008C4.16663 11.8751 9.99996 18.3334 9.99996 18.3334C9.99996 18.3334 15.8333 11.8751 15.8333 7.50008C15.8333 4.27508 13.225 1.66675 9.99996 1.66675ZM9.99996 9.58342C8.84996 9.58342 7.91663 8.65008 7.91663 7.50008C7.91663 6.35008 8.84996 5.41675 9.99996 5.41675C11.15 5.41675 12.0833 6.35008 12.0833 7.50008C12.0833 8.65008 11.15 9.58342 9.99996 9.58342Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>