<template>
  <svg 
    width="20" 
    height="20" 
    viewBox="0 0 90 80" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
  <path 
    d="M52 32C52 23.16 44.84 16 36 16C27.16 16 20 23.16 20 32C20 40.84 27.16 48 36 48C44.84 48 52 40.84 52 32ZM60 40V48H72V60H80V48H92V40H80V28H72V40H60ZM4 72V80H68V72C68 61.36 46.68 56 36 56C25.32 56 4 61.36 4 72Z" 
    :stroke="color"
    :fill="color"
    stroke-width="1.4"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>


