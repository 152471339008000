<template>
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.737 8.16354L12.4034 14.7249C11.8379 15.3108 10.8577 14.9007 10.8577 14.061V10.3116H5.73044C5.22903 10.3116 4.82564 9.89372 4.82564 9.37427V5.6249C4.82564 5.10545 5.22903 4.68755 5.73044 4.68755H10.8577V0.938175C10.8577 0.102376 11.8341 -0.311617 12.4034 0.274223L18.737 6.83563C19.0877 7.20276 19.0877 7.79641 18.737 8.16354ZM7.23845 14.5297V12.9674C7.23845 12.7097 7.03487 12.4988 6.78605 12.4988H3.61923C2.95193 12.4988 2.41282 11.9403 2.41282 11.249V3.75021C2.41282 3.05892 2.95193 2.50042 3.61923 2.50042H6.78605C7.03487 2.50042 7.23845 2.28951 7.23845 2.03174V0.469503C7.23845 0.211733 7.03487 0.000830666 6.78605 0.000830666H3.61923C1.62111 0.000830666 0 1.68024 0 3.75021V11.249C0 13.3189 1.62111 14.9983 3.61923 14.9983H6.78605C7.03487 14.9983 7.23845 14.7874 7.23845 14.5297Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: ["color"],
};
</script>
