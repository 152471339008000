<template>
  <div class="main-layout">
    <div class="px-5 content-set">
      <div class="card--payment">
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <img :src="require('../assets/images/ilustrations/IluSuccessPaid.png')" height="170" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <strong class="subheading"
              >{{ $t("use_test_has_been_processed") }} ✨</strong
            >
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <p class="content text-center">
              {{ $t("congrats_use_test") }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <localized-link to="/patient/profile?tab=ORDER">
              <c-button rounded shadow bold>
                {{ $t("view_order") }}
              </c-button>
            </localized-link>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <localized-link to="/">
              <icons icon="house" color="#00a4b3" />
            </localized-link>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import Icons from "@/components/elements/Icons.vue";
import CButton from "@/components/v2/button/index.vue";

export default {
  name: "Use Success",
  components: {
    Icons,
    CButton,
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/custom.scss";

.card--payment {
  padding: 32px 15px;
  border-radius: 16px;
}

.header {
  padding: 30px 0 10px 0;
}
.subheading {
  color: rgba(121, 196, 198, 1);
  font-size: 18px;
  margin-top: 40px;
}
.rp {
  color: $gray_2;
  font-size: 18px;
  margin-top: 40px;
}
.to-home {
  color: $gray_2;
  font-size: 18px;
}
.content {
  font-size: 16px;
  margin-top: 30px;
  font-weight: 500;
  color: rgba(123, 140, 174, 1);
}
.content-amount {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  color: rgba(123, 140, 174, 1);
}
.content-set {
  padding: 10px 0 60px 0;
}
.amount {
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  padding: 15px 40px;
  border-radius: 15px;
}
.btn-view-order {
  color: #fff !important;
  background: radial-gradient(
    118.75% 157.07% at 34.74% -18.75%,
    #5278c7 0%,
    $blue_1 100%
  );
  box-shadow: -4.74667px 8px 30px rgba(35, 63, 120, 0.25);
  border-radius: 12px;
  height: 45px;
  margin-bottom: 15px;
}
a {
  text-decoration: none;
}
</style>
