var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",class:{
    'card--primary': _vm.primary,
    'card--rounded': _vm.rounded,
    'card--rounded-md': _vm.roundedMd,
    'card--shadow': _vm.shadow,
    'card--border': _vm.border,
    'card--border-blue': _vm.borderBlue,
    'card--border-main': _vm.borderMain,
    'card--badges': _vm.badges,
    'card--badges--success': _vm.badgesSuccess,
    'card--badges--warning': _vm.badgesWarning,
    'card--badges--danger': _vm.badgesDanger,
  }},[_vm._t("header"),_vm._t("body"),_vm._t("footer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }