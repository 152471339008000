<!-- start modal popup order detail (dialog_order)-->
<template>
  <v-row justify="center">
    <v-dialog
      v-model="is_show"
      content-class="dialogorderdetail"
      width="1000px"
    >
      <v-card class="pt-5">
        <v-card-title class="px-3 px-md-5">
          <v-row dense>
            <v-col cols="6" md="6" no-gutters class="ma-0 pa-0">
              <span class="text-h5">{{ $t("invoice") }}</span>
              <br />
              <template v-if="dataDialogInvoice.order_number">
                <v-btn
                  text
                  small
                  color="#FFF"
                  @click="openReceipt(dataDialogInvoice.order_number)"
                  class="btn-download-report"
                >
                  <v-icon left dark> mdi-download </v-icon>
                  {{ $t("download") }} PDF
                </v-btn>
              </template>
            </v-col>
            <v-col cols="6" md="6" no-gutters class="ma-0 pa-0">
              <img
                src="/iconVirtu.png"
                alt="Icon Virtu"
                class="float-right dialogvirtulogo"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-3 px-md-5">
          <v-row class="pt-5">
            <v-col cols="12">
              <h3 class="font-black">{{ $t("document_information") }}</h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="super-dense" mobile-breakpoint="0">
                      <template v-slot:default>
                        <tbody class="no-pad tbl">
                          <tr class="grey-color head-vir2">
                            <th class="text-center tb-wide2">
                              {{ $t("field.invoice_no") }}
                            </th>
                            <th class="text-center tb-wide2">
                              {{ $t("field.invoice_date") }}
                            </th>
                            <th class="text-center tb-wide3">
                              {{ $t("field.branch_name") }}
                            </th>
                            <!--
                            <th class="text-center tb-wide desktop" style="background: white; border: 1px solid;" v-if="dataDialogInvoice.data.metadata.booking_code !== null" rowspan="6">
                              <qrcode-vue
                                  :value="dataDialogInvoice.data.metadata.booking_code"
                                  :size="sizes"
                                  level="H"
                                ></qrcode-vue>
                            </th>
                            -->
                          </tr>
                          <tr class="hover-apri">
                            <td class="text-center">
                              {{
                                dataDialogInvoice.document_info.invoice_number
                              }}
                            </td>
                            <td class="text-center text-uppercase">
                              {{
                                formatDate(
                                  dataDialogInvoice.document_info.invoice_date,
                                  "DD MMM YYYY"
                                )
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                dataDialogInvoice.document_info.plant_name ==
                                "IN SELECTION"
                                  ? "-"
                                  : dataDialogInvoice.document_info.plant_name
                              }}
                            </td>
                          </tr>

                          <tr class="grey-color head-vir2">
                            <th class="text-center tb-wide2">
                              {{ $t("field.medical_record_no") }}
                            </th>
                            <th class="text-center tb-wide">
                              {{ $t("field.primary_doctor") }}
                            </th>
                            <th class="text-center">
                              {{ $t("field.payment_method") }}
                            </th>
                          </tr>
                          <tr class="hover-apri">
                            <td class="text-center">
                              {{
                                dataDialogInvoice.document_info
                                  .medical_record_number
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                dataDialogInvoice.document_info
                                  .practitioner_name != null
                                  ? dataDialogInvoice.document_info
                                      .practitioner_name
                                  : "-"
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                dataDialogInvoice.document_info.payment_method
                              }}
                            </td>
                          </tr>

                          <tr class="grey-color head-vir2">
                            <th class="text-center">
                              {{ $t("field.gender") }}
                            </th>
                            <th class="text-center">
                              {{ $t("field.currency") }}
                            </th>
                            <th class="text-center">
                              {{ $t("page.cart.total_amount") }}
                            </th>
                          </tr>
                          <tr class="hover-apri">
                            <td class="text-center">
                              {{ dataDialogInvoice.document_info.gender }}
                            </td>
                            <td class="text-center">
                              {{ dataDialogInvoice.document_info.currency }}
                            </td>
                            <td class="text-center">
                              {{ formatPrice(dataDialogInvoice.grand_total) }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>
        <!--
         <v-card-text class="mobile">
          <v-col
            cols="12"
            class="d-flex align-center justify-center"
            style="padding-top: 15px; padding-bottom: 0"
          >
            <qrcode-vue
              :value="dataDialogInvoice.data.metadata.booking_code"
              :size="sizes"
              level="H"
            ></qrcode-vue>
          </v-col>
        </v-card-text>
        -->
        <v-card-text class="px-3 px-md-5">
          <v-row>
            <v-col cols="12">
              <h3 class="font-black">{{ $t("cutomer_information") }}</h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="no-hover-table super-dense">
                      <template v-slot:default>
                        <tbody class="no-pad desktop">
                          <tr class="hover-apri">
                            <td class="font-weight-bold">
                              {{ dataDialogInvoice.customer_info.name }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.mobile_phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogInvoice.customer_info
                                          .mobile_phone !== null
                                          ? dataDialogInvoice.customer_info
                                              .mobile_phone
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td class="text-uppercase">
                              {{
                                `${
                                  dataDialogInvoice.customer_info.address !==
                                  null
                                    ? dataDialogInvoice.customer_info.address
                                    : "-"
                                }`
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogInvoice.customer_info
                                          .home_contact !== null
                                          ? dataDialogInvoice.customer_info
                                              .home_contact
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              {{
                                dataDialogInvoice.customer_info.district_city
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.email") }}</td>
                                  <td class="text-uppercase">
                                    :
                                    {{
                                      `${
                                        dataDialogInvoice.customer_info
                                          .email !== null
                                          ? dataDialogInvoice.customer_info
                                              .email
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              {{
                                dataDialogInvoice.customer_info.postal_province
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.dob") }}</td>
                                  <td class="text-uppercase">
                                    :{{
                                      formatDate(
                                        dataDialogInvoice.customer_info.dob,
                                        "DD MMM YYYY"
                                      )
                                    }}
                                    | {{ dataDialogInvoice.customer_info.age }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                        </tbody>
                        <!-- start mobile part -->
                        <tbody class="no-pad mobile">
                          <tr class="hover-apri">
                            <td class="font-weight-bold">
                              {{ dataDialogInvoice.customer_info.name }}
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td class="text-uppercase">
                              {{
                                `${
                                  dataDialogInvoice.customer_info.address !==
                                  null
                                    ? dataDialogInvoice.customer_info.address
                                    : "-"
                                }`
                              }}
                            </td>
                          </tr>

                          <tr class="hover-apri">
                            <td>
                              {{
                                `${dataDialogInvoice.customer_info.district_city}`
                              }}
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              {{
                                `${dataDialogInvoice.customer_info.postal_province}`
                              }}
                            </td>
                          </tr>
                          <!-- <tr class="hover-apri">
                            <td class="font-weight-bold">
                              {{ $t("field.medical_record_no") }} :
                              {{
                                dataDialogInvoice.data.metadata
                                  .medical_record_number
                              }}
                            </td>
                          </tr> -->
                          <tr class="hover-apri">
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.mobile_phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogInvoice.customer_info
                                          .mobile_phone !== null
                                          ? dataDialogInvoice.customer_info
                                              .mobile_phone
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        dataDialogInvoice.customer_info
                                          .home_contact !== null
                                          ? dataDialogInvoice.customer_info
                                              .home_contact
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.email") }}</td>
                                  <td class="text-uppercase">
                                    :
                                    {{
                                      `${
                                        dataDialogInvoice.customer_info
                                          .email !== null
                                          ? dataDialogInvoice.customer_info
                                              .email
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr class="hover-apri">
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.dob") }}</td>
                                  <td class="text-uppercase">
                                    :

                                    {{ dataDialogInvoice.customer_info.dob }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>
        <v-card-text class="px-3 px-md-5">
          <v-row>
            <v-col cols="12">
              <h3 class="font-black">{{ $t("invoice_detail") }}</h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="table-sticky super-dense">
                      <template v-slot:default>
                        <thead class="no-pad">
                          <tr class="grey-color head-vir2">
                            <th class="text-center tb-wide">
                              {{ $t("field.product_code") }}
                            </th>
                            <th class="text-center tb-wide">
                              {{ $t("field.description") }}
                            </th>
                            <th class="text-center">{{ $t("field.uom") }}</th>
                            <th class="text-center">{{ $t("field.qty") }}</th>
                            <th
                              class="text-center"
                              style="min-width: 125px !important"
                            >
                              {{ $t("field.unit_price") }}
                            </th>
                            <th class="text-center">
                              {{ $t("field.disc") }}(%)
                            </th>
                            <th class="text-center">{{ $t("field.disc") }}</th>
                            <th class="text-center">{{ $t("field.payer") }}</th>
                            <th class="text-center">
                              {{ $t("field.total_amount") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody class="no-pad">
                          <tr
                            v-for="(item, index) in dataDialogInvoice.tables"
                            :key="index"
                            :class="{
                              'grey-color':
                                item.type == 'category' ||
                                item.type == 'name' ||
                                item.type == 'sub_category',
                              'hover-apri': item.type == 'item',
                            }"
                          >
                            <td
                              v-if="item.type == 'name'"
                              colspan="9"
                              class="bold patient-name text-uppercase"
                            >
                              {{ $t("field.patient") }} : {{ item.name }}
                            </td>
                            <td
                              v-else-if="item.type == 'category'"
                              colspan="9"
                              class="category-blue"
                            >
                              {{ item[`category_${$i18n.locale}`] }}
                            </td>
                            <td
                              v-else-if="item.type == 'sub_category'"
                              colspan="9"
                              class="bold pl-sub"
                            >
                              {{ item[`sub_category_${$i18n.locale}`] }}
                            </td>
                            <template v-else>
                              <td class="text-left td-custom">
                                {{ item.product.product_code }}
                              </td>
                              <td class="text-left">
                                {{ item.product.product_name }}
                              </td>
                              <td class="text-center">-</td>
                              <td class="text-right">
                                {{ item.product.product_qty }}
                              </td>
                              <td class="text-right">
                                {{
                                  formatPrice(
                                    item.product.product_price /
                                      item.product.product_qty
                                  )
                                }}
                              </td>
                              <td class="text-right">
                                {{ formatDisc(item.product.product_discount) }}
                              </td>
                              <td class="text-right">
                                {{
                                  formatPrice(
                                    (item.product.product_discount / 100) *
                                      item.product.product_price
                                  )
                                }}
                              </td>
                              <td class="text-center">-</td>
                              <td class="text-right">
                                {{
                                  formatPrice(
                                    item.product.product_price -
                                      (item.product.product_discount / 100) *
                                        item.product.product_price
                                  )
                                }}
                              </td>
                            </template>
                          </tr>
                        </tbody>
                        <!--
                        <tbody class="no-pad">
                          <tr
                            v-for="(item, index) in dataDialogInvoice.list"
                            :key="index"
                            :class="{
                              'grey-color': item.type == 'header',
                              'hover-apri': item.type == 'test',
                            }"
                          >
                          <td class="text-left td-custom" >
                            {{item.code}}
                          </td>
                          <td class="text-left">
                            {{item.name}}
                          </td>
                          <td class="text-center">
                            -
                          </td>
                          <td class="text-right">
                            {{item.qty}}
                          </td>
                          <td class="text-right">
                            {{ formatPrice(item.amount)}}
                          </td>
                          <td class="text-right">
                            {{item.discount_percent}}%
                          </td>
                          <td class="text-right">
                            {{formatPrice(item.discount_amount)}}
                          </td>
                          <td class="text-center">
                            -
                          </td>
                          <td class="text-right">
                            {{formatPrice(item.sub_total)}}
                          </td>
                          </tr>
                        </tbody>
                        -->
                        <tfoot>
                          <tr class="font-weight-bold">
                            <td colspan="5"></td>
                            <td colspan="3">
                              {{ $t("page.cart.total_price") }}
                            </td>
                            <td class="text-right">
                              {{ formatPrice(dataDialogInvoice.amount_total) }}
                            </td>
                          </tr>
                          <tr class="font-weight-bold">
                            <td colspan="5"></td>
                            <td colspan="3">
                              {{ $t("page.cart.discount") }} ({{
                                dataDialogInvoice.document_info.currency
                              }})
                            </td>
                            <td class="text-right">
                              {{
                                formatPrice(dataDialogInvoice.price_discount)
                              }}
                            </td>
                          </tr>
                          <tr class="font-weight-bold">
                            <td colspan="5"></td>
                            <td colspan="3">
                              {{ $t("page.cart.grand_total") }}
                            </td>
                            <td class="text-right">
                              {{ formatPrice(dataDialogInvoice.grand_total) }}
                            </td>
                          </tr>
                        </tfoot>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary darken-1" text @click="toggleDialog()">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import Card from "@/components/v2/card/index.vue";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
//import QrcodeVue from "qrcode.vue";
// import icons from "@/components/elements/Icons.vue";

export default {
  components: {
    Card,
    //QrcodeVue,
    // icons,
  },
  props: {
    tampilkan: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => [],
    },
    tableFilter: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      is_show: this.tampilkan,
      dataDialogInvoice: this.item,
    };
  },
  watch: {
    tampilkan: function (new_val, old_val) {
      if (old_val !== "") {
        this.is_show = new_val;
      }
    },
    is_show: function (new_val, old_val) {
      if (old_val !== "") {
        if (!new_val) {
          this.$emit("toggleDialog", this.is_show);
        }
      }
    },
  },
  // mounted(){
  //   console.log(this.dataDialogInvoice,'assaasds')
  //   console.log(this.tableFilter,'filter')
  // },
  methods: {
    openReceipt(item) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}invoices/get-printout-homecare?order_number=${item}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        this.preview = res;

        window.open(this.preview.linkDownload, "_blank");
      });
    },
    formatPrice(price) {
      return `${Intl.NumberFormat().format(price)}.00`;
    },
    formatDisc(discount) {
      return Math.round(discount * 100) / 100 == 0
        ? `0%`
        : (Math.round(discount * 100) / 100).toFixed(2) + "%";
    },
    formatDateTime(par) {
      if (!par) return null;
      return moment(par)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY HH:mm:ss");
    },
    formatDate(date, format) {
      return moment(date).locale(this.$i18n.locale).format(format);
    },
    toggleDialog() {
      this.is_show = false;
      this.$emit("toggleDialog", this.is_show);
    },
    downloadLab(param) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}diagnosis/get-labreport?order_number=${param}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        this.download = res;
        window.open(this.download.linkDownload, "_blank");
      });
    },
    downloadMcu(param) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}diagnosis/get-mcureport?order_number=${param}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        this.download = res;
        window.open(this.download.linkDownload, "_blank");
      });
    },
    formaterText(param) {
      let result = param.substr(0, 1);
      if (result == "[") {
        let newVal = JSON.parse(param);
        let newValues = newVal[0].reference_text;

        return newValues;
      } else {
        return param;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.pl-sub {
  padding-left: 3% !important;
}
.grey-color {
  background: #eeeeee;
}

.head-vir {
  background: #1a428a;
  color: #fff;
}

.head-vir2 {
  background: #cecece;
  font-weight: bold;
}

.head-vir2:hover {
  background: #cecece !important;
}

.head-vir2 th {
  color: #000 !important;
}

.bold {
  font-weight: bold;
}

// .hover-apri:hover {
//   background: rgba(113, 188, 225, 0.1) !important;
// }
// .hover-apri:hover td {
//   font-weight: bold;
// }

.v-data-table table tr td {
  font-size: 12px !important;
}

.mobile {
  display: none;
}

@media (max-width: 450px) {
  .dialogvirtu.v-dialog {
    margin: 5px !important;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  .dialogvirtulogo {
    width: 150px;
  }
}
</style>
<style lang="scss">
@media (max-width: 450px) {
  .dialogorderdetail.v-dialog {
    margin: 5px !important;
  }
  .dialogorderdetail .v-card__text {
    padding: 0 10px 20px !important;
  }
  .td-custom {
    width: 100% !important;
  }
}
.font-black {
  color: #000 !important;
}
.category-black {
  color: #fff !important;
  background: #000 !important;
}
.category-blue {
  color: #fff !important;
  background: #233e8d !important;
}
.patient-name {
  color: black;
  background: #cae9f7 !important;
}
.btn-download-report {
  background: #209fb4;
}

.table-inside {
  width: 100%;
  padding: 0;
}

.table-inside tr td:first-child {
  width: 20%;
}

.mobile tr {
  display: inline-table;
  width: 100%;
}

.mobile .table-inside tr td:first-child {
  width: 20%;
}

.td-custom {
  width: 20% !important;
}

@media (min-width: 600px) {
  .table-sticky .v-data-table__wrapper {
    overflow: unset;
  }
  .table-sticky thead {
    position: sticky;
    inset-block-start: 0;
    top: 0;
  }
}
@media (max-width: 599px) {
  .table-sticky .v-data-table__wrapper {
    overflow-x: auto;
    overflow-y: auto;
    height: 400px;
  }
  .table-sticky thead tr {
    border-bottom: 0 !important;
    position: sticky;
    top: 0;
    z-index: 2;
  }
}
.popup {
  padding: 8px;
  background: white;
  text-transform: uppercase;
  font-size: 12px !important;
  line-height: 16px;
  a {
    text-decoration: none;
    color: black;
  }
}
.title-grid {
  display: grid;
}
.title-grid-h {
  text-align: left;
}
.title-grid-c {
  text-align: left;
  font-size: 8px;
}
.mt5 {
  margin-top: 5px !important;
}
.mb10 {
  margin-bottom: -10px;
}
.mb5 {
  margin-bottom: 5px !important;
}
</style>
