<template>
  <div class="box-alert">
    <v-alert
      :type="alert.type"
      v-for="(alert, index) of alertData"
      :key="index"
    >
      {{ alert.message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  data: () => ({
    alertData: [],
  }),
};
</script>

<style lang="scss" scoped>
.box-alert {
  position: absolute;
  bottom: 0;
  max-height: 220px;
  left: 12px;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  z-index: 9999;
}
</style>
