import login from "./login.json";
import profile from "./profile.json";
import home from "./home.json";
import buy_package from "./buy_package.json";
import lab_test from "./lab_test.json";
import my_order from "./my_order.json";
import nearme from "./nearme.json";
import office_care from "./office_care.json";
import promo from "./promo.json";
import cart from "./cart.json";
import register from "./register.json";
import employee from "./employee.json";
import logout from "./logout.json";
import referral from "./referral.json";
import setting from "./setting.json";

export default {
  login,
  profile,
  home,
  buy_package,
  lab_test,
  my_order,
  nearme,
  office_care,
  promo,
  cart,
  register,
  employee,
  logout,
  referral,
  setting,
};
