<template>
  <div class="loading">
    <div class="d-flex flex-column align-center justify-center pb-5">
      <img :src="require('../../assets/logoDigiLab.png')" height="25" />
      <icons icon="loading" class="mt-n2"/>
    </div>
  </div>
</template>

<script>
import Icons from "../elements/Icons";
export default {
  components: { Icons },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/custom.scss";
.loading {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $main_1;
}
</style>
