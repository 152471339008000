<template>
<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 1.59222V17.6171H19.0186V1.59222H0ZM17.154 15.7537H2.23748V4.94626H17.154V15.7537Z" :fill="color"/>
<path d="M6.71243 6.43695H3.72913V9.41832H6.71243V6.43695Z" :fill="color"/>
<path d="M11.1874 6.43695H8.20407V9.41832H11.1874V6.43695Z" :fill="color"/>
<path d="M15.2894 6.43695H12.3061V9.41832H15.2894V6.43695Z" :fill="color"/>
<path d="M6.71243 10.909H3.72913V13.8904H6.71243V10.909Z" :fill="color"/>
<path d="M11.1874 10.909H8.20407V13.8904H11.1874V10.909Z" :fill="color"/>
<path d="M15.2894 10.909H12.3061V13.8904H15.2894V10.909Z" :fill="color"/>
<path d="M2.98331 2.71026C2.6104 2.71026 2.23749 2.33759 2.23749 1.96492V0.84691C2.23749 0.474239 2.6104 0.101569 2.98331 0.101569C3.35623 -0.271102 3.72914 0.474239 3.72914 0.84691V1.96492C3.72914 2.33759 3.35623 2.71026 2.98331 2.71026Z" :fill="color"/>
<path d="M16.0353 2.71026C15.6623 2.71026 15.2894 2.33759 15.2894 1.96492V0.846904C15.2894 0.474233 15.6623 0.101562 16.0353 0.101562C16.4082 0.101562 16.7811 0.474233 16.7811 0.846904V1.96492C17.154 2.33759 16.7811 2.71026 16.0353 2.71026Z" :fill="color"/>
</svg>
</template>

<script>
export default {
  props: ["color"],
};
</script>