<template>
  <div>
    <IconLoading v-if="icon == 'loading'" class="loading-icon" />
    <home v-if="icon == 'home'" :color="color" />
    <summary-reports v-if="icon == 'summary-reports'" :color="color" />
    <user v-if="icon == 'user'" :color="color" />
    <user-filled v-if="icon == 'user-filled'" :color="color" />
    <user-plus v-if="icon == 'user-plus'" :color="color" />
    <patient v-if="icon == 'patient'" :color="color" />
    <email v-if="icon == 'email'" :color="color" />
    <sign-out v-if="icon == 'sign-out'" :color="color" />
    <toggle v-if="icon == 'toggle'" :color="color" />
    <setting v-if="icon == 'setting'" :color="color" />
    <bell v-if="icon == 'bell'" :color="color" />
    <homecare v-if="icon == 'homecare'" :color="color" />
    <icv2-marker v-if="icon == 'marker'" :color="color" />
    <office v-if="icon == 'office'" :color="color" />
    <test v-if="icon == 'test'" :color="color" />
    <voucher v-if="icon == 'voucher'" :color="color" />
    <house v-if="icon == 'house'" :color="color" />
    <cart v-if="icon == 'cart'" :color="color" />
    <search v-if="icon == 'search'" :color="color" />
    <marker-mylocation v-if="icon == 'marker-mylocation'" :color="color" />
    <arrow-back v-if="icon == 'arrow-back'" :color="color" />
    <arrow-down v-if="icon == 'arrow-down'" :color="color" />
    <user-office v-if="icon == 'user-office'" :color="color" />
    <user-log v-if="icon == 'user-log'" :color="color" />
    <billing v-if="icon == 'billing'" :color="color" />
    <transaction v-if="icon == 'transaction'" :color="color" />
    <my-package v-if="icon == 'my-package'" :color="color" />
    <triangle-up v-if="icon == 'triangle-up'" :color="color" />
    <triangle-down v-if="icon == 'triangle-down'" :color="color" />
    <date v-if="icon == 'date'" :color="color" />
    <weight v-if="icon == 'weight'" :color="color" />
    <maps v-if="icon == 'maps'" :color="color" />
    <height v-if="icon == 'height'" :color="color" />
    <icon-simple-profile v-if="icon == 'simple-profile'" />
    <icon-simple-booking v-if="icon == 'simple-booking'" />
    <icon-simple-reschedule v-if="icon == 'simple-reschedule'" />
    <icon-simple-result v-if="icon == 'simple-result'" />
    <icon-EN v-if="icon == 'lang-en'" />
    <icon-ID v-if="icon == 'lang-id'" />
    <icon-TH v-if="icon == 'lang-th'" />
    <icon-PH v-if="icon == 'lang-ph'" />
    <icon-MS v-if="icon == 'lang-ms'" />
    <icon-test-microscope v-if="icon == 'icon-microscope'" />
    <icon-test-heart v-if="icon == 'icon-heart'" />
    <icon-test-blood v-if="icon == 'icon-blood'" />
    <icon-test-cart v-if="icon == 'icon-cart'" />
    <icon-test-cart-lg v-if="icon == 'icon-cart-lg'" />
    <icon-filter v-if="icon == 'filter'" :color="color" />
    <icon-pin-location v-if="icon == 'pin-location'" :color="color" />
    <icon-test-microscope-lg v-if="icon == 'icon-microscope-lg'" />
    <icon-test-heart-lg v-if="icon == 'icon-heart-lg'" />
    <icon-test-blood-lg v-if="icon == 'icon-blood-lg'" />
    <icon-arrow-collapse v-if="icon == 'icon-arrow-collapse'" color="#1F3C87" />
    <icon-virtu v-if="icon == 'virtu'" />
    <icon-copy v-if="icon == 'icon-copy'" />
    <icon-promo v-if="icon == 'icon-promo'" :color="color" />
    <icon-test-cart-blue v-if="icon == 'icon-cart-blue'" />
    <icon-my-booking v-if="icon == 'icon-booking'" :color="color" />
    <icon-download v-if="icon == 'icon-download'" :color="color" />
    <icon-share v-if="icon == 'icon-share'" :color="color" />
    <icon-history v-if="icon == 'icon-history'" :color="color" />
    <icon-verified v-if="icon == 'icon-verified'" :color="color" />
    <icon-gear v-if="icon == 'icon-gear'" :color="color" />
    <icon-birth v-if="icon == 'icon-birth'" :color="color" />
    <icon-doctor v-if="icon == 'icon-doctor'" :color="color" />
    
  </div>
</template>

<script>
import IconLoading from "./IconLoading.vue";
import Home from "./icv2_home.vue";
import IconSimpleProfile from "./IconSimpleProfile.vue";
import IconSimpleBooking from "./IconSimpleBookingProcess.vue";
import IconSimpleReschedule from "./IconSimpleReschedule.vue";
import IconSimpleResult from "./IconSimpleResult.vue";
import SummaryReports from "./icv2_summary_report.vue";
import User from "./icv2_user.vue";
import UserFilled from "./icv2_user_filled.vue";
import UserPlus from "./icv2_user_plus.vue";
import Patient from "./icv2_patient.vue";
import Email from "./icv2_email.vue";
import Toggle from "./icv2_toggle.vue";
import Setting from "./icv2_setting.vue";
import Bell from "./icv2_bell.vue";
import Homecare from "./icv2_homecare.vue";
import icv2Marker from "./icv2_marker.vue";
import Office from "./icv2_office.vue";
import SignOut from "./icv2_signOut.vue";
import Test from "./icv2_test.vue";
import Voucher from "./icv2_voucher.vue";
import House from "./icv2_house.vue";
import Cart from "./icv2_cart.vue";
import Search from "./icv2_search.vue";
import MarkerMylocation from "./icv2_marker_mylocation.vue";
import ArrowBack from "./icv2_arrow-back.vue";
import ArrowDown from "./icv2_arrow-down.vue";
import UserOffice from "./icv2_user_office.vue";
import UserLog from "./icv2_user_log.vue";
import Billing from "./icv2_billing.vue";
import Transaction from "./icv2_transaction.vue";
import MyPackage from "./icv2_mypackage.vue";
import TriangleUp from "./icv2_triangle_up.vue";
import TriangleDown from "./icv2_triangle_down.vue";
import Date from "./icv2_date.vue";
import Weight from "./icv2_weight.vue";
import Maps from "./icv2_maps.vue";
import Height from "./icv2_height.vue";
import IconEN from "./IconEN.vue";
import IconID from "./IconID.vue";
import IconPH from "./IconPH.vue";
import IconTH from "./IconTH.vue";
import IconMS from "./IconMS.vue";
import IconTestMicroscope from "./icv2_test_microscope.vue";
import IconTestHeart from "./icv2_test_heart.vue";
import IconTestBlood from "./icv2_test_blood.vue";
import IconTestCart from "./icv2_test_cart.vue";
import IconTestCartLg from "./icv2_test_cart_lg.vue";
import IconFilter from "./IconFilter.vue";
import IconPinLocation from "./IconPinLocation.vue";
import IconTestMicroscopeLg from "./icv2_test_microscope_lg.vue";
import IconTestHeartLg from "./icv2_test_heart_lg.vue";
import IconTestBloodLg from "./icv2_test_blood_lg.vue";
import IconArrowCollapse from "./icv2_arrow_collapse.vue";
import IconVirtu from "./icv2_virtu.vue";
import IconCopy from "./icv2_copy.vue";
import IconPromo from "./IconPromo.vue";
import IconTestCartBlue from "./icv2_test_cart_blue.vue";
import IconMyBooking from "./IconMyBooking.vue";
import IconHistory from "./icv2_history.vue";
import IconVerified from "./icv2_verified.vue";
import IconDownload from "./icv2_download.vue";
import IconShare from "./icv2_share.vue";
import IconGear from "./icv2_gear.vue";
import IconBirth from "./IconDateOfBirth.vue";
import IconDoctor from "./IconDoctor.vue";

export default {
  name: "icons",
  components: {
    IconLoading,
    Home,
    SummaryReports,
    User,
    UserFilled,
    UserPlus,
    Patient,
    Email,
    Toggle,
    Setting,
    Bell,
    Homecare,
    icv2Marker,
    Office,
    Test,
    Voucher,
    House,
    Cart,
    Search,
    MarkerMylocation,
    ArrowBack,
    ArrowDown,
    UserOffice,
    UserLog,
    Billing,
    Transaction,
    MyPackage,
    TriangleUp,
    Date,
    Weight,
    Maps,
    Height,
    TriangleDown,
    IconSimpleProfile,
    IconSimpleBooking,
    IconSimpleReschedule,
    IconSimpleResult,
    IconEN,
    IconID,
    IconPH,
    IconTH,
    IconMS,
    SignOut,
    IconTestMicroscope,
    IconTestHeart,
    IconTestBlood,
    IconTestCart,
    IconTestCartLg,
    IconFilter,
    IconPinLocation,
    IconTestMicroscopeLg,
    IconTestHeartLg,
    IconTestBloodLg,
    IconArrowCollapse,
    IconVirtu,
    IconCopy,
    IconPromo,
    IconTestCartBlue,
    IconMyBooking,
    IconDownload,
    IconShare,
    IconHistory,
    IconVerified,
    IconGear,
    IconBirth,
    IconDoctor
  },
  props: {
    icon: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#C2CFE0",
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-icon {
  height: 50px;
  transform: translateX(12px);
}
</style>
