<!-- start modal popup order detail (dialog_order)-->
<template>
    <v-row justify="center">
      <v-dialog
        v-model="is_show"
        content-class="dialogorderdetail"
        width="450px"
      >
        <v-card class="pt-2 pb-1">
            <v-card-title class="px-3 px-md-5">
                <v-row dense>
                    <v-col cols="12" md="6" no-gutters class="ma-0 pa-0">
                        <span class="title-head">{{ $t("invoice_detail") }} </span>    
                    </v-col>
                </v-row>
            </v-card-title> 
            <v-divider></v-divider> 
            <v-card-title class="padding-title">
                <v-row dense>
                    <v-col cols="5"  no-gutters class="ma-0 pa-0">
                        <span class="title-content">{{ $t("field.name") }} </span>   
                    </v-col>
                    <v-col cols="7"  no-gutters class="ma-0 pa-0">
                        <span class="title-content-value">
                            {{ dataDialogInvoice.data.metadata.patient_name }}
                        </span>   
                    </v-col>
                </v-row>
            </v-card-title> 
            <v-divider></v-divider> 
            <v-card-title class="padding-title">
                <v-row dense>
                    <v-col cols="5"  no-gutters class="ma-0 pa-0">
                        <span class="title-content">{{ $t("field.address") }} </span>   
                    </v-col>
                    <v-col cols="7"  no-gutters class="ma-0 pa-0">
                        <span class="title-content-value">
                            {{ dataDialogInvoice.data.metadata.address }} {{ dataDialogInvoice.data.metadata.city_name }}, 
                            {{ dataDialogInvoice.data.metadata.district_name }} {{ dataDialogInvoice.data.metadata.province_name }} {{ dataDialogInvoice.data.metadata.postal_code }},
                            {{ dataDialogInvoice.data.metadata.country }} 
                        </span>   
                    </v-col>
                </v-row>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-title class="padding-title">
                <v-row dense>
                    <v-col cols="5"  no-gutters class="ma-0 pa-0">
                        <span class="title-content">{{ $t("field.phone") }} </span>   
                    </v-col>
                    <v-col cols="7"  no-gutters class="ma-0 pa-0">
                        <span class="title-content-value">
                            {{ 
                               `${
                                    dataDialogInvoice.data.metadata
                                    .phone !== null
                                    ? dataDialogInvoice.data.metadata
                                        .phone
                                    : "-"
                                }`
                            }}
                        </span>   
                    </v-col>
                </v-row>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-title class="padding-title">
                <v-row dense>
                    <v-col cols="5"  no-gutters class="ma-0 pa-0">
                        <span class="title-content">{{ $t("field.mobile_phone") }} </span>   
                    </v-col>
                    <v-col cols="7"  no-gutters class="ma-0 pa-0">
                        <span class="title-content-value">
                            {{ 
                               `${
                                    dataDialogInvoice.data.metadata
                                    .mobile_phone !== null
                                    ? dataDialogInvoice.data.metadata
                                        .mobile_phone
                                    : "-"
                                }`
                            }}
                        </span>   
                    </v-col>
                </v-row>
            </v-card-title> 

            <v-divider></v-divider>
            <v-card-title class="padding-title">
                <v-row dense>
                    <v-col cols="5"  no-gutters class="ma-0 pa-0">
                        <span class="title-content">{{ $t("field.email") }} </span>   
                    </v-col>
                    <v-col cols="7"  no-gutters class="ma-0 pa-0">
                        <span class="title-content-value">
                            {{
                                `${
                                    dataDialogInvoice.data.metadata
                                    .email !== null
                                    ? dataDialogInvoice.data.metadata.email.toUpperCase()
                                    : "-"
                                }`
                            }}
                        </span>   
                    </v-col>
                </v-row>
            </v-card-title> 
            <v-divider></v-divider> 

            <v-card-title class="padding-title">
                <v-row dense>
                    <v-col cols="5"  no-gutters class="ma-0 pa-0">
                        <span class="title-content">{{ $t("field.dob") }} </span>   
                    </v-col>
                    <v-col cols="7"  no-gutters class="ma-0 pa-0">
                        <span class="title-content-value">
                            {{
                                `${
                                    dataDialogInvoice.data.metadata
                                    .date_of_birth !== null
                                    ? dataDialogInvoice.data.metadata.date_of_birth.toUpperCase()
                                    : "-"
                                }`
                              }}
                              <br>
                              {{
                                `${
                                    dataDialogInvoice.data.metadata
                                    .age !== null
                                    ? dataDialogInvoice.data.metadata.age.toUpperCase()
                                    : "-"
                                }`
                            }}
                        </span>   
                    </v-col>
                </v-row>
            </v-card-title> 
            <v-divider></v-divider>
            
            
            <v-card-title class="padding-title">
                <v-row dense>
                    <v-col cols="5"  no-gutters class="ma-0 pa-0">
                        <span class="title-content">{{ $t("field.invoice_no") }} </span>   
                    </v-col>
                    <v-col cols="7"  no-gutters class="ma-0 pa-0">
                        <span class="title-content-value">{{ dataDialogInvoice.data.metadata.invoice_number }} </span>   
                    </v-col>
                </v-row>
            </v-card-title> 
            <v-divider></v-divider> 
            <v-card-title class="padding-title">
                <v-row dense>
                    <v-col cols="5"  no-gutters class="ma-0 pa-0">
                        <span class="title-content">{{ $t("field.invoice_date") }} </span>   
                    </v-col>
                    <v-col cols="7"  no-gutters class="ma-0 pa-0">
                        <span class="title-content-value">{{
                                  formatDate(
                                    dataDialogInvoice.data.metadata.invoice_date,
                                          "DD MMM YYYY"
                                        ).toUpperCase()
                                }}
                        </span>   
                    </v-col>
                </v-row>
            </v-card-title> 
            <v-divider></v-divider>   
            <v-card-title class="padding-title">
                <v-row dense>
                    <v-col cols="5"  no-gutters class="ma-0 pa-0">
                        <span class="title-content">{{ $t("field.branch_name") }} </span>   
                    </v-col>
                    <v-col cols="7"  no-gutters class="ma-0 pa-0">
                        <span class="title-content-value">
                            {{
                                dataDialogInvoice.data.metadata.plant_name == "IN SELECTION"? '-'
                                : dataDialogInvoice.data.metadata.plant_name
                            }}
                        </span>   
                    </v-col>
                </v-row>
            </v-card-title> 
            <v-divider></v-divider> 
            <v-card-title class="padding-title">
                <v-row dense>
                    <v-col cols="5"  no-gutters class="ma-0 pa-0">
                        <span class="title-content">{{ $t("field.medical_record_no") }} </span>   
                    </v-col>
                    <v-col cols="7"  no-gutters class="ma-0 pa-0">
                        <span class="title-content-value">
                            {{
                                dataDialogInvoice.data.metadata
                                .medical_record_number
                            }}
                        </span>   
                    </v-col>
                </v-row>
            </v-card-title> 
            <v-divider></v-divider> 
            <v-card-title class="padding-title">
                <v-row dense>
                    <v-col cols="5"  no-gutters class="ma-0 pa-0">
                        <span class="title-content">{{ $t("field.primary_doctor") }} </span>   
                    </v-col>
                    <v-col cols="7"  no-gutters class="ma-0 pa-0">
                        <span class="title-content-value">
                            {{
                                dataDialogInvoice.data.metadata
                                    .practitioner_name != null
                                    ? dataDialogInvoice.data.metadata
                                        .practitioner_name
                                    : "-"
                            }}
                        </span>   
                    </v-col>
                </v-row>
            </v-card-title> 
            <v-divider></v-divider> 
            <v-card-title class="padding-title">
                <v-row dense>
                    <v-col cols="5"  no-gutters class="ma-0 pa-0">
                        <span class="title-content">{{ $t("field.payment_method") }} </span>   
                    </v-col>
                    <v-col cols="7"  no-gutters class="ma-0 pa-0">
                        <span class="title-content-value">
                            {{
                                dataDialogInvoice.data.metadata.payment_method
                            }}
                        </span>   
                    </v-col>
                </v-row>
            </v-card-title> 
            <v-divider></v-divider> 

            <v-card-title class="padding-title">
                <v-row dense>
                    <v-col cols="5"  no-gutters class="ma-0 pa-0">
                        <span class="title-content">{{ $t("page.cart.grand_total") }} </span>   
                    </v-col>
                    <v-col cols="7"  no-gutters class="ma-0 pa-0">
                        <span class="title-content-value">
                            {{
                              dataDialogInvoice.data.metadata.currrency !=
                              null
                                ? dataDialogInvoice.data.metadata.currrency
                                : "-"
                            }}
                            {{
                               formatPrice(
                                    dataDialogInvoice.data.metadata.grand_total
                                )
                            }}
                        </span>   
                    </v-col>
                </v-row>
            </v-card-title> 
            
            <!-- footer -->
            <v-card-title class="px-3 px-md-5">
                <v-row dense>
                    <v-col cols="5"  no-gutters class="ma-0 pa-0">
                        <img
                        src="/iconVirtu.png"
                        alt="Icon Virtu"
                        width="90%"
                        style="padding-top:12px;"
                        />
                    </v-col>
                    <v-col cols="7"  no-gutters class="ma-0 pa-0">
                        <span class="title-content-footer">
                            {{ 
                                $t(`field.invoice_footer`) 
                            }} 
                        </span>
                    </v-col>
                </v-row>
            </v-card-title>
                           
            <v-card-title class="px-3 px-md-5 pb-3">
                <v-row dense>
                    <v-col cols="6" md="6" no-gutters class="ma-0 pa-0">
                        <template v-if="dataDialogInvoice.order_number">
                            <v-btn
                                text
                                small
                                color="#FFF"
                                @click="openReceipt(dataDialogInvoice.order_number)"
                                class="btn-download-report"
                                width="95%"
                            >
                                <v-icon left dark> mdi-download </v-icon>
                                {{ $t("download") }} PDF
                            </v-btn>
                        </template>
                    </v-col>
                    <v-col cols="6" md="6" no-gutters class="ma-0 pa-0" style="text-align:end;">
                        <v-btn
                            text
                            small
                            @click="is_show = false"
                            class="btn-close"
                            width="95%"
                            style="text-align:end"
                        >
                            {{ $t("close") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
         
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <script>
  import moment from "moment";
  //import Card from "@/components/v2/card/index.vue";
  import API from "@/service/api_service";
  import Auth from "@/service/auth_service";
  //import QrcodeVue from "qrcode.vue";
  // import icons from "@/components/elements/Icons.vue";
  
  export default {
    components: {
    //  Card,
      //QrcodeVue,
      // icons,
    },
    props: {
      tampilkan: {
        type: Boolean,
        default: false,
      },
      item: {
        type: Object,
        default: () => [],
      },
      tableFilter: {
        type: Array,
        default: () => [],
      },
    },
    data: function () {
      return {
        is_show: this.tampilkan,
        dataDialogInvoice: this.item,
      };
    },
    watch: {
      tampilkan: function (new_val, old_val) {
        if (old_val !== "") {
          this.is_show = new_val;
        }
      },
      is_show: function (new_val, old_val) {
        if (old_val !== "") {
          if (!new_val) {
            this.$emit("toggleDialog", this.is_show);
          }
        }
      },
    },
    methods: {
      openReceipt(item) {
        API.get(
          `${process.env.VUE_APP_API_TRUST}invoices/get-printout?order_number=${item}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then(res => {
          this.preview = res;
  
          window.open(this.preview.linkDownload, "_blank");
        });
      },
      formatPrice(price) {
        return `${Intl.NumberFormat().format(price)}.00`;
      },
      formatDateTime(par) {
        if (!par) return null;
        return moment(par)
          .locale(this.$i18n.locale)
          .format("DD-MMM-YYYY HH:mm:ss")
          .toUpperCase();
      },
      formatDate(date, format) {
        return moment(date).locale(this.$i18n.locale).format(format);
      },
      toggleDialog() {
        this.is_show = false;
        this.$emit("toggleDialog", this.is_show);
      },
      downloadLab(param) {
        API.get(
          `${process.env.VUE_APP_API_TRUST}diagnosis/get-labreport?order_number=${param}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then(res => {
          this.download = res;
          window.open(this.download.linkDownload, "_blank");
        });
      },
      downloadMcu(param) {
        API.get(
          `${process.env.VUE_APP_API_TRUST}diagnosis/get-mcureport?order_number=${param}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then(res => {
          this.download = res;
          window.open(this.download.linkDownload, "_blank");
        });
      },
      formaterText(param) {
        let result = param.substr(0, 1);
        if (result == "[") {
          let newVal = JSON.parse(param);
          let newValues = newVal[0].reference_text;
  
          return newValues;
        } else {
          return param;
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/scss/color.scss";
  .pl-sub {
    padding-left: 3% !important;
  }
  .grey-color {
    background: #eeeeee;
  }
  
  .head-vir {
    background: #1a428a;
    color: #fff;
  }
  
  .head-vir2 {
    background: #cecece;
    font-weight: bold;
  }
  
  .head-vir2:hover {
    background: #cecece !important;
  }
  
  .head-vir2 th {
    color: #000 !important;
  }
  
  .bold {
    font-weight: bold;
  }
  
  // .hover-apri:hover {
  //   background: rgba(113, 188, 225, 0.1) !important;
  // }
  // .hover-apri:hover td {
  //   font-weight: bold;
  // }
  
  .v-data-table table tr td {
    font-size: 12px !important;
  }
  
  .mobile {
    display: none;
  }
  
  @media (max-width: 450px) {
    .dialogvirtu.v-dialog {
      margin: 5px !important;
    }
  
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
  
    .dialogvirtulogo {
      width: 150px;
    }
  }
  </style>
  <style lang="scss">
  .dialogorderdetail{
    .v-card__title{
        line-height: 1rem !important;
    }
    .title-head{
        color: #1f3c87;
        font-weight: 600;
        font-size: 1.5rem;
    }
    .title-content{
        color: #1f3c87;
        font-weight: 500;
        font-size: 14px;
    }
    .title-content-value{
        font-weight: 400;
        font-size: 14px;
    }
    .title-content-footer{
        font-weight: 400;
        font-size: 14px;
        margin: unset !important;
        height: 15px;
        word-break: normal;
    }
    .title-content-footer3{
        font-weight: 400;
        font-size: 14px;
        margin: unset !important;
        height: 16px;
    }
    .padding-title{
        padding: 7px 20px 7px !important;
    }
    .btn-close{
        background: #fff;
        color: #209fb4 !important;
        border: 1px solid;
        border-color: #209fb4;
    }
  }
  
  @media (max-width: 450px) {
    .dialogorderdetail.v-dialog {
      margin: 5px !important;
    }
    .dialogorderdetail .v-card__text {
      padding: 0 10px 20px !important;
    }
    .td-custom {
      width: 100% !important;
    }
  }
  .font-black {
    color: #000 !important;
  }
  .category-black {
    color: #fff !important;
    background: #000 !important;
  }
  .category-blue {
    color: #fff !important;
    background: #233e8d !important;
  }
  .btn-download-report {
    background: #209fb4;
  }
  
  .table-inside {
    width: 100%;
    padding: 0;
  }
  
  .table-inside tr td:first-child {
    width: 20%;
  }
  
  .mobile tr {
    display: inline-table;
    width: 100%;
  }
  
  .mobile .table-inside tr td:first-child {
    width: 20%;
  }
  
  .td-custom {
    width: 20% !important;
  }
  
  @media (min-width: 600px) {
    .table-sticky .v-data-table__wrapper {
      overflow: unset;
    }
    .table-sticky thead {
      position: sticky;
      inset-block-start: 0;
      top: 0;
    }
  }
  @media (max-width: 599px) {
    .table-sticky .v-data-table__wrapper {
      overflow-x: auto;
      overflow-y: auto;
      height: 400px;
    }
    .table-sticky thead tr {
      border-bottom: 0 !important;
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }
  .popup {
    padding: 8px;
    background: white;
    text-transform: uppercase;
    font-size: 12px !important;
    line-height: 16px;
    a {
      text-decoration: none;
      color: black;
    }
  }
  .title-grid {
    display: grid;
  }
  .title-grid-h {
    text-align: left;
  }
  .title-grid-c {
    text-align: left;
    font-size: 8px;
  }
  .mt5 {
    margin-top: 5px !important;
  }
  .mb10 {
    margin-bottom: -10px;
  }
  .mb5 {
    margin-bottom: 5px !important;
  }
  </style>
  