<template>
  <div class="box-login">
    <div class="login__header">
      <v-container>
        <a :href="linkVirtu">
          <img :src="require('../assets/logoDigiLab.png')" height="50" />
        </a>
      </v-container>
    </div>
    <div class="login__body">
      <v-container>
        <v-row class="flex-column-reverse flex-lg-row z-index-10">
          <v-col cols="12" lg="7" class="mb-6">
            <p class="virtu-desc">
              <a :href="linkVirtu"> Virtu DigiLab </a>
              {{ $t("page.login.content") }}
            </p>
            <!-- <a href="#" class="read-more">{{ $t("read_more") }}...</a> -->
            <v-row class="mt-6 virtu-point">
              <v-col cols="6">
                <div class="d-flex align-items-center">
                  <v-col
                    cols="3"
                    md="2"
                    style="padding: 0px"
                    class="d-flex align-items-center"
                  >
                    <img
                      height="30"
                      src="../assets/icon/login_appointment.png"
                      alt=""
                    />
                  </v-col>
                  <v-col cols="9" md="10" style="padding: 0px">
                    <p class="d-inline-block mb-0">
                      {{ $t("page.login.list_3") }}
                    </p>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="d-flex align-items-center">
                  <v-col
                    cols="3"
                    md="2"
                    style="padding: 0px"
                    class="d-flex align-items-center"
                  >
                    <img
                      height="30"
                      src="../assets/icon/login_pay.png"
                      alt=""
                    />
                  </v-col>
                  <v-col cols="9" md="10" style="padding: 0px">
                    <p class="d-inline-block mb-0">
                      {{ $t("page.login.list_5") }}
                    </p>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="d-flex align-items-center">
                  <v-col
                    cols="3"
                    md="2"
                    style="padding: 0px"
                    class="d-flex align-items-center"
                  >
                    <img
                      height="30"
                      src="../assets/icon/login_search.png"
                      alt=""
                    />
                  </v-col>
                  <v-col cols="9" md="10" style="padding: 0px">
                    <p class="d-inline-block mb-0">
                      {{ $t("page.login.list_2") }}
                    </p>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="d-flex align-items-center">
                  <v-col
                    cols="3"
                    md="2"
                    style="padding: 0px"
                    class="d-flex align-items-center"
                  >
                    <img
                      height="30"
                      src="../assets/icon/login_doc.png"
                      alt=""
                    />
                  </v-col>
                  <v-col cols="9" md="10" style="padding: 0px">
                    <p class="d-inline-block mb-0">
                      {{ $t("page.login.list_4") }}
                    </p>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="6">
                <div class="d-flex align-items-center">
                  <v-col
                    cols="3"
                    md="2"
                    style="padding: 0px"
                    class="d-flex align-items-center"
                  >
                    <img
                      height="30"
                      src="../assets/icon/login_user.png"
                      alt=""
                    />
                  </v-col>
                  <v-col cols="9" md="10" style="padding: 0px">
                    <p class="d-inline-block mb-0">
                      {{ $t("page.login.list_1") }}
                    </p>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="5" class="mb-6">
            <div
              class="d-flex justify-center align-center flex-column login-card"
            >
              <h4 class="mb-4 welcome">{{ $t("page.login.welcome") }}</h4>
              <p
                v-show="formCompany == true"
                class="small-dark-text text-center mb-4 mt-2 doctor-company-text"
              >
                {{ $t("page.login.for_company") }}<br /><a
                  @click="submitCompany()"
                  class="text-uppercase"
                >
                  {{ $t("click_here") }}</a
                >
              </p>
              <p
                v-show="formPatient == true"
                class="small-dark-text text-center mb-4 mt-2 doctor-company-text"
              >
                {{ $t("page.login.for_patient") }}<br /><a
                  @click="submitPatient()"
                  class="text-uppercase"
                >
                  {{ $t("click_here") }}</a
                >
              </p>
              <!-- <div class="login-with">
                <p class="login-with__title">
                  Login With
                </p>
                <div class="login-with__tabs">
                  <v-row no-gutters>
                    <v-col class="">
                      <p class="mb-0 login-with__tab" :class="{active: loginwith == 'username'}" @click="changeLogin('username')">
                        Username/Email
                      </p>
                    </v-col>
                    <v-col>
                      <p class="mb-0 login-with__tab" :class="{active: loginwith == 'phone'}" @click="changeLogin('phone')">
                        Phone
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </div> -->
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="form-login"
              >
                <div
                  class="mb-1 field-company-id"
                  v-show="showHideCompany == true"
                >
                  <label
                    for="username"
                    >{{ $t("page.login.company_health") }}</label
                  >
                  <v-text-field
                    outlined
                    dense
                    color="#00A4B3"
                    hide-details
                    v-model="companyId"
                    type="text"
                    class="text-field"
                    :placeholder="$t('page.login.company_health')"
                    id="companyId"
                    v-on:keyup.enter="login"
                  />
                </div>
                <div class="mb-1 field-username">
                  <label
                    for="username"
                    >{{ $t("page.login.sign_with") }}</label
                  >
                  <v-text-field
                    outlined
                    dense
                    color="#00A4B3"
                    hide-details
                    v-model="keyLogin"
                    type="text"
                    class="text-field"
                    :placeholder="$t('page.login.sign_with')"
                    id="username"
                    v-on:keyup.enter="login"
                  />
                </div>
                <div class="mb-1 mt-1 field-password">
                  <label
                    for="password"
                    >{{ $t("page.login.password") }}</label
                  >
                  <v-text-field
                    outlined
                    dense
                    color="#00A4B3"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    hide-details
                    v-model="password"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    class="text-field"
                    :placeholder="$t('page.login.password')"
                    id="password"
                    v-on:keyup.enter="login"
                  />
                </div>
                <div class="mb-6 remember-me d-flex justify-space-between">
                  <div>
                    <input
                      type="checkbox"
                      id="remember"
                      class="mr-4"
                      v-model="rememberme"
                      @change="toggleRememberMe"
                    />
                    <label for="remember" class="small-dark-text">{{
                      $t("page.login.remember_me")
                    }}</label>
                  </div>
                  <div>
                    <v-dialog v-model="dialog" width="500" class="bg-blue">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="small-dark-text lupa-password"
                        >
                          {{ $t("page.login.forgot_password") }}
                        </span>
                      </template>

                      <v-card class="blue_2">
                        <v-card-title class="text-h5 center title-package">
                          {{ $t("page.login.forgot_password") }}
                        </v-card-title>
                        <v-card-text class="pb-0">
                          <v-col cols="12">
                            <v-text-field
                              :label="`${$t('page.login.sign_with')}`"
                              v-model="keyLoginForgot"
                              id="usernameForgot"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            class="mr-4"
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                          >
                            {{ $t("close") }}
                          </v-btn>
                          <button class="button--forgot" @click="forgot">
                            {{ $t("save") }}
                          </button>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </div>
                <!-- <p class="login__forgot">{{ $t("login.forgot_password") }}</p> -->
                <div class="d-flex justify-center flex-column">
                  <div class="d-flex justify-center">
                    <v-btn
                      class="button--login"
                      @click="login"
                      rounded
                      :disabled="!valid"
                    >
                      {{ $t("page.login.login") }}
                    </v-btn>
                  </div>
                  <p
                    class="small-dark-text text-center mb-0 mt-6 dont-have-text"
                  >
                    {{ $t("page.login.dont_have_account") }}
                  </p>
                  <localized-link
                    to="/register"
                    class="login__link--register text-center text-uppercase"
                  >
                    {{ $t("page.login.sign_up_now") }}</localized-link
                  >
                </div>
              </v-form>
            </div>
          </v-col>
        </v-row>
        <v-row class="doctor-pic">
          <v-col>
            <div class="d-flex justify-center">
              <img
                src="../assets/images/team-doctors.png"
                class="team-doctor"
                alt=""
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="login__footer coak"></div>
  </div>
</template>

<script>
import Auth from "../service/auth_service";
import validator from "validator";
import API from "../service/api_service.js";
import localStorageService from "../service/localStorageService";
import moment from "moment";

export default {
  name: "Login",
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.$t("page.login.sign_in")}`,
    };
  },
  // components: { IconCoax },
  data: () => ({
    formCompany: true,
    formPatient: false,
    show: false,
    phone: "",
    loginwith: "username",
    dialog: false,
    rememberme: false,
    show_password: false,
    password: "",
    username: "",
    companyId: "",
    showHideCompany: false,
    labelType: false,
    valid: true,
    UsernameRules: [v => !!v || "Username is required"],
    PasswordRules: [v => !!v || "Password is required"],
    usernameForgot: "",
    linkVirtu: process.env.VUE_APP_URL_VIRTU,
  }),
  computed: {
    keyLogin: {
      get() {
        return this.username;
      },
      set(param) {
        if (param.length > 9 && validator.isMobilePhone(param)) {
          if (param.substring(0, 2) == "08") {
            this.username = `+${param.replace("08", "628")}`;
          } else if (param.substring(0, 1) !== "+") {
            this.username = `+${param}`;
          } else {
            this.username = param;
          }
        } else {
          this.username = param;
        }
      },
    },
    keyLoginForgot: {
      get() {
        return this.usernameForgot;
      },
      set(param) {
        if (param.length > 9 && validator.isMobilePhone(param)) {
          if (param.substring(0, 2) == "08") {
            this.usernameForgot = `+${param.replace("08", "628")}`;
          } else if (param.substring(0, 1) !== "+") {
            this.usernameForgot = `+${param}`;
          } else {
            this.usernameForgot = param;
          }
        } else {
          this.usernameForgot = param;
        }
      },
    },
  },
  mounted() {
    if (
      localStorageService.getItem("username") !== null &&
      localStorageService.getItem("username") !== ""
    ) {
      this.username = localStorageService.getItem("username");
      this.rememberme = true;
    }

    if (
      this.$route.query.type != "" &&
      this.$route.query.type != null &&
      this.$route.query.type != undefined &&
      this.$route.query.type == "company"
    ) {
      this.submitCompany();
    } else {
      this.submitPatient();
    }
  },
  methods: {
    submitCompany() {
      this.showHideCompany = true;
      this.formCompany = false;
      this.formPatient = true;
      this.companyId = "";
    },
    submitPatient() {
      this.showHideCompany = false;
      this.formCompany = true;
      this.formPatient = false;
      this.companyId = "";
    },
    login(e) {
      if (this.$refs.form.validate() == true) {
        e.preventDefault();

        if (
          this.username !== "" &&
          this.password !== "" &&
          this.companyId == ""
        ) {
          if (this.showHideCompany == true) {
            this.$swal({
              icon: "error",
              text: this.$t("page.login.error_login_company"),
            });
          } else {
            const payload = { password: this.password };
            if (validator.isEmail(this.username)) {
              payload.email = this.username;
            } else if (validator.isMobilePhone(this.username)) {
              payload.phone_number = this.username;
            } else {
              payload.username = this.username;
            }

            Auth.signIn(payload).then(res => {
              if (res.statusCode == 200) {
                if (!res.results.fully_registered) {
                  Auth.setTypeUser(0);
                  Auth.setToken(
                    res.results.access_token,
                    moment().add(1, "hours")
                  );
                  this.$store.commit("setUserType", 0);
                  this.$router.push(`/${this.$i18n.locale}/continue-register`);
                } else {
                  Auth.setToken(res.results.access_token);
                  if (res.results.account_type == "HEALTH FACILITY") {
                    Auth.setTypeUser(6);
                    this.$store.commit("setUserType", 6);
                  } else if (res.results.account_type == "PAYOR") {
                    Auth.setTypeUser(3);
                    this.$store.commit("setUserType", 3);
                  } else if (res.results.account_type == "REFERRER") {
                    Auth.setTypeUser(2);
                    this.$store.commit("setUserType", 2);
                  } else {
                    Auth.setTypeUser(1);
                    this.$store.commit("setUserType", 1);
                  }
                  if (this.rememberme) {
                    localStorageService.setItem("username", this.username);
                  }
                  if (this.$router.currentRoute.query.redirect !== undefined) {
                    this.$router.push({
                      path: this.$router.currentRoute.query.redirect,
                    });
                  } else {
                    this.$router.push(`/${this.$i18n.locale}/`);
                  }
                }
              } else {
                this.toast("error", res.message);
              }
            });
          }
        } else if (
          this.username !== "" &&
          this.password !== "" &&
          this.companyId != ""
        ) {
          const payload = {
            password: this.password,
            is_company: true,
            company_code: this.companyId,
          };
          if (validator.isEmail(this.username)) {
            payload.email = this.username;
          } else if (validator.isMobilePhone(this.username)) {
            payload.phone_number = this.username;
          } else {
            payload.username = this.username;
          }

          Auth.signIn(payload).then(async res => {
            if (res.statusCode == 200) {
              if (!res.results.fully_registered) {
                Auth.setTypeUser(0);
                Auth.setToken(
                  res.results.access_token,
                  moment().add(1, "hours")
                );
                this.$store.commit("setUserType", 0);
                this.$router.push(`/${this.$i18n.locale}/continue-register`);
              } else {
                Auth.setToken(res.results.access_token);
                await this.$store.dispatch("fetchMenuData");
                if (res.results.account_type == "HEALTH FACILITY") {
                  Auth.setTypeUser(6);
                  this.$store.commit("setUserType", 6);
                } else if (res.results.account_type == "PAYOR") {
                  Auth.setTypeUser(3);
                  this.$store.commit("setUserType", 3);
                } else if (res.results.account_type == "REFERRER") {
                  Auth.setTypeUser(2);
                  this.$store.commit("setUserType", 2);
                } else {
                  Auth.setTypeUser(1);
                  this.$store.commit("setUserType", 1);
                }
                if (this.rememberme) {
                  localStorageService.setItem("username", this.username);
                }
                if (this.$router.currentRoute.query.redirect !== undefined) {
                  this.$router.push({
                    path: this.$router.currentRoute.query.redirect,
                  });
                } else {
                  this.$router.push(`/${this.$i18n.locale}/`);
                }
              }
            } else {
              this.toast("error", res.message);
            }
          });
        } else {
          this.$swal({
            icon: "error",
            text: this.$t("page.login.error_login"),
          });
        }
      }
    },
    forgot(e) {
      // if (this.$refs.form.validate() == true) {
      e.preventDefault();
      const dataReady = {};
      if (validator.isEmail(this.usernameForgot)) {
        dataReady.email = this.usernameForgot;
      } else if (validator.isMobilePhone(this.usernameForgot)) {
        dataReady.phone_number = this.usernameForgot;
      } else {
        dataReady.username = this.usernameForgot;
      }
      API.post(
        `${process.env.VUE_APP_API_TRUST}forget-password`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        },
        dataReady
      )
        .then(res => {
          // console.log(res);
          if (res.statusCode === 200) {
            this.$swal({
              icon: "success",
              text: res.message,
              showCancelButton: false,
              confirmButtonColor: "#7367f0",
              confirmButtonText: "OK",
            }).then(() => {
              window.location.href = `/login`;
            });
          } else if (res.statusCode === 400) {
            this.$swal({
              icon: "error",
              text: res.errors.username[0],
            });
          } else {
            this.$swal({
              icon: "error",
              text: res.message,
            });
          }
        })
        .catch(e => {
          console.log(e);
          this.$swal({
            icon: "error",
            text: "Error Forget Password!",
          });
        });
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    toggleRememberMe() {
      localStorageService.removeItem("username");
    },
    changeLogin(param) {
      this.loginwith = param;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/custom.scss";
@font-face {
  font-family: HankenSans;
  src: url("../assets/fonts/HankenSans-Light.otf");
}
.blue_2 {
  background-color: #e9edf8 !important;
}
.box-login {
  font-family: HankenSans;
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(239, 231, 223, 1) 0%,
    rgba(255, 255, 255, 1) 50%
  );
  min-height: 760px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .login__header {
    padding: 50px 0 0 0;
    display: flex;
    @media screen and (max-width: 1264px) {
      text-align: center;
    }
  }
  .login__body {
    display: flex;
    height: 100%;
    flex-grow: 2;
    .form-login {
      width: 100%;
      max-width: 350px;
    }
  }
  .login__footer {
    /* FOOTER COAK START */
    height: 46px;
    background: $blue_2;
    &.coak {
      position: relative;
      width: 100%;
      background: $blue_2;
      background-clip: content-box;

      padding-top: 0.65rem;
      overflow: hidden;
    }
    &.coak {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        width: calc(50% - (-1px));
        height: 0.7rem;
        background: $blue_2;
        left: 0;
        width: 75%;
        transform: skew(45deg);
        transform-origin: right bottom;
        border-top-right-radius: 1rem;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        width: calc(50% - (-1px));
        height: 0.7rem;
        background: $blue_2;
        right: -25%;
        transform: skew(-45deg);
        transform-origin: left bottom;
        border-top-left-radius: 1rem;
      }
    }
  }
  /* FOOTET COAK END */
  a {
    color: $blue_2;
    text-decoration: none;
    font-size: 20px;
  }
  .team-doctor {
    width: 90%;
    max-width: 500px;
    position: absolute;
    bottom: 0;
    @media screen and (max-width: 1264px) {
      margin-bottom: -50px;
      position: relative;
    }
  }
  .button--login {
    padding: 8px 18px;
    background: $blue_2;
    color: white;
    text-transform: capitalize !important;
    border-radius: 8px;
    width: 110px;
  }

  .doctor-company-text {
    font-size: 16px;
  }
}
.button--forgot {
  padding: 8px 18px;
  background: $blue_2;
  color: white;
  text-transform: uppercase;
  border-radius: 8px;
  width: 110px;
}
p {
  font-size: 20px;
  color: $blue_1;
}
h4 {
  font-size: 26px;
  color: $blue_1;
  font-family: HankenSans;
}
input,
select {
  font-family: HankenSans;
  color: $gray_2;
  option {
    font-family: HankenSans;
    color: $gray_2;
  }
  &.input-login {
    border: 1px solid $blue_2;
    width: 100%;
    padding: 8px 16px;
    border-radius: 14px;
    background: white;
    font-size: 20px;
  }
  &:focus {
    outline: 1px solid $blue_2;
    outline: none;
  }
}
.hide-label {
  visibility: hidden;
}
.samall-dark-text {
  font-size: 16px;
  color: $gray_2;
}
.z-index-10 {
  position: relative;
  z-index: 10;
}
.coax-icon {
  position: absolute;
  left: 75%;
  height: 60px;
  transform: translateX(-50%);
}
.lupa-password {
  color: $blue_2;
}
.login-with {
  width: 100%;
  max-width: 350px;
  &__title {
    font-weight: 500;
    margin: 0;
  }
  &__tabs {
    border: 1px solid $main_2;
    border-radius: 12px;
    overflow: hidden;
    margin-top: 8px;
    margin-bottom: 10px;
    text-align: center;
  }
  &__tab {
    padding: 6px 8px;
    cursor: pointer;
    font-size: 18px;
    &.active {
      cursor: default;
      background: $main_3;
      color: white;
    }
  }
}
.v-text-field--outlined > .v-input__control > .v-input__slot {
  background: transparent;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .box-login {
    background: white;
    a {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .virtu-desc {
    display: none;
  }
  .read-more {
    display: none;
  }

  .virtu-point {
    display: none;
  }

  .doctor-pic {
    display: none;
  }

  .welcome {
    font-weight: bold;
  }

  .small-dark-text {
    font-weight: normal;
  }

  .lupa-password {
    font-weight: bold;
  }

  .button--login {
    font-size: 12px;
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
    height: 35px;
    text-transform: capitalize;
    width: 150px;
  }
  .login__link--register {
    text-transform: capitalize !important;
    font-size: 14px;
  }
}
</style>
