<template>
  <div
    class="card"
    :class="{
      'card--primary': primary,
      'card--rounded': rounded,
      'card--rounded-md': roundedMd,
      'card--shadow': shadow,
      'card--border': border,
      'card--border-blue': borderBlue,
      'card--border-main': borderMain,
      'card--badges': badges,
      'card--badges--success': badgesSuccess,
      'card--badges--warning': badgesWarning,
      'card--badges--danger': badgesDanger,
    }"
  >
    <slot name="header"> </slot>
    <slot name="body"> </slot>
    <slot name="footer"> </slot>
  </div>
</template>

<script>
export default {
  props: {
    primary: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    roundedMd: {
      type: Boolean,
      default: false,
    },
    borderBlue: {
      type: Boolean,
      default: false,
    },
    borderMain: {
      type: Boolean,
      default: false,
    },
    badges: {
      type: Boolean,
      default: false,
    },
    badgesSuccess: {
      type: Boolean,
      default: false,
    },
    badgesWarning: {
      type: Boolean,
      default: false,
    },
    badgesDanger: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/color.scss";
.card {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  /* identical to box height, or 143% */

  //section record patient & patient summary
  letter-spacing: 0.25px;
  background: white;
  &--badges {
    &::after {
      content: "";
      display: block;
      height: 80%;
      transform: translate(-50%, -50%);
      width: 6px;
      border-radius: 3px;
      background: $dark_blue_3;
      position: absolute;
      left: 0;
      top: 50%;
    }
    &--warning {
      &::after {
        background: $yellow_2;
      }
    }
    &--danger {
      &::after {
        background: $red_3;
      }
    }
    &--success {
      &::after {
        background: $green_2;
      }
    }
  }
  &__header {
    padding: 12px;
    border-bottom: 1px solid $gray_2;
    @media screen and (min-width: 960px) {
      padding: 16px 20px;
    }
  }
  &__body {
    padding: 12px;
    @media screen and (min-width: 960px) {
      padding: 16px 20px;
    }
  }
  &__footer {
    padding: 12px 12px 10px 12px;
    .text-showmore {
      font-size: 14px;
      font-weight: 600;
      text-align: center;

      a {
        color: #00a6b6;
        text-decoration: none;
      }
    }
  }
  &--primary {
    background-color: $main_2;
    color: white;
  }
  &--rounded {
    border-radius: 4px;
  }
  &--rounded-md {
    border-radius: 10px;
  }
  &--shadow {
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
  }
  &--border {
    border: 1px solid $gray_10;
  }
  &--border-blue {
    border: 1px solid $dark_blue_3;
  }
  &--border-main {
    border: 1px solid $main_2;
  }
}
</style>
