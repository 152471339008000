<template>
  <v-app>
    <div
      class="layout bg-app"
      v-if="
        (isLogin &&
          Object.keys(dataProfile).length > 0 &&
          $store.state.finishFetch) ||
        !isLogin
      "
    >
      <v-main>
        <router-view :key="$route.fullPath" />
      </v-main>
    </div>
    <loading v-if="$store.state.loading" />
    <modal-menu v-if="$store.state.menuOpen" />
    <alert-custom v-if="$store.state.openAlert" />
  </v-app>
</template>
<script>
import Loading from "./components/general/Loading.vue";
import AlertCustom from "./components/general/AlertCustom.vue";
import ModalMenu from "./components/ModalMenu.vue";
import localStorageService from "./service/localStorageService";
import Pusher from "pusher-js";
import Auth from "@/service/auth_service";

export default {
  name: "App",
  metaInfo() {
    return {
      title: "Virtu DigiLab",
      lang: this.$i18n.locale,
      link: [
        { rel: "icon", href: "/favicon.ico" },
        {
          rel: "canonical",
          href: `${window.location.origin}${this.$route.fullPath}`,
        },
      ],
      titleTemplate: null,
    };
  },
  data: () => ({
    lang: "id",
    isLogin: false,
  }),
  components: { Loading, ModalMenu, AlertCustom },
  computed: {
    // get data profile from store
    dataProfile() {
      return this.$store.state.profileData;
    },
  },
  async created() {
    this.$store.commit("setFinishFetch", false);
    this.isLogin = Auth.checkSession();
    if (this.isLogin && Object.keys(this.dataProfile).length > 0) {
      this.getNotification();
    } else {
      await this.$store.dispatch("fetchProfileData");
      await this.$store.dispatch("fetchCartData");
      await this.$store.dispatch("fetchMenuData");
      await this.$store.dispatch("fetchRescheduleData");
    }
    this.$store.commit("setFinishFetch", true);
  },
  mounted() {
    if (localStorageService.getItem("type") == 1) {
      document.querySelector("html").classList.add("not-scroll");
    } else {
      document.querySelector("html").classList.remove("not-scroll");
    }
    this.$store.commit("checkUserType");
    if (this.$route.query.lang == "id") {
      localStorageService.setItem("locale", "id");
      this.$i18n.locale = "id";
    } else {
      localStorageService.setItem("locale", "en");
      this.$i18n.locale = "en";
    }
  },
  watch: {
    async $route() {
      localStorageService.setItem("locale", this.$i18n.locale);
      this.$store.commit("checkUserType");
      this.$store.commit("toggleMenuActive", "");
      this.$store.commit("setActivePage", this.$router.currentRoute.name);
      this.isLogin = Auth.checkSession();
      await this.$store.dispatch("fetchProfileData");
      await this.$store.dispatch("fetchCartData");
      await this.$store.dispatch("fetchMenuData");
      await this.$store.dispatch("fetchRescheduleData");
    },
  },
  methods: {
    async getNotification() {
      try {
        // check data is not empty
        let client = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
          wsHost: process.env.VUE_APP_PUSHER_HOST,
          wsPort: 443,
          wssPort: 443,
          forceTLS: true,
          encrypted: true,
          disableStats: true,
          enabledTransports: ["ws", "wss"],
        });

        client.connection.bind("state_change", state => {
          if (state == "connected") {
            console.log(state);
          }
          //document.getElementById("state").innerHTML = 'State: '+JSON.stringify(state.current);
        });

        client
          .subscribe(`c-${this.dataProfile.uuid}`)
          .bind("notification", dat => {
            //const _this = this;
            if (Notification.permission === "default") {
              Notification.requestPermission().then(function (permission) {
                if (permission === "granted") {
                  //_this.notify(dat);
                  new Notification(dat.title, {
                    body: dat.message,
                  });
                }
              });
            } else if (Notification.permission === "granted") {
              new Notification(dat.title, {
                body: dat.message,
              });

              //this.notify(message);
            }
            this.$store.dispatch("fetchNotification");
          });

        // client.connection.bind("connected", function () {
        //    client.subscribe(`c-${resp.uuid}`).bind('notification', (dat) => {
        //       console.log('response subscribe', dat);

        //         const _this = this;
        //         if (Notification.permission === "default") {
        //               Notification.requestPermission().then(function (permission) {
        //                 if (permission === "granted") {
        //                   //_this.notify(dat);
        //                   var notification = new Notification(dat.title, {
        //                     body: dat.message,
        //                   });
        //                   notification.onclick = function () {
        //                     window.open(`${process.env.VUE_APP_BASE_URL}${_this.$i18n.locale}/patient/profile?tab=${dat.notificationType}`);
        //                   };
        //                 }
        //               });
        //           }else if(Notification.permission === "granted") {
        //             var notification = new Notification(dat.title, {
        //                 body: dat.message,
        //               });
        //               notification.onclick = function () {
        //                 window.open(`${process.env.VUE_APP_BASE_URL}${_this.$i18n.locale}/patient/profile?tab=${dat.notificationType}`);
        //               };
        //             //this.notify(message);
        //           }
        //         _this.$store.dispatch("fetchNotification");
        //     });
        // });
        // client.connection.bind('state_change', (state) => {
        //   console.log('luar if state console',state)
        //   if (state == "connected") {
        //      console.log('state console',state)
        //     }
        //   //document.getElementById("state").innerHTML = 'State: '+JSON.stringify(state.current);
        // });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./assets/css/custom.scss";
.layout {
  background: $main_1;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  // backdrop-filter: blur(309px);
}
</style>
<style lang="scss">
@import "../src/assets/css/global.scss";
@import "../src/assets/css/responsive.scss";
.container {
  @media screen and (max-width: 1264px) {
    max-width: 1200px !important;
  }
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: 36px;
}
.box-page {
  background: white;
  min-height: 700px;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 50px;
}
.box-page-new {
  background: white;
  // padding-top: 100px;
  // padding-bottom: 50px;
}
.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  opacity: 1;
  // display: none;
}

//doctor office tab menu
.tab {
  &__selects {
    .v-text-field {
      .v-input {
        &__control {
          .v-input__slot {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
            .v-select {
              &__selection {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                text-transform: initial;
              }
            }
          }
        }
      }
    }
  }
}
.tab__menus {
  display: block;
  @media screen and (max-width: 960px) {
    display: none;
  }
  .menu__item {
    cursor: pointer;
    border: 1px solid green;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 8px 30px;
    background: green;
    border-radius: 4px 4px 0 0;
    &--active {
      background: white;
    }
  }
}
.tab__contents {
  padding: 20px 20px 5px 20px;
  background: white;
  border-radius: 0 4px 4px 4px;
  display: none;
  &--active {
    display: block;
  }
  @media screen and (max-width: 960px) {
    border-radius: 4px;
  }
}
.tab__selects {
  background: white;
  display: none;
  @media screen and (max-width: 960px) {
    display: block;
  }
}
.v-expansion-panels {
  border-radius: 0px !important;
  z-index: 0;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
// .v-text-field--rounded > .v-input__control > .v-input__slot {
//   padding: 0 2px !important;
// }
.v-label {
  font-size: 12px !important;
}
.v-btn {
  text-transform: none;
}
.my-sub {
  .v-expansion-panel-content__wrap {
    padding: 0 0 16px 0 !important;
  }
  .v-expansion-panel--active {
    background: #fafafa !important;
  }
}
</style>
