<template>
  <svg
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 19C9.84026 19 10.9274 17.9368 10.9274 16.625H6.07257C6.07257 17.9368 7.15974 19 8.5 19ZM16.6733 13.4444C15.9401 12.674 14.5684 11.515 14.5684 7.71875C14.5684 4.83535 12.5011 2.52715 9.71352 1.96086V1.1875C9.71352 0.531777 9.17013 0 8.5 0C7.82987 0 7.28648 0.531777 7.28648 1.1875V1.96086C4.49894 2.52715 2.43163 4.83535 2.43163 7.71875C2.43163 11.515 1.05987 12.674 0.326744 13.4444C0.0990661 13.6837 -0.00187108 13.9698 2.62356e-05 14.25C0.00420033 14.8586 0.492569 15.4375 1.2181 15.4375H15.7819C16.5074 15.4375 16.9962 14.8586 17 14.25C17.0019 13.9698 16.9009 13.6833 16.6733 13.4444V13.4444Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
