<template>
  <svg
    width="9"
    height="7"
    viewBox="0 0 9 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.73201 0.920905C4.13179 0.441523 4.86821 0.441523 5.26799 0.920904L8.7611 5.10953C9.30422 5.7608 8.84112 6.75 7.99311 6.75L1.00689 6.75C0.15888 6.75 -0.304218 5.7608 0.2389 5.10954L3.73201 0.920905Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
