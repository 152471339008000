<template>
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.8739 6.19542C12.8813 6.19542 12.0058 5.72652 11.4337 5.00848L6.12811 7.6988C6.19757 7.95567 6.24749 8.22107 6.24749 8.50039C6.24749 8.80447 6.1882 9.09075 6.10546 9.36931L11.3877 12.0472C11.9566 11.2967 12.8548 10.8061 13.8747 10.8061C15.6024 10.8061 17 12.1919 17 13.9034C17 15.6134 15.6024 17 13.8747 17C12.1509 17 10.7517 15.6134 10.7517 13.9035C10.7517 13.6234 10.8017 13.3572 10.8719 13.0996L5.56705 10.4092C4.99427 11.128 4.11713 11.5985 3.12297 11.5985C1.39681 11.5985 0 10.2111 0 8.50039C0 6.78964 1.39685 5.40308 3.12297 5.40308C4.14445 5.40308 5.04188 5.89518 5.61307 6.64648L10.8929 3.96856C10.8102 3.69078 10.7502 3.40217 10.7502 3.09731C10.7502 1.38656 12.1493 0 13.8731 0C15.6008 0 16.9984 1.38656 16.9984 3.09731C16.9992 4.80961 15.6016 6.19542 13.8739 6.19542Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  props: ["color"],
};
</script>