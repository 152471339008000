<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.37508 5.00059V5.00001C2.37508 4.46537 2.80791 4.03334 3.33341 4.03334H16.6667C17.1968 4.03334 17.6334 4.46994 17.6334 5.00001V15C17.6334 15.5301 17.1968 15.9667 16.6667 15.9667H3.33341C2.80343 15.9667 2.36689 15.5302 2.36675 15.0003C2.36675 15.0002 2.36675 15.0001 2.36675 15L2.37508 5.00059Z"
      :stroke="color"
      stroke-width="1.4"
    />
    <path d="M2.5 5L10 10L17.5 5" :stroke="color" stroke-width="1.4" />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
