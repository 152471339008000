<template>
  <div class="box-modal active" @click.stop="$emit('modalAddressClose')">
    <div class="modal--search-address">
      <div class="title-address" @click.stop="changeMenu">
        {{ menu[address_active] }}
      </div>
      <v-text-field
        solo
        prepend-inner-icon="search"
        label="Search..."
        v-model="searchText"
        @click.stop=""
        ref="searchText"
      />
      <div class="text-center" v-if="loading">
        <icon-loading class="icon-loading" />
      </div>
      <div class="list-address" v-else>
        <template v-if="address_active == 0">
          <div
            class="card--address"
            v-for="(country, index) of countrysFilter"
            :key="index"
            @click.stop="changeCountry(country)"
          >
            {{ country.name }}
          </div>
        </template>
        <template v-if="address_active == 1">
          <div
            class="card--address"
            v-for="(prov, index) of provincesFilter"
            :key="index"
            @click.stop="changeProv(prov)"
          >
            {{ prov.name }}
          </div>
        </template>
        <template v-if="address_active == 2">
          <div
            class="card--address"
            v-for="(city, index) of citysFilter"
            :key="index"
            @click.stop="changeCity(city)"
          >
            {{ city.name }}
          </div>
        </template>
        <template v-if="address_active == 3">
          <div
            class="card--address"
            v-for="(district, index) of districtsFilter"
            :key="index"
            @click.stop="changeDistrict(district)"
          >
            {{ district.name }}
          </div>
        </template>
        <template v-if="address_active == 4">
          <div
            class="card--address"
            v-for="(subdistrict, index) of subdistrictsFilter"
            :key="index"
            @click.stop="changeSubDistrict(subdistrict)"
          >
            {{ subdistrict.name }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../service/api_service";
import IconLoading from "../elements/IconLoading.vue";
export default {
  components: { IconLoading },
  props: {
    countryCode: {
      type: String,
      default: "ID",
    },
  },
  data: () => ({
    searchText: "",
    loading: true,
    address_active: 0,
    countrys: [],
    countrysFilter: [],
    provinces: [],
    provincesFilter: [],
    citys: [],
    citysFilter: [],
    districts: [],
    districtsFilter: [],
    subdistricts: [],
    subdistrictsFilter: [],
    selected: {
      country: "",
      country_id: "",
      country_code: "",
      prov: "",
      prov_id: "",
      prov_id_sni: "",
      city: "",
      city_id: "",
      distict: "",
      distict_id: "",
      subdistrict: "",
      idsubdistrict: "",
      postal_code: "",
    },
    menu: {},
  }),
  watch: {
    searchText() {
      this.searchAddress();
    },
  },
  mounted() {
    if (this.countryCode.toUpperCase() == "ID") {
      this.address_active = 1;
      this.selected.country_code = "ID";
      this.selected.country = "INDONESIA";
      this.selected.country_id = 102;
      this.getProvince();
      this.$refs.searchText.focus();
    } else {
      this.address_active = 0;
      this.getCountry();
      this.$refs.searchText.focus();
    }
    this.menu = {
      0: this.$t("page.office_care.select_country"),
      1: this.$t("page.office_care.select_provice"),
      2: this.$t("page.office_care.select_city"),
      3: this.$t("page.office_care.select_district"),
      4: this.$t("page.office_care.select_subdistrict"),
    };
  },
  methods: {
    async getCountry() {
      try {
        this.loading = true;
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}country/get?query=${
            this.searchText == undefined ? "" : this.searchText
          }`,
          {}
        );
        if (res.statusCode == 200) {
          this.countrys = res.results.sort((a, b) => this.sortByName(a, b));
          this.countrysFilter = this.countrys;
          this.loading = false;
        }
      } catch (error) {
        console.log("Error 403! Country");
        this.loading = false;
      }
    },
    async getProvince() {
      try {
        this.loading = true;
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}province/get?country_code=${
            this.selected.country_code
          }&query=${this.searchText == undefined ? "" : this.searchText}`,
          {}
        );
        if (res.statusCode == 200) {
          if (
            (this.searchText == "" || this.searchText == undefined) &&
            res.results.length < 1
          ) {
            this.finishAddress();
          } else {
            this.provinces = res.results.sort((a, b) => this.sortByName(a, b));
            this.provincesFilter = this.provinces;
            this.loading = false;
          }
        }
      } catch (error) {
        console.log("Error 403! Provice");
        this.loading = false;
      }
    },
    async getCity() {
      try {
        this.loading = true;
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}city/get?province_id_sni=${
            this.selected.prov_id_sni
          }&query=${this.searchText == undefined ? "" : this.searchText}`,
          {}
        );
        if (res.statusCode == 200) {
          if (
            (this.searchText == "" || this.searchText == undefined) &&
            res.results.length < 1
          ) {
            this.finishAddress();
          } else {
            this.citys = res.results.sort((a, b) => this.sortByName(a, b));
            this.citysFilter = this.citys;
            this.loading = false;
          }
        }
      } catch (error) {
        console.log("Error 403!");
        this.loading = false;
      }
    },

    async getDistrict() {
      try {
        this.loading = true;
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}district/get?city_id=${
            this.selected.city_id
          }&query=${this.searchText == undefined ? "" : this.searchText}`,
          {}
        );
        if (res.statusCode == 200) {
          if (
            (this.searchText == "" || this.searchText == undefined) &&
            res.results.length < 1
          ) {
            this.finishAddress();
          } else {
            this.districts = res.results.sort((a, b) => this.sortByName(a, b));
            this.districtsFilter = this.districts;
            this.loading = false;
          }
        }
      } catch (error) {
        console.log("Error 403!");
        this.loading = false;
      }
    },

    async getSubDistrict() {
      try {
        this.loading = true;
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}subdistrict/get?district_id=${
            this.selected.distict_id
          }&query=${this.searchText == undefined ? "" : this.searchText}`,
          {}
        );
        if (res.statusCode == 200) {
          if (
            (this.searchText == "" || this.searchText == undefined) &&
            res.results.length < 1
          ) {
            this.finishAddress();
          } else {
            this.subdistricts = res.results.sort((a, b) =>
              this.sortByName(a, b)
            );
            this.subdistrictsFilter = this.subdistricts;
            this.loading = false;
          }
        }
      } catch (error) {
        console.log("Error 403!");
        this.loading = false;
      }
    },

    changeCountry(param) {
      this.selected.country = param.name;
      this.selected.country_id = param.id;
      this.selected.country_code = param.code;
      this.address_active = 1;
      this.searchText = "";
      this.getProvince();
      this.$refs.searchText.focus();
    },

    changeProv(param) {
      this.selected.prov = param.name;
      this.selected.prov_id = param.id;
      this.selected.prov_id_sni = param.id_sni;
      this.searchText = "";
      if (param.id_sni == null) {
        this.finishAddress();
      } else {
        this.address_active = 2;
        this.getCity();
        this.$refs.searchText.focus();
      }
    },
    changeCity(param) {
      this.selected.city = param.name;
      this.selected.city_id = param.id;
      this.address_active = 3;
      this.searchText = "";
      this.getDistrict();
      this.$refs.searchText.focus();
    },
    changeDistrict(param) {
      this.selected.distict = param.name;
      this.selected.distict_id = param.id;
      this.address_active = 4;
      this.searchText = "";
      this.getSubDistrict();
      this.$refs.searchText.focus();
    },
    changeSubDistrict(param) {
      this.address_active = 4;
      this.selected.subdistrict = param.name;
      this.selected.idsubdistrict = param.code;
      this.selected.postal_code = param.postal_code;
      this.searchText = "";
      this.finishAddress();
      this.$refs.searchText.focus();
    },
    changeMenu() {
      if (this.address_active != 0) {
        switch (this.address_active) {
          case 1:
            if (this.countryCode.toUpperCase() != "ID") {
              this.selected.prov = "";
              this.selected.prov_id = "";
              this.selected.prov_id_sni = "";
            }
            break;
          case 2:
            this.selected.city = "";
            this.selected.city_id = "";
            break;
          case 3:
            this.selected.distict = "";
            this.selected.distict_id = "";
            break;
          case 4:
            this.selected.subdistrict = "";
            this.selected.idsubdistrict = "";
            break;

          default:
            break;
        }
        this.address_active = this.address_active - 1;
        this.searchText = "";
      }
    },
    searchAddress() {
      if (this.address_active == 0) {
        this.getCountry();
      }
      if (this.address_active == 1) {
        this.getProvince();
      }
      if (this.address_active == 2) {
        this.getCity();
      }
      if (this.address_active == 3) {
        this.getDistrict();
      }
      if (this.address_active == 4) {
        this.getSubDistrict();
      }
    },
    sortByName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    finishAddress() {
      const {
        prov,
        city,
        distict,
        subdistrict,
        postal_code,
        country,
        idsubdistrict,
      } = this.selected;
      const address = {
        name: `${subdistrict == "" ? "" : `${subdistrict}, `}${
          distict == "" ? "" : `${distict}, `
        }${city == "" ? "" : `${city}, `}${prov == "" ? "" : `${prov}, `}${
          postal_code == "" ? "" : `${postal_code}, `
        }${country == "" ? "" : `${country}`}`,
        selected: this.selected,
        idsubdistrict,
      };
      this.$emit("setAddress", address);
      this.$emit("modalAddressClose");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/custom.scss";
.box-modal {
  background: rgba(0, 0, 0, 0.337);
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .modal--search-address {
    background: white;
    width: 90%;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 14px 14px 0 0;
    padding: 16px;
    position: absolute;
    min-height: 471px;
    .icon-loading {
      height: 50px;
    }
    .list-address {
      height: 300px;
      overflow: auto;
      .card--address {
        display: flex;
        padding: 14px;
        border-radius: 8px;
        text-transform: uppercase;
        border: 1px solid $gray_2;
        margin-bottom: 12px;
        cursor: pointer;
      }
    }
    .title-address {
      border-bottom: 1px solid $gray_2;
      text-align: center;
      padding: 12px;
      margin-bottom: 12px;
    }
  }
  &.active {
    .modal--search-address {
      bottom: 0;
    }
  }
}

@media screen and (min-width: 389px) and (max-width: 415px) {
  .box-modal .modal--search-address {
    min-height: 630px !important;
  }
}
@media screen and (max-width: 976px) {
  .box-modal .modal--search-address .list-address {
    height: 350px !important;
  }
}
</style>
