<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.54169 4.67492C5.22503 6.83325 8.33336 10.8333 8.33336 10.8333V15.8332C8.33336 16.2916 8.70836 16.6666 9.1667 16.6666H10.8334C11.2917 16.6666 11.6667 16.2916 11.6667 15.8332V10.8333C11.6667 10.8333 14.7667 6.83325 16.45 4.67492C16.875 4.12492 16.4834 3.33325 15.7917 3.33325H4.20003C3.50836 3.33325 3.11669 4.12492 3.54169 4.67492Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
