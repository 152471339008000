<template>
  <div class="otp">
    <div class="otp__box">
      <img
        class="mt-6 mb-3"
        :src="require('../assets/logoDigiLab.png')"
        height="50"
        alt="Virtu Logo"
      />
      <h1>Hi {{ $route.query.name }}</h1>
      <p>{{ $t("page.register.input_otp") }}</p>
      <div class="mb-8" v-if="!loading">
        <v-otp-input
          ref="otpInput"
          input-classes="otp-input"
          separator=""
          :num-inputs="6"
          :should-auto-focus="true"
          :is-input-num="true"
          @on-complete="handleOnComplete"
        />
      </div>
      <div v-else>
        <icon-loading class="otp__loading" />
      </div>
      <div class="d-flex justify-end otp__countdown" v-if="distance > 0">
        <span>{{ otpTime }} {{ $t("to_resend_otp") }}</span>
      </div>
      <p class="mt-3 text-right otp__resend mb-0" v-else @click="resendOtp">
        {{ $t("page.register.resend_otp") }}
      </p>
    </div>
  </div>
</template>

<script>
import VOtpInput from "@bachdgvn/vue-otp-input";
import API from "../service/api_service";
import IconLoading from "../components/elements/IconLoading.vue";

export default {
  data: () => ({
    valid: true,
    loading: false,
    otpTime: "0m 0s",
    distance: 300,
    timerOtp: () => {},
  }),
  components: { VOtpInput, IconLoading },

  created() {
    this.countdown();
  },
  beforeDestroy() {
    clearInterval(this.timerOtp);
  },
  methods: {
    handleOnComplete(value) {
      this.loading = true;
      API.post(
        `${process.env.VUE_APP_API_TRUST}verify-otp`,
        {
          "Content-Type": "application/json",
        },
        { code: value, username: this.$route.query.username }
      )
        .then(res => {
          this.loading = false;
          if (res.statusCode == 200) {
            this.$router.push({ path: "/register-success" });
          } else if (res.statusCode == 400) {
            this.toast("error", res.message);
            // Object.keys(res.errors).forEach(element => {
            //   this.toast(
            //     "error",
            //     typeof res.errors[element] == "object"
            //       ? res.errors[element][0]
            //       : res.errors[element]
            //   );
            // });
          } else {
            this.$swal({
              icon: "error",
              text: "OTP Failed!",
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$swal({
            icon: "error",
            text: this.$t("error_try_again_latter"),
          });
        });
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    countdown() {
      var time = this;
      this.timerOtp = setInterval(function() {
        time.distance = time.distance - 1;
        time.otpTime = `${Math.floor(time.distance / 60)}m  ${time.distance %
          60}s`;
      }, 1000);
    },
    resendOtp() {
      this.loading = true;
      API.post(
        `${process.env.VUE_APP_API_TRUST}request-otp`,
        {
          "Content-Type": "application/json",
        },
        {
          phone_number: this.$route.params.phone,
          username: this.$route.query.username,
        }
      )
        .then(res => {
          this.loading = false;
          if (res.statusCode == 200) {
            this.$swal({
              text: "Resend OTP Success!",
              confirmButtonColor: "#1A428A",
              confirmButtonText: "Oke",
            });
            this.$refs.otpInput.clearInput();
          } else if (res.statusCode == 400) {
            this.toast("error", res.message);
            // Object.keys(res.errors).forEach(element => {
            //   this.toast(
            //     "error",
            //     typeof res.errors[element] == "object"
            //       ? res.errors[element][0]
            //       : res.errors[element]
            //   );
            // });
          } else {
            this.$swal({
              icon: "error",
              text: "Resend OTP Failed!",
            });
          }
        })
        .catch(err => {
          if (err.response.statusCode == 400) {
            this.$swal({
              icon: "error",
              text: this.$t("error_try_again_latter"),
            });
          }
          this.loading = false;
        });
      this.distance = 300;
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/custom.scss";
.otp {
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__resend {
    cursor: pointer;
    color: $blue_2;
  }
  &__button {
    background: radial-gradient(
      118.75% 157.07% at 34.74% -18.75%,
      #5278c7 0%,
      #233f78 100%
    );
  }
  &__countdown {
    color: $gray_2;
  }
  &__loading {
    height: 50px;
    transform: translateX(12px);
  }
  &__box {
    width: 100%;
    max-width: 325px;
    margin: 0 4px;
    padding: 20px 12px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 8px rgb(191, 191, 191);
    h1 {
      margin: 16px 0;
      font-size: 24px;
      font-weight: 600;
    }
    input {
      padding: 10px;
      border: 1px solid gray;
      outline: none;
      border-radius: 10px;
      margin-right: 10px;
      text-align: center;
      font-size: 20px;
      &:nth-last-child(1) {
        margin: 0;
      }
    }
  }
}
</style>
