import { render, staticRenderFns } from "./AlertCustom.vue?vue&type=template&id=c6742eda&scoped=true"
import script from "./AlertCustom.vue?vue&type=script&lang=js"
export * from "./AlertCustom.vue?vue&type=script&lang=js"
import style0 from "./AlertCustom.vue?vue&type=style&index=0&id=c6742eda&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6742eda",
  null
  
)

export default component.exports