import API from "./api_service";
import localStorageService from "./localStorageService";

const setToken = (token, ...expiry) => {
  localStorageService.setItem("token", token, expiry[0]);
};
const setTypeUser = type => {
  localStorageService.setItem("type", type);
};

const getToken = () => {
  return localStorageService.getItem("token");
};
const getTypeUser = () => {
  return localStorageService.getItem("type");
};

const checkSession = () => {
  return getToken() !== "" && getToken() !== null && getToken() !== "undefined";
};

const signOut = () => {
  localStorage.clear();
};

const signIn = async payload => {
  return await API.post(
    `${process.env.VUE_APP_API_TRUST}login?lang=${
      localStorageService.getItem("locale") == null
        ? "en"
        : localStorageService.getItem("locale")
    }`,
    { "Content-Type": "application/json" },
    payload
  )
    .then(res => {
      return res;
    })
    .catch(err => {
      signOut();
      return err;
    });
};

const Auth = {
  signIn,
  getToken,
  checkSession,
  signOut,
  setToken,
  setTypeUser,
  getTypeUser,
};

export default Auth;
