<template>
  <div class="main-layout">
    <v-container class="mt-14 containt">
      <div class="d-flex align-center justify-center pb-5">
        <localized-link to="/sign-out">
          <img :src="require('../../assets/logoDigiLab.png')" height="50" />
        </localized-link>
      </div>
      <v-form v-model="valid" lazy-validation ref="form">
        <v-row>
          <v-col cols="12" md="6" offset-md="3">
            <div class="card--cart">
              <v-row v-if="payload.identityType == 128 && payload.idcard == ''">
                <v-col>
                  <v-col>
                    <h4 class="blue-font mt-0">
                      {{ $t("page.register.scan_otp_ocr") }}
                    </h4>
                    <small>
                      {{ $t("page.register.pls_upload_good_quality_photo") }}
                      <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="900"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <a
                            href="javascript:void(0);"
                            v-bind="attrs"
                            v-on="on"
                            style="text-decoration: underline"
                            >{{ $t("page.register.click_image_guide") }}</a
                          >
                        </template>
                        <template v-slot:default="dialog">
                          <v-card>
                            <v-toolbar color="#00a4b3" dark>{{
                              $t("page.register.scan_ktp_guide")
                            }}</v-toolbar>
                            <v-card-text>
                              <v-row>
                                <div class="col-12 col-md-6 offset-md-3">
                                  <img
                                    :src="
                                      require('../../assets/images/ocr_guide/identifai-1.jpeg')
                                    "
                                    style="width: 100%"
                                  />
                                </div>
                                <div class="col-12">
                                  <img
                                    :src="
                                      require('../../assets/images/ocr_guide/identifai-2.jpeg')
                                    "
                                    style="width: 100%"
                                  />
                                </div>
                                <div class="col-12">
                                  <img
                                    :src="
                                      require('../../assets/images/ocr_guide/identifai-3.jpeg')
                                    "
                                    style="width: 100%"
                                  />
                                </div>
                                <div class="col-12">
                                  <img
                                    :src="
                                      require('../../assets/images/ocr_guide/identifai-4.jpeg')
                                    "
                                    style="width: 100%"
                                  />
                                </div>
                              </v-row>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <v-btn text @click="dialog.value = false">{{
                                $t("close")
                              }}</v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </small>
                  </v-col>

                  <v-col cols="12" md="12" class="float-left">
                    <v-layout>
                      <v-file-input
                        show-size
                        dense
                        :rules="rulesPhoto"
                        accept="image/png, image/jpeg, image/bmp"
                        :placeholder="
                          $t('page.register.open_camera_chose_file')
                        "
                        prepend-icon="mdi-camera"
                        :label="$t('page.register.click_open_camera')"
                        v-model="file_ktp"
                        ref="file"
                      ></v-file-input>

                      <v-btn
                        rounded
                        class="btn-detail"
                        style="height: 26px"
                        @click="scanOCR()"
                        :disabled="disableBtn"
                      >
                        {{ $t("upload") }}
                      </v-btn>
                    </v-layout>
                    <!-- <input type="file" id="file" ref="file" v-on:change="scanOCR()"/> -->
                    <br />
                    <div v-if="loadingKTP == true">
                      <small>{{ $t("page.register.wait_we_read_ktp") }} </small>
                      <v-progress-circular
                        indeterminate
                        :size="20"
                        color="primary"
                      ></v-progress-circular>
                    </div>
                    <div v-if="errorKTP == true">
                      <small style="color: red">{{ errorKTP_message }} </small>
                    </div>
                    <div v-if="successKTP == true">
                      <small style="color: green">{{
                        $t("page.register.success_please_have_look")
                      }}</small>
                    </div>
                  </v-col>
                  <!-- <v-col cols="12" md="6" class="float-left">
                    <v-btn
                        block
                        elevation="4"
                        v-on:click="scanOCR()"
                      >Scan</v-btn>
                  </v-col> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col align="left">
                  <h4 class="blue-font mb-0 mt-0">
                    {{ $t("page.register.personal_info") }}
                  </h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="field-padding">
                  <v-autocomplete
                    outlined
                    dense
                    class="mt-0"
                    :search-input.sync="searchCitizenship"
                    v-model="payload.citizenship"
                    hide-no-data
                    :items="citizenship"
                    item-text="name"
                    item-value="code"
                    :rules="[
                      v =>
                        !!v ||
                        `${$t('field.citizenship')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.citizenship')"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="field-padding">
                  <v-select
                    outlined
                    dense
                    :disabled="
                      this.payload.citizenship == null ||
                      this.payload.citizenship == ''
                    "
                    class="mt-0"
                    v-model="payload.identityType"
                    :items="identityTypeFilter"
                    :item-text="
                      $i18n.locale == 'en' ? `name` : `name_${$i18n.locale}`
                    "
                    item-value="id"
                    :rules="[
                      v =>
                        !!v ||
                        `${$t('field.identity_type')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.identity_type')"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="field-padding">
                  <v-autocomplete
                    outlined
                    dense
                    class="mt-0"
                    :search-input.sync="searchIdentityCountry"
                    v-model="payload.identityCountry"
                    hide-no-data
                    :items="identityCountry"
                    item-text="name"
                    item-value="id"
                    :rules="[
                      v =>
                        !!v ||
                        `${$t('field.identity_country_isu')} ${$t(
                          'is_required'
                        )}`,
                    ]"
                    :label="$t('field.identity_country_isu')"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="field-padding">
                  <v-text-field
                    outlined
                    dense
                    class="mt-0"
                    v-model="payload.idcard"
                    :rules="[
                      v =>
                        !!v ||
                        `${$t('field.identity_number')} ${$t('is_required')}`,
                      v =>
                        (payload.identityType === 128
                          ? /^\d+$/.test(v)
                          : true) || $t('field.invalid_id_number'),
                      v =>
                        (payload.identityType === 128
                          ? /^\d{16}$/.test(v)
                          : true) || $t('field.id_number_must_16'),
                    ]"
                    :label="$t('field.identity_number')"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="field-padding">
                  <v-autocomplete
                    outlined
                    dense
                    class="mt-0"
                    v-model="payload.birthCountry"
                    :search-input.sync="searchBirthCountry"
                    hide-no-data
                    :items="country"
                    item-text="name"
                    item-value="id"
                    :rules="[
                      v =>
                        !!v ||
                        `${$t('field.country_of_birth')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.country_of_birth')"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="field-padding">
                  <v-text-field
                    outlined
                    dense
                    class="mt-0 uppercase-input"
                    v-model="payload.birthPlace"
                    :rules="[
                      v =>
                        !!v ||
                        `${$t('field.place_of_birth')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.place_of_birth')"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="field-padding">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="payload.birthDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        class="mt-0"
                        v-model="dateFormatted"
                        :label="$t('field.birth_date')"
                        :rules="[
                          v =>
                            !!v ||
                            `${$t('field.birth_date')} ${$t('is_required')}`,
                        ]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hint="DD-MM-YYYY format"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :locale="$i18n.locale"
                      v-model="payload.birthDate"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      no-title
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(payload.birthDate)"
                      >
                        {{ $t("ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="field-padding">
                  <v-autocomplete
                    outlined
                    dense
                    class="mt-0"
                    v-model="payload.selectGender"
                    :items="gender"
                    :item-text="
                      $i18n.locale == 'en' ? `name` : `name_${$i18n.locale}`
                    "
                    item-value="id"
                    :rules="[
                      v => !!v || `${$t('field.gender')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.gender')"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="field-padding">
                  <v-autocomplete
                    outlined
                    dense
                    class="mt-0"
                    v-model="payload.bloodType"
                    :items="bloodType"
                    item-text="text"
                    item-value="value"
                    :label="$t('field.blood_type')"
                    :rules="[
                      v =>
                        !!v || `${$t('field.blood_type')} ${$t('is_required')}`,
                    ]"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="field-padding">
                  <v-autocomplete
                    outlined
                    dense
                    class="mt-0"
                    v-model="payload.selectReligion"
                    :items="religion"
                    :search-input.sync="searchReligion"
                    hide-no-data
                    :item-text="
                      $i18n.locale == 'en' ? 'name' : `name_${$i18n.locale}`
                    "
                    item-value="id"
                    :rules="[
                      v =>
                        !!v || `${$t('field.religion')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.religion')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="field-padding">
                  <v-autocomplete
                    outlined
                    dense
                    class="mt-0"
                    v-model="payload.selectMaritalStatus"
                    :items="maritalStatus"
                    :item-text="
                      $i18n.locale == 'en' ? `name` : `name_${$i18n.locale}`
                    "
                    item-value="id"
                    :rules="[
                      v =>
                        !!v ||
                        `${$t('field.marital_status')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.marital_status')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="field-padding">
                  <v-autocomplete
                    outlined
                    dense
                    class="mt-0"
                    v-model="payload.selectEthnicity"
                    :search-input.sync="searchEthnicity"
                    hide-no-data
                    :items="ethnicity"
                    :item-text="
                      $i18n.locale == 'en' ? `name` : `name_${$i18n.locale}`
                    "
                    item-value="id"
                    :rules="[
                      v => !!v || `${$t('field.race')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.race')"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="left">
                  <h4 class="blue-font mb-0 mt-0 address-title">
                    {{ $t("field.address") }}
                  </h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="field-padding">
                  <v-text-field
                    outlined
                    dense
                    class="mt-0 uppercase-input"
                    v-model="payload.addressInput"
                    :rules="[
                      v => !!v || `${$t('field.address')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.address')"
                    readonly
                    @click.stop="openModalAddress"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" class="field-padding">
                  <v-text-field
                    outlined
                    dense
                    class="mt-0 uppercase-input"
                    v-model="payload.address"
                    :rules="[
                      v =>
                        !!v ||
                        `${$t('field.address_detail')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.address_detail')"
                  ></v-text-field>
                </v-col>
                <input type="hidden" v-model="payload.selectSubdistrict" />
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="d-flex align-center justify-end">
            <v-btn class="mr-4 button-register" @click="logout" color="white">
              {{ $t("back") }}
            </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex align-center justify-start">
            <v-btn
              :disabled="!valid"
              class="mr-4 button-register ml-1"
              @click="validate"
              color="white"
            >
              {{ $t("next") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <address-pop-up
      v-if="modalAddressIsOpen"
      :countryCode="payload.citizenship == null ? 'ID' : payload.citizenship"
      @modalAddressClose="closeModalAddress"
      @setAddress="setAddress"
    />
  </div>
</template>

<script>
import validator from "validator";
import API from "@/service/api_service";
import AddressPopUp from "@/components/general/AddressPopUp.vue";
import Auth from "../../service/auth_service";
import axios from "axios";
import moment from "moment";
export default {
  components: { AddressPopUp },
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.$t("page.register.register")}`,
    };
  },
  name: "Register",
  data: () => ({
    searchIdentityCountry: "",
    searchReligion: "",
    searchBirthCountry: "",
    searchEthnicity: "",
    searchCitizenship: "",
    disableBtn: false,
    rulesPhoto: [
      value =>
        !value || value.size < 8000000 || "KTP size should be less than 8mb!",
    ],
    loadingKTP: false,
    errorKTP: false,
    errorKTP_message: "",
    successKTP: false,
    modalAddressIsOpen: false,
    valid: true,
    show_password: false,
    show_confirm_password: false,
    modal: false,
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    bloodType: [],
    gender: [],
    ethnicity: [],
    country: [],
    citizenship: [],
    province: [],
    city: [],
    district: [],
    subdistrict: [],
    birthPlace: [],
    identityType: [],
    identityCountry: [],
    religion: [],
    maritalStatus: [],
    payload: {
      address: "",
      selectSubdistrict: "",
      selectDistrict: "",
      selectProvince: "",
      selectCountry: 102,
      selectEthnicity: "",
      selectMaritalStatus: "",
      selectReligion: "",
      selectGender: "",
      idcard: "",
      birthDate: "",
      selectCity: "",
      birthPlace: "",
      birthCountry: 102,
      identityType: "",
      identityCountry: 102,
      identityCountryCode: "",
      subDistrictDetail: {},
      addressInput: "",
      bloodType: "",
      citizenship: "ID",
      citizenshipId: 102,
    },
    file_ktp: null,
  }),
  computed: {
    dataProfile() {
      return this.$store.state.profileData;
    },
    dateFormatted() {
      return this.formatDate(this.payload.birthDate);
    },
    identityTypeFilter() {
      if (this.payload.citizenship != "ID") {
        return this.identityType.filter(res => res.code.toUpperCase() != "ID");
      } else {
        return this.identityType;
      }
    },
  },
  watch: {
    searchIdentityCountry: function (text, before) {
      this.getCountry(
        before == ""
          ? "INDONESIA"
          : text != null && text != undefined
          ? text
          : ""
      );
    },
    searchReligion: function () {
      this.getReligion();
    },
    searchBirthCountry: function (text, before) {
      this.getCountryOfBirth(
        before == ""
          ? "INDONESIA"
          : text != null && text != undefined
          ? text
          : ""
      );
    },
    searchEthnicity: function () {
      this.getRace();
    },
    searchCitizenship: function (text, before) {
      this.getCitizenship(
        before == ""
          ? "INDONESIA"
          : text != null && text != undefined
          ? text
          : ""
      );
    },
    "payload.identityCountry": function (text) {
      this.changeIdentityCountry(text == null || text == undefined ? "" : text);
    },
    "payload.identityCountryCode": function () {
      this.getReligion();
    },
    "payload.citizenship": function (value) {
      this.payload.address = "";
      this.payload.selectSubdistrict = "";
      this.payload.selectDistrict = "";
      this.payload.selectProvince = "";
      this.payload.selectCity = "";
      this.payload.selectCountry = 102;
      this.payload.addressInput = "";
      const indexCitizen = this.citizenship.findIndex(res => res.code == value);
      this.payload.citizenshipId =
        this.citizenship[indexCitizen] == undefined
          ? ""
          : this.citizenship[indexCitizen].id;
    },
  },
  async mounted() {
    // this.payload.birthDate = '1996-07-13';
    this.bloodType = [
      { value: "-", text: this.$t("unknown") },
      { value: "A+", text: "A+" },
      { value: "A-", text: "A-" },
      { value: "AB+", text: "AB+" },
      { value: "AB-", text: "AB-" },
      { value: "B+", text: "B+" },
      { value: "B-", text: "B-" },
      { value: "O+", text: "O+" },
      { value: "O-", text: "O-" },
    ];
    // identity Type
    API.get(
      `${process.env.VUE_APP_API_TRUST}identity-type/get?show_default=true`,
      {}
    ).then(res => {
      if (res.statusCode == 200) {
        this.identityType = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });
    // gender
    API.get(`${process.env.VUE_APP_API_TRUST}gender/get`, {}).then(res => {
      if (res.statusCode == 200) {
        this.gender = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });
    // marital status
    this.getMaritalStatus();

    let userEmail = "";

    await API.get(`${process.env.VUE_APP_API_TRUST}profile`, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then(res => {
      userEmail = res.email;
    });

    let emailUrl = `${process.env.VUE_APP_API_BIZNET_URL}virtuelab/getCustomerBiznetByEmail?e=${userEmail}`;

    await API.get(emailUrl, {}).then(res => {
      if (res.data.length > 0) {
        this.payload.idcard = res.data[0].customeridentityno;
        let idType = res.data[0].customerid_type.toLowerCase();
        if (idType == "ktp id") {
          this.payload.identityType = 128;
        } else if (idType == "passport") {
          this.payload.identityType = 91;
        }
        this.payload.birthDate = moment(
          res.data[0].customertanggallahir
        ).format("YYYY-MM-DD");
        let gender = res.data[0].customergender.toLowerCase();
        if (gender == "male") {
          this.payload.selectGender = 3;
        } else if (gender == "female") {
          this.payload.selectGender = 2;
        }
        this.payload.address = res.data[0].customeraddress;
      }
    });
    this.getTitle();
  },
  methods: {
    async getTitle() {
      try {
        let resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}patient-title/get`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode == 200) {
          const indexGender = resp.results.findIndex(
            res => res.id == this.dataProfile.person.patient.title_id
          );
          if (indexGender > -1) {
            this.payload.selectGender = resp.results[indexGender].gender_id;
          }
        }
      } catch (error) {
        console.log("Error 403! title");
      }
    },
    logout() {
      Auth.signOut();
      this.$router.push(`/${this.$i18n.locale}/login`);
    },
    validate() {
      if (this.$refs.form.validate() == true) {
        const {
          idcard,
          selectEthnicity,
          birthPlace,
          birthDate,
          selectGender,
          selectCountry,
          selectProvince,
          selectDistrict,
          selectSubdistrict,
          selectMaritalStatus,
          selectReligion,
          birthCountry,
          address,
          selectCity,
          identityType,
          identityCountry,
          bloodType,
          citizenshipId,
        } = this.payload;
        const dataReady = {
          identity_number: idcard,
          identity_type: identityType,
          identity_country_issuer: identityCountry,
          ethnicity: selectEthnicity == "" ? null : selectEthnicity,
          place_of_birth: birthPlace,
          date_of_birth: birthDate,
          gender: selectGender,
          subdistrict_code: selectSubdistrict,
          address,
          client_unique_id: "tm617a34c4a3b86",
          district_id: selectDistrict,
          country_id: selectCountry,
          provice_id: selectProvince,
          city_id: selectCity,
          country_birth_id: birthCountry,
          religion_id: selectReligion,
          marital_status_id: selectMaritalStatus,
          blood_type: bloodType,
          otp_request: true,
          citizenship: citizenshipId,
        };

        API.post(
          `${process.env.VUE_APP_API_TRUST}continue-register?lang=${this.$i18n.locale}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          dataReady
        )
          .then(async res => {
            if (res.statusCode === 200) {
              this.payload = {
                address: "",
                selectSubdistrict: "",
                selectDistrict: "",
                selectProvince: "",
                selectCountry: 102,
                selectEthnicity: "",
                selectMaritalStatus: "",
                selectReligion: "",
                selectGender: "",
                idcard: "",
                birthDate: "",
                selectCity: "",
                birthPlace: "",
                birthCountry: 102,
                identityType: "",
                identityCountry: 102,
                subDistrictDetail: {},
                addressInput: "",
                bloodType: "",
                citizenship: "ID",
                citizenshipId: 102,
              };
              await Auth.signOut();
              this.$router.push(`/${this.$i18n.locale}/register-success`);
            } else if (res.statusCode == 400) {
              Object.keys(res.errors).forEach(element => {
                this.toast(
                  "error",
                  typeof res.errors[element] == "object"
                    ? res.errors[element][0]
                    : res.errors[element]
                );
              });
            } else {
              this.$swal({
                icon: "error",
                text: res.message,
              });
            }
          })
          .catch(() => {
            this.$swal({
              icon: "error",
              text: "Error Registration!",
            });
          });
      }
    },
    passwordConfirmRules(value) {
      if (value !== this.payload.password) {
        return `${this.$t("register.confirm_password")} ${this.$t(
          "must_valid"
        )}`;
      } else if (value == "") {
        return `${this.$t("register.confirm_password")} ${this.$t(
          "is_required"
        )}`;
      } else {
        return true;
      }
    },
    emailRules(value) {
      if (value == "") {
        return `${this.$t("register.email")} ${this.$t("is_required")}`;
      } else if (!validator.isEmail(value)) {
        return `${this.$t("register.email")} ${this.$t("must_valid")}`;
      } else {
        return true;
      }
    },
    async getCountry(text) {
      try {
        const country = await API.get(
          `${process.env.VUE_APP_API_TRUST}country/get?query=${
            text == null ? "" : text
          }`,
          {}
        );
        if (country.statusCode == 200) {
          this.identityCountry = country.results.sort((a, b) =>
            this.sortByName(a, b)
          );
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },

    async getCitizenship(text) {
      try {
        const country = await API.get(
          `${process.env.VUE_APP_API_TRUST}country/get?query=${
            text == null ? "" : text
          }`,
          {}
        );
        if (country.statusCode == 200) {
          this.citizenship = country.results.sort((a, b) =>
            this.sortByName(a, b)
          );
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },

    async getMaritalStatus() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}marital-status/get`,
          {}
        );
        if (res.statusCode == 200) {
          this.maritalStatus = res.results.sort((a, b) =>
            this.sortByName(a, b)
          );
        }
        if (res.statusCode == 200) {
          this.maritalStatus = res.results.sort((a, b) =>
            this.sortByName(a, b)
          );
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    async getRace() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}ethnicity/get?query=${
            this.searchEthnicity != null && this.searchEthnicity != undefined
              ? this.searchEthnicity
              : null
          }`,
          {}
        );
        if (res.statusCode == 200) {
          this.ethnicity = res.results.sort((a, b) => this.sortByName(a, b));
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    async getCountryOfBirth(text) {
      try {
        const country = await API.get(
          `${process.env.VUE_APP_API_TRUST}country/get?query=${
            text == null ? "" : text
          }`,
          {}
        );
        if (country.statusCode == 200) {
          this.country = country.results.sort((a, b) => this.sortByName(a, b));
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    async getReligion() {
      try {
        const religion = await API.get(
          `${process.env.VUE_APP_API_TRUST}religion/get?sort_by_country=${
            this.payload.identityCountryCode
          }&query=${
            this.searchReligion != undefined && this.searchReligion != null
              ? this.searchReligion
              : ""
          }`,
          {}
        );
        if (religion.statusCode == 200) {
          this.religion = religion.results;
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    sortByName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    formatDate(date) {
      if (!date) return null;
      const MMM = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "Aug",
        "09": "Sept",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      };
      const [year, month, day] = date.split("-");
      return `${day} ${MMM[month]} ${year}`;
    },
    openModalAddress() {
      this.modalAddressIsOpen = true;
    },
    closeModalAddress() {
      this.modalAddressIsOpen = false;
    },
    setAddress(param) {
      const { prov_id, city_id, distict_id, idsubdistrict, country_id } =
        param.selected;
      this.payload.addressInput = param.name;
      this.payload.selectSubdistrict = idsubdistrict;
      this.payload.selectCity = city_id;
      this.payload.selectProvince = prov_id;
      this.payload.selectCountry = country_id;
      this.payload.selectDistrict = distict_id;
    },
    uploadFile() {
      this.file_ktp = this.$refs.file.files[0];
    },
    scanOCR() {
      /* eslint-disable no-unused-vars */
      var _this = this;

      if (this.file_ktp !== null) {
        this.errorKTP = false;
        var resizeImage = function (settings) {
          var file = settings.file;
          var maxSize = settings.maxSize;
          var reader = new FileReader();
          var image = new Image();
          var canvas = document.createElement("canvas");
          var dataURItoBlob = function (dataURI) {
            var bytes =
              dataURI.split(",")[0].indexOf("base64") >= 0
                ? atob(dataURI.split(",")[1])
                : unescape(dataURI.split(",")[1]);
            var mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
            var max = bytes.length;
            var ia = new Uint8Array(max);
            for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
            return new Blob([ia], { type: mime });
          };
          var resize = function () {
            var width = image.width;
            var height = image.height;
            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              }
            } else {
              if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL("image/jpeg");
            return dataURItoBlob(dataUrl);
          };
          return new Promise(function (ok, no) {
            if (!file.type.match(/image.*/)) {
              // no(new Error("Not an image"));
              _this.errorKTP = true;
              _this.errorKTP_message =
                "Please only select image files e.g JPG/PNG/JPEG";
              return;
            }
            reader.onload = function (readerEvent) {
              image.onload = function () {
                return ok(resize());
              };
              image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
          });
        };

        resizeImage({
          file: _this.file_ktp,
          maxSize: 1000,
        })
          .then(function (resizedImage) {
            var converted_image = new File([resizedImage], "uploaded_file", {
              type: "image/jpeg",
            });
            _this.file_ktp = converted_image;

            // var url = `${process.env.VUE_APP_API_TRUST}user-identity/ocr-scan`;
            // var url = `${process.env.VUE_APP_API_BIZNET_URL}virtuelab/finfiniOcrKtp`;
            // var url = `${process.env.VUE_APP_API_BIZNET_URL}virtuelab/verihubsOcrKtp`;
            var url = `${process.env.VUE_APP_API_BIZNET_URL}virtuelab/glairOcrKtp`;
            
            axios.interceptors.request.use(
              function (config) {
                _this.loadingKTP = true;
                _this.errorKTP = false;
                _this.successKTP = false;
                _this.disableBtn = true;
                return config;
              },
              function (error) {
                return Promise.reject(error);
              }
            );

            axios.interceptors.response.use(
              function (response) {
                _this.loadingKTP = false;
                _this.disableBtn = false;
                let status = response.data.status;
                if (status == "incompleted") {
                  _this.errorKTP = true;
                  _this.errorKTP_message =
                    "Error. Please provide a proper KTP image with high resolution";
                }
                return response;
              },
              function (error) {
                _this.loadingKTP = false;
                _this.errorKTP = true;
                _this.errorKTP_message = error;
                _this.disableBtn = false;
                return Promise.reject(error);
              }
            );

            let formData = new FormData();

            formData.append("image", _this.file_ktp);

            axios
              .post(url, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(function (json) {
                try {                
                      let data = json.data;
                      let dataresults = data.data;
                      let status = data.status;
                      let message = data.message;
                      console.log('data', data);
                      console.log('dataresults',dataresults);
                      console.log('status', data.status)
                      if (status == "200" || status == 200) {

            
                            /* milik finfini 
                            ---------------------------------------------*/
                            // let result = dataresults.data;
                            // let religion = result.religion.suggestion;
                            // let address = result.address.suggestion;
                            // let marital_status = result.marital_status.suggestion;
                            // let gender = result.gender.suggestion;
                            // let blood_type = result.blood_type.suggestion;
                            // let birth_date = result.date_of_birth.suggestion;
                            // let place_birth = result.place_of_birth.suggestion;
                            // let country_birth = 102;
                            // let identity_number = result.nik.suggestion;
                            /* ----------------------------------------- */

                            /* milik verihubs 
                            // let result = dataresults.data;
                            // let religion = result.religion;
                            // let address = result.address;
                            // let marital_status = result.marital_status;
                            // let gender = result.gender;
                            // let blood_type = result.blood_type;
                            // let birth_date = result.date_of_birth;
                            // let place_birth = result.place_of_birth;
                            // let country_birth = 102;
                            // let identity_number = result.nik;
                            /* ----------------------------------------- */

                            let result = dataresults.read;
                            let religion = result.agama.value;
                            let address = result.alamat.value;
                            let marital_status = result.statusPerkawinan.value;
                            let gender = result.jenisKelamin.value;
                            let blood_type = result.golonganDarah.value;
                            let birth_date = result.tanggalLahir.value;
                            let place_birth = result.tempatLahir.value;
                            let country_birth = 102;
                            let identity_number = result.nik.value;

                            console.log('result', result);
                            console.log('religion', religion);
                            console.log('address', address);
                            console.log('marital_status', marital_status);
                            console.log('gender', gender);
                            console.log('blood_type', blood_type);
                            console.log('birth_date', birth_date);
                            console.log('place_birth', place_birth);
                            console.log('country_birth', country_birth);
                            console.log('identity_number', identity_number);

                            religion = religion.toUpperCase();
                            marital_status = marital_status.toUpperCase();
                            gender = gender.toUpperCase();

                            switch (religion) {
                              case "ISLAM":
                                religion = 57;
                                break;
                              case "KATOLIK":
                                religion = 16;
                                break;
                              case "KATHOLIK":
                                religion = 16;
                                break;
                              case "KRISTEN":
                                religion = 18;
                                break;
                              case "HINDU":
                                religion = 39;
                                break;
                              case "BUDDHA":
                                religion = 15;
                                break;
                              case "KONGHUCU":
                                religion = 21;
                                break;
                              default:
                                religion = 84;
                            }

                            switch (marital_status) {
                              case "KAWIN":
                                marital_status = 5;
                                break;
                              case "BELUM KAWIN":
                                marital_status = 9;
                                break;
                              case "CERAI HIDUP":
                                marital_status = 2;
                                break;
                              case "CERAI MATI":
                                marital_status = 11;
                                break;
                              default:
                                marital_status = 9;
                            }

                            switch (gender) {
                              case "LAKI-LAKI":
                                gender = 3;
                                break;
                              case "PEREMPUAN":
                                gender = 2;
                                break;
                              default:
                                gender = 6; //unknown
                            }

                            switch (blood_type) {
                              case "O":
                                blood_type = "O+";
                                break;
                              case "A":
                                blood_type = "A+";
                                break;
                              case "B":
                                blood_type = "B+";
                                break;
                              case "AB":
                                blood_type = "AB+";
                                break;
                              default:
                                blood_type = "-";
                            }

                            //setting birthdate finfini
                            let explode = birth_date.split("-");
                            if (explode.length > 2) {
                              let year = explode[2];
                              let month = explode[1];
                              let day = explode[0];

                              month = parseInt(month) - 1;

                              let newdate = new Date(year, month, day);
                              if (new Date(newdate).toString() !== "Invalid Date") {
                                let format_birth_date =
                                  moment(newdate).format("YYYY-MM-DD");
                                _this.payload.birthDate = format_birth_date;
                              }
                            }
                            
                        
                            // _this.payload.birthDate = birth_date;
                            _this.payload.selectReligion = religion;
                            _this.payload.address = address;
                            _this.payload.selectMaritalStatus = marital_status;
                            _this.payload.selectGender = gender;
                            _this.payload.bloodType = blood_type;

                            _this.payload.birthPlace = place_birth;
                            _this.payload.birthCountry = country_birth;
                            _this.payload.idcard = identity_number;
                            _this.payload.selectEthnicity = 839; // indonesia

                            _this.successKTP = true;
                            _this.file_ktp = null;

                      
                       
                      } else {
                        console.log('gagal woy'); 
                        console.log('message', message);
                      _this.errorKTP = true;
                      _this.errorKTP_message = message;
                      _this.file_ktp = null;
                      }
                  
                } catch (error) {
                  _this.errorKTP = true;
                  // _this.errorKTP_message = json;
                  _this.errorKTP_message =
                    "Cannot read your uploaded file. Make sure you upload a clear image of your ID";
                  _this.file_ktp = null;
                }
              })
              .catch(function (e) {
                _this.errorKTP = true;
                _this.errorKTP_message =
                  "Cannot communicate with server, please try again later";
                _this.file_ktp = null;
              });
          })
          .catch(function (err) {
            console.error(err);
          });
      } else {
        this.errorKTP = true;
        this.errorKTP_message =
          "Please select take KTP picture or select file ";
      }
    },
    changeIdentityCountry(value) {
      const getIndex = this.identityCountry.findIndex(data => data.id == value);
      if (getIndex > -1) {
        this.payload.identityCountryCode = this.identityCountry[getIndex].code;
      } else {
        this.payload.identityCountryCode = "";
      }
    },
  },
};
</script>
<style>
.uppercase-input input,
.v-input {
  text-transform: uppercase;
}

.box-modal
  .modal--search-address
  .list-address
  .card--address[data-v-1c171faf] {
  display: flex;
  padding: 4px 10px;
  border-radius: 8px;
  text-transform: uppercase;
  border: 1px solid #858585;
  margin-bottom: 7px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 16px;
}
</style>
<style lang="scss" scoped>
@import "../../assets/css/custom.scss";
.button-register {
  margin-top: 40px;
  padding: 8px 18px;
  background: $blue_2 !important;
  color: white;
  text-transform: capitalize;
  border-radius: 8px;
}
.theme--light.v-btn.v-btn--disabled {
  color: white !important;
  background: rgb(24, 24, 24);
}
.containt {
  padding-bottom: 110px;
}
.card--cart {
  padding: 32px 15px;
  border-radius: 16px;
  background: #fff;
  margin-top: 30px;
}
.blue-font {
  color: $blue_1;
  font-weight: 700;
  font-size: 20px;
}

a {
  text-decoration: none;
}
.btn-detail {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: #00a4b3 !important;
  color: #fff;
  font-size: 12px;
  text-transform: capitalize;
  height: 20px;
}

@media only screen and (max-width: 600px) {
  // .identity-number {
  //   margin-top: -30px;
  // }

  // .birth-country {
  //   margin-top: -30px;
  // }

  // .birth-place {
  //   margin-top: -30px;
  // }

  // .birth-date {
  //   margin-top: -30px;
  // }

  // .gender {
  //   margin-top: -30px;
  // }

  // .blood-type {
  //   margin-top: -30px;
  // }

  // .religion {
  //   margin-top: -30px;
  // }

  // .marital-status {
  //   margin-top: -30px;
  // }

  // .ethnicity {
  //   margin-top: -30px;
  // }

  // .address-title {
  //   margin-top: -5px !important;
  // }

  // .address {
  //   margin-top: -30px;
  // }

  // .address-detail {
  //   margin-top: -30px;
  // }

  .button-register {
    min-width: 150px !important;
    margin-left: 10px !important;
  }
}
.field-padding {
  padding: 0 10px !important;
}
.field-padding-top {
  padding: 12px 10px 0px 10px !important;
}
.card--cart {
  padding: 23px !important;
}
</style>
