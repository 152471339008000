import Vue from "vue";
import Vuex from "vuex";
import Auth from "../service/auth_service";
import localStorageService from "../service/localStorageService";
import moment from "moment";
import API from "@/service/api_service";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    listMenu: "",
    listContent: "",
    activeTab: "",
    count: 0,
    menuOpen: false,
    menuActive: "",
    cart: [],
    rescheduleData: {},
    appointmentBranchActive: {},
    appointmentBranch: [],
    appointmentList: [],
    openAlert: true,
    midtransIsLoaded: false,
    typeUser: "",
    loading: false,
    activePage: "",
    helper: {
      filter_time: "thirty_days_ago",
    },
    date: moment().format("DD-MMM-YYYY"),
    paramOrderNumber: "",
    notifLength: "",
    plantPackage: "",
    notification: [],
    profileData: {},
    menuData: [],
    finishFetch: false,
    paramMedicalRecord: "",
  },
  getters: {
    getCart(state) {
      return state;
    },
    getNotification: state => state.notification,
  },
  mutations: {
    setLoading(state, data) {
      state.loading = data;
    },
    loadMidtrans(state) {
      state.midtransIsLoaded = true;
    },
    increment(state) {
      state.count++;
    },
    toggleMenu(state) {
      state.menuOpen = !state.menuOpen;
    },
    openMenu(state) {
      state.menuOpen = true;
    },
    closeMenu(state) {
      state.menuOpen = false;
    },
    setAppointmentBranchActive(state, data) {
      state.appointmentBranchActive = data;
    },
    setAppointmentBranch(state, data) {
      state.appointmentBranch = { data };
    },
    setAppointmentList(state, data) {
      state.appointmentList = data;
    },
    updateCart(state, data) {
      state.cart = data;
    },
    toggleMenuActive(state, data) {
      if (state.menuActive == data) {
        state.menuActive = "";
      } else {
        state.menuActive = data;
      }
    },
    setActivePage(state, data) {
      state.activePage = data;
    },
    checkUserType(state) {
      if (
        localStorageService.getItem("type") !== "" &&
        localStorageService.getItem("type") !== "undefined" &&
        localStorageService.getItem("type") !== null
      ) {
        if (Auth.checkSession()) {
          state.typeUser = localStorageService.getItem("type");
        } else {
          state.typeUser = "";
        }
      }
    },
    setUserType(state, data) {
      state.typeUser = data;
    },
    setActiveTab(state, data) {
      state.activeTab = data;
    },
    setHelper(state, data) {
      state.helper = data;
    },
    setListMenu(state, data) {
      state.listMenu = data;
    },
    setListContent(state, data) {
      state.listContent = data;
    },
    setPlantPackage(state, data) {
      state.plantPackage = data;
    },
    setNotifLength(state, data) {
      state.notifLength = data;
    },
    setNotificationData(state, data) {
      state.notification = data;
    },
    // set Profile Data
    setProfileData(state, data) {
      state.profileData = data;
    },
    // set Cart Data
    setCartData(state, data) {
      state.cart = data;
    },
    // set Finish Fetch api
    setFinishFetch(state, data) {
      state.finishFetch = data;
    },
    // set Profile Data
    setMenuData(state, data) {
      state.menuData = data;
    },
    // set Profile Data
    setRescheduleData(state, data) {
      state.rescheduleData = data;
    },
    // end Reschedule Data
  },
  actions: {
    increment(context) {
      context.commit("increment");
    },
    async fetchNotification({ commit }) {
      try {
        let resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}notifications/get?per_page=10&page=1`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        let lengt = 0;
        resp.results.forEach(el => {
          if (el.is_read == false) {
            lengt++;
          }
        });
        commit("setNotificationData", resp.results);
        commit("setNotifLength", lengt);
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    // fetch Profile Data
    async fetchProfileData({ commit }) {
      try {
        if (Auth.checkSession()) {
          let resp = await API.get(`${process.env.VUE_APP_API_TRUST}profile`, {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          });
          commit("setProfileData", resp);
        } else {
          commit("setProfileData", {});
        }
      } catch (error) {
        console.log("Error when get profile");
      }
    },
    // Fetch Cart Data
    async fetchCartData({ commit }) {
      try {
        if (Auth.checkSession()) {
          let resp = await API.get(`${process.env.VUE_APP_API_TRUST}cart/get`, {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          });
          commit("setCartData", resp.results);
        } else {
          commit("setCartData", {});
        }
      } catch (error) {
        console.log("Error when get Cart");
      }
    },
    // Fetch Menu Data
    async fetchMenuData({ commit }) {
      try {
        if (Auth.checkSession()) {
          let resp = await API.get(
            `${
              process.env.VUE_APP_API_TRUST
            }get-menu?lang=${localStorageService.getItem("locale")}`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          );
          commit("setMenuData", resp.results);
        } else {
          commit("setMenuData", {});
        }
      } catch (error) {
        console.log("Error when get Menu");
      }
    },
    // start Fetch Reschedule helper
    async fetchRescheduleData({ commit }) {
      try {
        if (Auth.checkSession()) {
          let resp = await API.get(
            `${
              process.env.VUE_APP_API_TRUST
            }get-defaults?lang=${localStorageService.getItem("locale")}`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          );
          commit("setRescheduleData", resp.reschedule);
        } else {
          commit("setRescheduleData", {});
        }
      } catch (error) {
        console.log("Error when get Reschedule");
      }
    },
    // end Fetch Reschedule helper
  },
  modules: {},
});
