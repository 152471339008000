<template>
  <svg
    width="50"
    height="32"
    viewBox="0 0 50 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 4.80828C21.8824 1.72981 17.6588 0 13.2571 0H0L25 31.8182L50 0H36.7503C32.3412 0 28.1176 1.72981 25 4.80828Z"
      fill="#00A6B6"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
