<template>
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5436 2.50525C11.5603 -0.476116 6.33954 -0.476116 2.98333 2.50525C2.40803e-05 5.48662 2.40803e-05 10.704 2.98333 14.058L8.57702 19.6481L14.1707 14.058C17.5269 10.704 17.5269 5.48662 14.5436 2.50525ZM8.94993 11.4494C7.08537 11.4494 5.59372 9.95867 5.59372 8.09531C5.59372 6.23196 7.08537 4.74127 8.94993 4.74127C10.8145 4.74127 12.3061 6.23196 12.3061 8.09531C12.3061 9.95867 10.8145 11.4494 8.94993 11.4494Z" :fill="color"/>
</svg>

</template>

<script>
export default {
  props: ["color"],
};
</script>