<template>
  <div class="not-found pa-3 pa-md-5">
    <img :src="require('@/assets/images/banner/coming_soon.png')" alt="" />
    <h4>404</h4>
    <h5>{{ $t("title_not_found") }}</h5>
    <p>{{ $t("desc_not_found") }}</p>
    <router-link to="/">
      <c-button class="mx-2 py-2" rounded outline small>
        {{ $t("back_to_home") }}
      </c-button>
    </router-link>
  </div>
</template>

<script>
import CButton from "@/components/v2/button/index.vue";

export default {
  components: {
    CButton,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.not-found {
  background: white;
  width: 100%;
  max-width: 300px;
  text-align: center;
  border-radius: 12px;
  img {
    width: 80%;
    height: auto;
  }
  h4 {
    font-size: 64px;
    color: $main_2;
  }
  h5 {
    font-size: 18px;
  }
  p {
    font-size: 14px;
  }
}
</style>
