<template>
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 0L11.1962 6.75H0.803848L6 0Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: ["color"],
};
</script>
